import { CopyButton } from "@components/CopyButton";
import React, { useState, useRef, useMemo, useCallback } from "react";
import { Panel } from "react-resizable-panels";
import {
  SignedIn,
  useOrganization,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import { useActiveServerStore } from "@/store/activeServerStore";
import {
  Button,
  Checkbox,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  TextWithTooltip,
  Textarea,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@kino/ui";
import { ChevronDownIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import ServerConnectionForm, {
  ServerConnectionFormRef,
} from "@/components/forms/ServerConnectionForm";
import { serverUrlToFormValues } from "@/utils/stringUtils";
import { DebuggerTab, useDebuggerStore } from "@/store/debuggerStore";
import { useTheme } from "@/context/theme-provider";
import { ThemeSwitcher } from "@/components/theme/ThemeSwitcher";
import { usePythonServerStatus } from "@/hooks/usePythonServerStatus";
import { useOrganizationStore } from "@/store/organizationStore";
import { Tab } from "@/components/shared/Tab";
import { BarChartIcon, CogIcon, ServerIcon, SparkleIcon } from "lucide-react";
import {
  useDisplayStore,
  getEffectiveMode,
} from "@/store/display/displayStore";
import { DisplayMode } from "@/types/display";
import { useClerkServerInfo } from "@/hooks/useClerkMetadata";
import { useOverrideServerUrl } from "@/providers/ServerProvider";

// Separate the store selector to prevent unnecessary re-renders
const useDebuggerTabState = () => {
  return useDebuggerStore(
    useCallback(
      (state) => ({
        activeTab: state.activeTab,
        setActiveTab: state.setActiveTab,
      }),
      [],
    ),
  );
};

// Separate the store selector for mock settings
const useMockSettings = () => {
  return useDebuggerStore(
    useCallback(
      (state) => ({
        isMockSearchResults: state.isMockSearchResults,
        setIsMockSearchResults: state.setIsMockSearchResults,
        isMockMediaEndpoint: state.isMockMediaEndpoint,
        setIsMockMediaEndpoint: state.setIsMockMediaEndpoint,
        isMockSeasonStats: state.isMockSeasonStats,
        setIsMockSeasonStats: state.setIsMockSeasonStats,
        isMockTagsEndpoint: state.isMockTagsEndpoint,
        setIsMockTagsEndpoint: state.setIsMockTagsEndpoint,
        showComments: state.showComments,
        setShowComments: state.setShowComments,
        showExportPlaylistsAsAaf: state.showExportPlaylistsAsAaf,
        setShowExportPlaylistsAsAaf: state.setShowExportPlaylistsAsAaf,
      }),
      [],
    ),
  );
};

// Separate the store selector for generation settings
const useGenerationSettings = () => {
  return useDebuggerStore(
    useCallback(
      (state) => ({
        summarizationPrompt: state.summarizationPrompt,
        setSummarizationPrompt: state.setSummarizationPrompt,
      }),
      [],
    ),
  );
};

const GenerationTab = React.memo(() => {
  const { summarizationPrompt, setSummarizationPrompt } =
    useGenerationSettings();

  const handlePromptChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSummarizationPrompt(e.target.value || undefined);
    },
    [setSummarizationPrompt],
  );

  return (
    <div className="flex flex-col gap-4 p-3">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-1">
          <div className="text-xs text-neutral-400">Summarization Prompt</div>
          <TextWithTooltip tooltipText="Custom prompt used for summarization">
            <InfoCircledIcon className="h-3 w-3" />
          </TextWithTooltip>
        </div>
        <Textarea
          value={summarizationPrompt ?? ""}
          onChange={handlePromptChange}
          placeholder="Enter custom summarization prompt..."
          className="h-24 text-xs"
        />
      </div>
    </div>
  );
});

const CheckboxItem = React.memo(
  ({
    checked,
    onChange,
    label,
  }: {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
  }) => (
    <div className="flex items-center gap-1">
      <Checkbox checked={checked} onCheckedChange={onChange} />
      <div className="text-xs text-neutral-400">{label}</div>
    </div>
  ),
);

const MockDataTab = React.memo(() => {
  const settings = useMockSettings();

  return (
    <div className="flex flex-col gap-4 p-3">
      <div className="flex flex-col gap-2">
        <CheckboxItem
          checked={settings.isMockSearchResults}
          onChange={settings.setIsMockSearchResults}
          label="Mock Search Results"
        />
        <CheckboxItem
          checked={settings.isMockMediaEndpoint}
          onChange={settings.setIsMockMediaEndpoint}
          label="Mock Media Files"
        />
        <CheckboxItem
          checked={settings.isMockSeasonStats}
          onChange={settings.setIsMockSeasonStats}
          label="Mock Season Stats"
        />
        <CheckboxItem
          checked={settings.showComments}
          onChange={settings.setShowComments}
          label="Show Comments"
        />
        <CheckboxItem
          checked={settings.showExportPlaylistsAsAaf}
          onChange={settings.setShowExportPlaylistsAsAaf}
          label="Show Export Playlists as AAF"
        />
        <CheckboxItem
          checked={settings.isMockTagsEndpoint}
          onChange={settings.setIsMockTagsEndpoint}
          label="Mock Tags Endpoint"
        />
      </div>
    </div>
  );
});

const ConfigTab = React.memo(() => {
  const { theme } = useTheme();
  const isPythonServerOnline = usePythonServerStatus();
  const { user } = useUser();
  const settings = useDebuggerStore();
  return (
    <div className="flex flex-col gap-4 p-3">
      <div className="flex items-center gap-2">
        <ThemeSwitcher />
        <div className="light:bg-neutral-200 light:text-neutral-500 rounded-sm bg-neutral-800 p-2 text-xs">
          Theme: {theme}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <SignedIn>
          <div className="flex items-center gap-2">
            <UserButton />
            <div className="flex flex-col gap-1 text-sm text-neutral-800">
              <div>{user?.fullName}</div>
              <div className="text-xs text-neutral-400">
                {user?.emailAddresses[0]?.emailAddress}
              </div>
            </div>
          </div>
        </SignedIn>

        <div className="flex w-full flex-col gap-2 text-xs text-neutral-500">
          <DebuggerItem
            label="Python Server Status"
            contents={
              isPythonServerOnline === null
                ? "Checking..."
                : isPythonServerOnline
                  ? "Online"
                  : "Offline"
            }
          />
        </div>
        <CheckboxItem
          checked={settings.isReactScanEnabled}
          onChange={() => {
            settings.setIsReactScanEnabled(!settings.isReactScanEnabled);
            window.location.reload(); // hacky but enough until react-scanlibrary implements toggling (on their roadmap)
          }}
          label="Enable React Scan (reloads page)"
        />
      </div>
    </div>
  );
});

const ServerTab = React.memo(() => {
  const { apiUrl, config: serverConfig } = useClerkServerInfo();
  const overrideUrl = useActiveServerStore((state) => state.overrideUrl);
  const { setOverride, clearOverride } = useOverrideServerUrl();
  const formRef = useRef<ServerConnectionFormRef>(null);
  const {
    mode: defaultDisplayMode,
    modeOverride,
    setModeOverride,
  } = useDisplayStore();
  const effectiveMode = useDisplayStore(getEffectiveMode);
  const {
    organizationOverrideId,
    setOrganizationOverrideId,
    organizationOverrideSlug,
    setOrganizationOverrideSlug,
    organizationSlug,
  } = useOrganizationStore();
  const { organization } = useOrganization();
  const [orgOverrideInput, setOrgOverrideInput] = useState("");
  const [orgSlugOverrideInput, setOrgSlugOverrideInput] = useState("");

  const handleSubmit = useCallback(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);

  const handleClearServer = useCallback(() => {
    clearOverride();
  }, [clearOverride]);

  const handleOrgInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOrgOverrideInput(e.target.value);
    },
    [],
  );

  const handleOrgSlugInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOrgSlugOverrideInput(e.target.value);
    },
    [],
  );

  const handleOrgSubmit = useCallback(() => {
    setOrganizationOverrideId(orgOverrideInput);
    setOrganizationOverrideSlug(orgSlugOverrideInput);
  }, [
    orgOverrideInput,
    orgSlugOverrideInput,
    setOrganizationOverrideId,
    setOrganizationOverrideSlug,
  ]);

  const handleOrgClear = useCallback(() => {
    setOrganizationOverrideId(null);
    setOrganizationOverrideSlug(null);
    setOrgOverrideInput("");
    setOrgSlugOverrideInput("");
  }, [setOrganizationOverrideId, setOrganizationOverrideSlug]);

  const defaultFormValues = useMemo(() => {
    if (!overrideUrl?.trim()) return undefined;
    return serverUrlToFormValues(overrideUrl);
  }, [overrideUrl]);

  const handleDisplayModeSubmit = useCallback(
    (mode: DisplayMode | null) => {
      setModeOverride(mode);
    },
    [setModeOverride],
  );

  return (
    <div className="flex flex-col gap-3 p-3 text-xs text-neutral-500">
      {/* Backend URL Section */}
      <div>
        <div className="flex items-center gap-1">
          <div className="text-neutral-400">Kino API config via Clerk</div>
          <TextWithTooltip tooltipText="These values are entirely defined in the org's Clerk publicMetadata">
            <InfoCircledIcon className="h-3 w-3" />
          </TextWithTooltip>
        </div>
        <div className="text-neutral-600">
          {serverConfig ? (
            <div className="text-xs text-neutral-600">
              {serverConfig.name} — {serverConfig.ip}:{serverConfig.port}
            </div>
          ) : (
            <div className="text-xs text-neutral-600">
              No server config found in organization metadata
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="text-neutral-400">Kino API URL</div>
        <div className="text-neutral-600">
          {overrideUrl ? (
            <div className="flex flex-col gap-1">
              <span className="line-through">
                {apiUrl || "No backend URL configured"}
              </span>
              <span className="text-green-600">{overrideUrl}</span>
            </div>
          ) : (
            apiUrl
          )}
        </div>
      </div>

      <div>
        <Collapsible defaultOpen={!!overrideUrl}>
          <CollapsibleTrigger className="flex items-center justify-between gap-1 py-1">
            <div className="flex items-center gap-1">
              <div className="text-neutral-400">URL Override</div>
              <TextWithTooltip tooltipText="This overrides the organization's backend URL">
                <InfoCircledIcon className="h-3 w-3" />
              </TextWithTooltip>
            </div>
            <ChevronDownIcon className="h-3 w-3" />
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <ServerConnectionForm
                ref={formRef}
                defaultValues={defaultFormValues}
              />
              <div className="flex items-center justify-between">
                <Button onClick={handleSubmit} size="xsm" variant="outline">
                  Submit
                </Button>
                <Button
                  onClick={handleClearServer}
                  variant="minimal"
                  size="xsm"
                  disabled={!overrideUrl}
                >
                  Clear
                </Button>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>

      {/* Organization Section */}
      <div className="border-t border-neutral-800 pt-3">
        <div className="text-neutral-400">Organization ID</div>
        <div className="text-neutral-600">
          {organizationOverrideId ? (
            <>
              <span className="line-through">{organization?.id}</span>
              <span className="ml-2 text-green-600">
                {organizationOverrideId}
              </span>
            </>
          ) : (
            organization?.id
          )}
        </div>
        <div className="mt-2 text-neutral-400">Organization Slug</div>
        <div className="text-neutral-600">
          {organizationOverrideSlug ? (
            <>
              <span className="line-through">{organizationSlug}</span>
              <span className="ml-2 text-green-600">
                {organizationOverrideSlug}
              </span>
            </>
          ) : (
            organizationSlug
          )}
        </div>
      </div>

      <div>
        <Collapsible
          defaultOpen={!!organizationOverrideId || !!organizationOverrideSlug}
        >
          <CollapsibleTrigger className="flex items-center justify-between gap-1 py-1">
            <div className="flex items-center gap-1">
              <div className="text-neutral-400">Organization Override</div>
              <TextWithTooltip tooltipText="This overrides the Clerk-defined organization ID and slug">
                <InfoCircledIcon className="h-3 w-3" />
              </TextWithTooltip>
            </div>
            <ChevronDownIcon className="h-3 w-3" />
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <input
                type="text"
                value={orgOverrideInput}
                onChange={handleOrgInputChange}
                placeholder="Enter organization ID"
                className="w-full rounded-md border border-neutral-800 bg-neutral-900 px-2 py-1 text-xs text-neutral-200 placeholder:text-neutral-500 focus:border-neutral-600 focus:outline-none focus:ring-1 focus:ring-neutral-600"
              />
              <input
                type="text"
                value={orgSlugOverrideInput}
                onChange={handleOrgSlugInputChange}
                placeholder="Enter organization slug"
                className="w-full rounded-md border border-neutral-800 bg-neutral-900 px-2 py-1 text-xs text-neutral-200 placeholder:text-neutral-500 focus:border-neutral-600 focus:outline-none focus:ring-1 focus:ring-neutral-600"
              />
              <div className="flex items-center justify-between">
                <Button
                  onClick={handleOrgSubmit}
                  size="xsm"
                  variant="secondary"
                  className="bg-neutral-800 hover:bg-neutral-700"
                >
                  Submit
                </Button>
                <Button
                  onClick={handleOrgClear}
                  variant="minimal"
                  size="xsm"
                  disabled={
                    !organizationOverrideId && !organizationOverrideSlug
                  }
                  className="text-neutral-400 hover:text-neutral-200 disabled:text-neutral-600"
                >
                  Clear
                </Button>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>

      {/* Display Mode Section */}
      <div className="border-t border-neutral-800 pt-3">
        <div className="mt-2 text-neutral-400">Display Mode</div>
        <div className="text-neutral-600">
          {modeOverride ? (
            <>
              <span className="line-through">{defaultDisplayMode}</span>
              <span className="ml-2 text-green-600">{effectiveMode}</span>
            </>
          ) : (
            defaultDisplayMode
          )}
        </div>
      </div>

      <div>
        <Collapsible defaultOpen={!!modeOverride}>
          <CollapsibleTrigger className="flex items-center justify-between gap-1 py-1">
            <div className="flex items-center gap-1">
              <div className="text-neutral-400">Display Mode Override</div>
              <TextWithTooltip tooltipText="Override the current display mode">
                <InfoCircledIcon className="h-3 w-3" />
              </TextWithTooltip>
            </div>
            <ChevronDownIcon className="h-3 w-3" />
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <Select
                value={modeOverride || "Select a display mode override"}
                onValueChange={(value) =>
                  handleDisplayModeSubmit((value as DisplayMode) || null)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select display mode">
                    {modeOverride || "Select a display mode override"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {(
                    [
                      "default",
                      "news",
                      "unscripted",
                      "scripted",
                      "s48",
                      "bs2",
                    ] as DisplayMode[]
                  ).map((mode) => (
                    <SelectItem key={mode} value={mode}>
                      {mode}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div className="flex items-center justify-between">
                <Button
                  onClick={() => handleDisplayModeSubmit(null)}
                  variant="minimal"
                  size="xsm"
                  disabled={!modeOverride}
                  className="text-neutral-400 hover:text-neutral-200 disabled:text-neutral-600"
                >
                  Clear Override
                </Button>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
});

interface DebuggerItemProps {
  label: string;
  contents: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  height?: number;
  view?: "json" | "text";
}

const DebuggerItem = React.memo(
  ({ label, contents, height }: DebuggerItemProps) => {
    const contentString = useMemo(
      () => JSON.stringify(contents, null, 2),
      [contents],
    );

    return (
      <div className="flex w-full flex-col py-2 text-xs text-neutral-500">
        <div className="flex items-center justify-between">
          <div className="font-semibold">{label.toUpperCase()}:</div>
          {contentString && contentString.length > 0 && (
            <CopyButton
              className="ml-2"
              content={contentString}
              toastMessage="Contents copied to clipboard"
              tooltipText="Copy contents"
            />
          )}
        </div>
        <textarea
          style={{ height: height && `${height}px` }}
          className="h-20 w-full whitespace-pre-wrap break-words border bg-neutral-800 py-1"
          readOnly
          value={contentString}
        />
      </div>
    );
  },
);

interface TabConfig {
  id: DebuggerTab;
  label: string;
  icon: React.ReactNode;
  Component: React.ComponentType;
}

const TAB_CONFIG: readonly TabConfig[] = [
  {
    id: "mocking",
    label: "Mocking",
    icon: <BarChartIcon className="h-4 w-4" />,
    Component: MockDataTab,
  },
  {
    id: "generation",
    label: "Generation",
    icon: <SparkleIcon className="h-4 w-4" />,
    Component: GenerationTab,
  },
  {
    id: "server",
    label: "Server",
    icon: <ServerIcon className="h-4 w-4" />,
    Component: ServerTab,
  },
  {
    id: "other",
    label: "Config",
    icon: <CogIcon className="h-4 w-4" />,
    Component: ConfigTab,
  },
] as const;

const DebuggerPanel = React.memo(() => {
  const { activeTab, setActiveTab } = useDebuggerTabState();

  const handleTabChange = useCallback(
    (value: string) => {
      setActiveTab(value as DebuggerTab);
    },
    [setActiveTab],
  );

  return (
    <Panel
      minSize={20}
      maxSize={30}
      defaultSize={20}
      className="pt-2"
      id="debugger-panel"
      order={4}
    >
      <div className="flex h-full w-full flex-col gap-4 divide-y overflow-hidden">
        <div className="flex items-center gap-1">
          <div className="flex flex-col gap-1 px-3">
            <div className="text-sm font-medium text-neutral-600">Debugger</div>
            <div className="text-xs text-neutral-400">
              I only exist for @trykino emails and in dev mode!
            </div>
          </div>
        </div>

        <Tabs
          defaultValue={TAB_CONFIG[0]?.id}
          onValueChange={handleTabChange}
          value={activeTab}
          className="flex h-full w-full flex-col overflow-hidden"
        >
          <TabsList className="no-scrollbar flex h-10 max-h-10 min-h-10 w-full justify-start gap-2 overflow-x-scroll border-b p-2 text-xs font-normal">
            {TAB_CONFIG.map(({ id, label, icon }) => (
              <TabsTrigger key={id} value={id}>
                <Tab
                  label={label}
                  value={id}
                  icon={icon}
                  isActive={activeTab === id}
                />
              </TabsTrigger>
            ))}
          </TabsList>

          {TAB_CONFIG.map(({ id, Component }) => (
            <TabsContent
              key={id}
              value={id}
              className="flex-grow overflow-auto"
            >
              <Component />
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </Panel>
  );
});

export { DebuggerPanel };
