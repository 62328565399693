import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { components } from "@/openapi-bindings/v2";
import {
  useDeletePerson,
  useFilteredPeople,
  useUploadReferenceFaces,
} from "@/hooks/usePeople";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
  Input,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  cn,
  Skeleton,
  ScrollArea,
} from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import { TrashIcon } from "@radix-ui/react-icons";
import { AddPersonDialog } from "./AddPersonDialog";
import React, { useRef, useState, useMemo, useCallback, memo } from "react";
import { toast } from "sonner";
import { UnassignedImage } from "@/types/people";
import ContextMenu, { MenuItem } from "../ContextMenu";
import { useUnassignedImagesStore } from "@/store/unassignedImagesStore";
import { PersonPhotoDropzone } from "./PersonPhotoDropzone";
import { CircleAlert, CircleUser, PlusCircle } from "lucide-react";

type Person = components["schemas"]["Person"];

export const PeopleSidebar = memo(() => {
  const { personId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: people, status } = useFilteredPeople(searchTerm);

  const navigate = useNavigate();

  const addUnassignedImages = useUnassignedImagesStore(
    (state) => state.addImages,
  );

  const onDrop = useCallback((acceptedFiles: UnassignedImage[]) => {
    addUnassignedImages(acceptedFiles);

    toast.success("Images added to unassigned list", {
      action: {
        label: "Go to unassigned",
        onClick: () => navigate("/people/unassigned"),
      },
    });
  }, []);

  const dialogRef = useRef<{ setOpen: (open: boolean) => void }>(null);

  return (
    <ScrollArea className="pointer-events-auto relative flex h-full min-w-72 max-w-72 flex-col gap-4 py-4">
      <div className="pointer-events-auto mb-2 px-2">
        <Input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search people..."
          className="pointer-events-auto h-8"
        />
      </div>
      <div className="pointer-events-auto relative flex flex-grow flex-col gap-1 px-2">
        <div
          className="border-border flex cursor-pointer items-center justify-start gap-2 rounded-md border bg-neutral-500/5 p-2 text-sm text-neutral-300 transition-all hover:bg-neutral-800"
          onClick={() => dialogRef.current?.setOpen(true)}
        >
          <PlusCircle className="h-5 w-5" />
          Create new person
        </div>
        <UnassignedImagesItem />
        {status === "pending" && (
          <>
            <div className="flex items-center gap-2 p-2 text-sm text-neutral-600">
              <Skeleton className="h-5 w-5 rounded-full bg-neutral-800" />
              <Skeleton className="h-2 w-40 rounded-md bg-neutral-800" />
            </div>
            <div className="flex items-center gap-2 p-2 text-sm text-neutral-600">
              <Skeleton className="h-5 w-5 rounded-full bg-neutral-800" />
              <Skeleton className="h-2 w-40 rounded-md bg-neutral-800" />
            </div>
            <div className="flex items-center gap-2 p-2 text-sm text-neutral-600">
              <Skeleton className="h-5 w-5 rounded-full bg-neutral-800" />
              <Skeleton className="h-2 w-40 rounded-md bg-neutral-800" />
            </div>
          </>
        )}
        {status === "error" && (
          <div className="flex items-center gap-2 p-2 text-sm text-neutral-600">
            <CircleAlert className="h-5 w-5" />
            Error loading people
          </div>
        )}
        {status === "success" &&
          people.map((person) => (
            <PeopleSidebarPerson
              key={person.id}
              person={person}
              isActive={person.id === personId}
            />
          ))}
        <PersonPhotoDropzone
          onDrop={onDrop}
          className="pointer-events-auto relative min-h-96 flex-grow"
        />
      </div>
      <AddPersonDialog triggerButton={false} ref={dialogRef} />
    </ScrollArea>
  );
});

const UnassignedImagesItem = () => {
  const location = useLocation();
  const numUnassignedImages = useUnassignedImagesStore(
    (state) => state.images.length,
  );
  const isOnUnassignedImagesPage = location.pathname === "/people/unassigned";

  if (!numUnassignedImages) {
    return null;
  }

  return (
    <Link
      to="/people/unassigned"
      className={cn(
        "flex cursor-pointer items-center gap-2 rounded-md p-2 text-sm text-neutral-300 transition-all hover:bg-neutral-800",
        isOnUnassignedImagesPage && "bg-neutral-800",
      )}
    >
      <CircleUser className="h-5 w-5" />
      Unassigned
      <span className="ml-auto text-xs text-neutral-500">
        {`(${numUnassignedImages})`}
      </span>
    </Link>
  );
};

const PeopleSidebarPerson: React.FC<{ person: Person; isActive: boolean }> = ({
  person,
  isActive,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { buildServerUrl } = useActiveServerStore();
  const navigate = useNavigate();

  const deletePerson = useDeletePerson();
  const uploadFaces = useUploadReferenceFaces();

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        label: "Delete Person",
        icon: <TrashIcon />,
        onClick: () => setDeleteDialogOpen(true),
      },
    ],
    [],
  );

  return (
    <>
      <PersonPhotoDropzone
        onDrop={(images) =>
          uploadFaces(
            { personId: person.id, images: images },
            {
              onSuccess: () =>
                toast.success(
                  `Reference image${images.length === 1 ? "" : "s"} uploaded for ${person.name}`,
                ),
              onError: () =>
                toast.error(
                  `References image${images.length === 1 ? "" : "s"} failed to upload to ${person.name}`,
                ),
            },
          )
        }
      >
        <ContextMenu customMenuItems={menuItems}>
          <Link
            to={{
              pathname: `/people/${person.id}`,
              search: `?person_ids=${person.id}`,
            }}
            className={cn(
              "pointer-events-auto flex cursor-pointer items-center gap-2 rounded-md p-2 text-sm text-neutral-300 transition-all hover:bg-neutral-800",
              isActive && "bg-neutral-800",
            )}
          >
            <Avatar size="xs">
              <AvatarImage
                src={buildServerUrl(person.thumbnail_path ?? "")}
                alt={`${person.name}'s avatar`}
                className="object-cover"
              />
              <AvatarFallback>{person.name[0]}</AvatarFallback>
            </Avatar>
            <span className="truncate">{person.name}</span>
            {person.reference_face_paths.length > 0 && (
              <span className="ml-auto text-xs text-neutral-500">
                ({person.reference_face_paths.length})
              </span>
            )}
          </Link>
        </ContextMenu>
      </PersonPhotoDropzone>
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete {person.name} and all their reference
              faces. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="mt-0">Cancel</AlertDialogCancel>
            <AlertDialogAction
              variant="destructive"
              onClick={() => {
                deletePerson({ personId: person.id });

                if (isActive) {
                  navigate("/people");
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

PeopleSidebar.displayName = "PeopleSidebar";
