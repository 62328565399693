import React from "react";
import { UploadCloud } from "lucide-react";
import { Button, cn } from "@kino/ui";
import { UnassignedImage } from "@/types/people";
import { usePersonImagesDropzone } from "@/hooks/usePeopleImageDrop";

interface UploadZoneProps {
  onDrop: (images: UnassignedImage[]) => void;
  className?: string | undefined;
}

export const PersonPhotoUploadZone: React.FC<UploadZoneProps> = ({
  onDrop,
  className,
}) => {
  const { getRootProps, getInputProps, isDragActive, isUploading, open } =
    usePersonImagesDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={cn(
        "cursor-pointer rounded-lg border-2 border-dashed p-12 text-center transition-colors",
        isDragActive
          ? "border-primary bg-accent/50"
          : "border-border hover:border-primary/50",
        isUploading && "cursor-not-allowed opacity-50",
        className,
      )}
    >
      <input {...getInputProps()} />
      <UploadCloud className="text-muted-foreground mx-auto mb-4 h-12 w-12" />
      <p className="text-lg font-medium">
        {isUploading
          ? "Uploading..."
          : isDragActive
            ? "Drop the images here..."
            : "Drag & drop images here, or click to select"}
      </p>
      <p className="text-muted-foreground mt-2 text-sm">
        Supports JPEG and PNG files
      </p>
      <Button
        variant="outline"
        className="mt-4"
        disabled={isUploading}
        onClick={open}
      >
        Select Files
      </Button>
    </div>
  );
};
