import { useClient, queryClient } from "@/hooks/useClient";
import { useSyncOrganization } from "@/store/organizationStore";
import { useUser } from "@clerk/clerk-react";
import { components } from "@/openapi-bindings/v2";
import { toast } from "sonner";
import { formatStringPlurality } from "@/utils/stringUtils";

type CreatePlaylistMomentRequestWithoutOrg = Omit<
  components["schemas"]["CreatePlaylistMomentRequest"],
  "organization_id"
>;
type DeletePlaylistMomentRequestWithoutOrg = Omit<
  components["schemas"]["DeletePlaylistMomentRequest"],
  "organization_id"
>;

export const usePlaylists = () => {
  const { apiClient } = useClient();
  const { user } = useUser();
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/playlists",
    {
      params: {
        query: {
          user_id: user?.id,
          organization_id: store.getEffectiveOrganizationId(),
        },
      },
    },
    {},
    queryClient,
  );
};

// Hook for playlist mutations
export function usePlaylistMutations() {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();

  const createPlaylistMutation = apiClient.useMutation(
    "post",
    "/playlists",
    {
      onSuccess: (response: components["schemas"]["PlaylistResponse"]) => {
        queryClient.invalidateQueries({
          queryKey: ["get", "/playlists"],
        });

        if (response.playlists?.[0]?.id) {
          toast.success("Playlist created successfully");
          return response.playlists[0].id;
        } else {
          toast.error("Failed to create playlist: No playlist ID returned");
          throw new Error("No playlist ID returned");
        }
      },
      onError: (error) => {
        console.error("Failed to create playlist:", error);
        toast.error("Failed to create playlist");
        throw error;
      },
    },
    queryClient,
  );

  const createPlaylist = async ({
    title,
    description,
    moments,
  }: {
    title: string;
    description?: string;
    moments?: components["schemas"]["CreatePlaylistMomentData"][];
  }) => {
    try {
      const response = await createPlaylistMutation.mutateAsync({
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          title,
          description,
          moments: moments || [],
        },
      });
      return response;
    } catch (error) {
      console.error("Error in createPlaylist:", error);
      throw error;
    }
  };

  const addMomentsMutation = apiClient.useMutation(
    "post",
    "/playlists/{playlist_id}/moments",
    {
      onSuccess: (response: components["schemas"]["PlaylistResponse"]) => {
        queryClient.invalidateQueries({ queryKey: ["get", "/playlists"] });
        toast.success(response.message || "Successfully added to playlist");
        return response;
      },
      onError: (error) => {
        console.error("Failed to add moments:", error);
        toast.error("Failed to add to playlist");
        throw error;
      },
    },
    queryClient,
  );

  const removeMomentsMutation = apiClient.useMutation(
    "delete",
    "/playlists/{playlist_id}/moments",
    {
      onSuccess: (response: components["schemas"]["PlaylistResponse"]) => {
        queryClient.invalidateQueries({
          queryKey: ["get", "/playlists"],
        });
        if (response.failed_operations?.length > 0) {
          const failedCount = response.failed_operations.length;
          toast.warning(
            `${failedCount} ${formatStringPlurality(failedCount, "moment", "moments")} could not be removed from the playlist`,
          );
        } else {
          const removedCount = response.playlists?.[0]?.moments?.length ?? 0;
          toast.success(
            `Successfully removed ${removedCount} ${formatStringPlurality(removedCount, "moment", "moments")} from playlist`,
          );
        }
        return response;
      },
      onError: (error) => {
        console.error("Failed to remove moments:", error);
      },
    },
    queryClient,
  );

  const deletePlaylistMutation = apiClient.useMutation(
    "delete",
    "/playlists",
    {
      onSuccess: (response: components["schemas"]["PlaylistResponse"]) => {
        queryClient.invalidateQueries({
          queryKey: ["get", "/playlists"],
        });
        const count = response.playlists?.length ?? 0;
        toast.success(
          `Successfully deleted ${count} ${formatStringPlurality(count, "playlist", "playlists")}`,
        );
        return response;
      },
      onError: (error) => {
        console.error("Failed to delete playlist:", error);
        toast.error("Failed to delete playlist");
        throw error;
      },
    },
    queryClient,
  );

  const addMoments = async ({
    playlist_id,
    moments,
  }: {
    playlist_id: string;
    moments: Array<{
      media_item_id: string;
      start_time: number;
      end_time: number;
      position?: number;
    }>;
  }) => {
    try {
      const response = await addMomentsMutation.mutateAsync({
        params: {
          path: {
            playlist_id,
          },
        },
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          playlist_id,
          moments,
        },
      });
      return response;
    } catch (error) {
      console.error("Error in addMoments:", error);
      throw error;
    }
  };

  const removeMoments = async ({
    playlist_id,
    moment_ids,
  }: {
    playlist_id: string;
    moment_ids: string[];
  }) => {
    try {
      const response = await removeMomentsMutation.mutateAsync({
        params: {
          path: {
            playlist_id,
          },
        },
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          playlist_id,
          moment_ids,
        },
      });
      return response;
    } catch (error) {
      console.error("Error in removeMoments:", error);
      throw error;
    }
  };

  const deletePlaylists = async (playlist_ids: string[]) => {
    try {
      const response = await deletePlaylistMutation.mutateAsync({
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          playlist_ids,
        },
      });
      return response;
    } catch (error) {
      console.error("Error in deletePlaylist:", error);
      throw error;
    }
  };

  return {
    createPlaylist,
    createPlaylistMutation,
    addMoments,
    addMomentsMutation,
    removeMoments,
    removeMomentsMutation,
    deletePlaylists,
    deletePlaylistMutation,
  };
}

export const usePlaylistMoments = () => {
  const { apiClient } = useClient();
  const store = useSyncOrganization();
  const organizationId = store.getEffectiveOrganizationId();

  const addMomentsMutation = apiClient.useMutation(
    "post",
    "/playlists/{playlist_id}/moments",
    {
      onSuccess: (response: components["schemas"]["PlaylistResponse"]) => {
        queryClient.invalidateQueries({ queryKey: ["get", "/playlists"] });
        toast.success(response.message || "Successfully added to playlist");
        return response;
      },
      onError: (error) => {
        console.error("Failed to add moments:", error);
        toast.error("Failed to add to playlist");
        throw error;
      },
    },
    queryClient,
  );

  const removeMomentsMutation = apiClient.useMutation(
    "delete",
    "/playlists/{playlist_id}/moments",
    {
      onSuccess: (response: components["schemas"]["PlaylistResponse"]) => {
        queryClient.invalidateQueries({ queryKey: ["get", "/playlists"] });
        if (response.failed_operations?.length > 0) {
          const failedCount = response.failed_operations.length;
          toast.warning(
            `${failedCount} ${formatStringPlurality(failedCount, "moment", "moments")} could not be removed from the playlist`,
          );
        } else {
          const removedCount = response.playlists?.[0]?.moments?.length ?? 0;
          toast.success(
            `Successfully removed ${removedCount} ${formatStringPlurality(removedCount, "moment", "moments")} from playlist`,
          );
        }
      },
      onError: (error) => {
        console.error("Failed to remove moments:", error);
      },
    },
    queryClient,
  );

  return {
    addMoments: async (
      createPlaylistMomentRequest: CreatePlaylistMomentRequestWithoutOrg,
    ) =>
      await addMomentsMutation.mutateAsync({
        params: {
          path: {
            playlist_id: createPlaylistMomentRequest.playlist_id,
          },
        },
        body: {
          ...createPlaylistMomentRequest,
          organization_id: organizationId,
        },
      }),
    removeMoments: async (
      deletePlaylistMomentRequest: DeletePlaylistMomentRequestWithoutOrg,
    ) =>
      await removeMomentsMutation.mutateAsync({
        params: {
          path: {
            playlist_id: deletePlaylistMomentRequest.playlist_id,
          },
        },
        body: {
          ...deletePlaylistMomentRequest,
          organization_id: organizationId,
        },
      }),
    isLoading: addMomentsMutation.isPending || removeMomentsMutation.isPending,
    error: addMomentsMutation.error || removeMomentsMutation.error,
  };
};
