import { components } from "@/openapi-bindings/v2";
import { HydratedBucket, ParsedQuery } from "@/types";

export function parseSearchQuery(query: string): ParsedQuery {
  const frameRegex = /(\+|-)?\s*frame_id:([\w-]+)/g;
  const frames: { id: string; isPositive: boolean }[] = [];
  let match;
  while ((match = frameRegex.exec(query)) !== null) {
    if (match[2]) {
      frames.push({ id: match[2], isPositive: match[1] !== "-" });
    }
  }
  let queryString = query.replace(frameRegex, "").trim();
  queryString = queryString.startsWith("+")
    ? queryString.slice(1).trim()
    : queryString;

  return { frames, queryString: queryString || undefined };
}

export function stringifyParsedQuery(parsedQuery: ParsedQuery): string {
  let query = "";

  parsedQuery.frames.forEach((frame, index) => {
    if (index !== 0) {
      query += " ";
    }
    query += `${index !== 0 ? (frame.isPositive ? "+" : "-") : ""}frame_id:${
      frame.id
    }`;
  });

  if (parsedQuery.queryString) {
    if (query !== "") {
      query += " + ";
    }
    query += parsedQuery.queryString;
  }

  return query.trim();
}

export const getHitsBySearchMode = (
  searchResponse: components["schemas"]["SearchResponse"],
  searchMode: components["schemas"]["SearchMode"],
): components["schemas"]["Hit"][] => {
  switch (searchMode) {
    case "omni":
      return searchResponse.omni_hits ?? [];
    case "transcript":
      return searchResponse.transcript_hits ?? [];
    case "visual":
      return searchResponse.visual_hits ?? [];
    default:
      return [];
  }
};

export const getMomentsBySearchMode = (
  searchResponse: components["schemas"]["SearchResponse"],
  searchMode: components["schemas"]["SearchMode"],
): components["schemas"]["Moment"][] => {
  const hits = getHitsBySearchMode(searchResponse, searchMode);
  const result: components["schemas"]["Moment"][] = [];

  for (const hit of hits) {
    const mediaItem = searchResponse.media_items?.[hit.media_item_id];
    if (mediaItem) {
      const moment = mediaItem.moments.find((m) => m.id === hit.moment_id);
      if (moment) {
        result.push(moment);
      }
    }
  }

  return result;
};

export const getHydratedBucketsByAggregationKey = (
  searchResponse: components["schemas"]["SearchResponse"],
  aggregationKey: string,
  searchMode: components["schemas"]["SearchMode"],
): HydratedBucket[] | undefined => {
  const hits = getHitsBySearchMode(searchResponse, searchMode);
  const searchModeAggregations = searchResponse.aggregations?.[searchMode];

  const aggregation = searchModeAggregations?.find(
    (agg) => agg.key === aggregationKey,
  );

  // Create a map of moment_id -> score for efficient lookup
  const momentScores = new Map<string, number>();
  hits.forEach((hit) => {
    momentScores.set(hit.moment_id, hit.score);
  });

  // Helper function to sort buckets by highest scoring moment
  const sortBucketsByRelevance = (buckets: HydratedBucket[]) => {
    return buckets.sort((a, b) => {
      const aMaxScore = Math.max(
        ...a.moments.map((m) => momentScores.get(m.id) ?? 0),
      );
      const bMaxScore = Math.max(
        ...b.moments.map((m) => momentScores.get(m.id) ?? 0),
      );
      return bMaxScore - aMaxScore; // Descending order
    });
  };

  if (aggregationKey === "by_video") {
    // Bucket values are media item ids
    const bucketKeys = aggregation?.values ?? [];
    const hydratedBuckets: HydratedBucket[] = [];

    // Create a map of media_item_id -> Set<moment_id> for efficient lookup
    const mediaItemHits = new Map<string, Set<string>>();
    hits.forEach((hit) => {
      if (!mediaItemHits.has(hit.media_item_id)) {
        mediaItemHits.set(hit.media_item_id, new Set());
      }
      mediaItemHits.get(hit.media_item_id)?.add(hit.moment_id);
    });

    bucketKeys.forEach((bucketKey) => {
      const mediaItem = searchResponse.media_items?.[bucketKey];
      if (!mediaItem) return;

      const hitMomentIds = mediaItemHits.get(bucketKey);
      if (!hitMomentIds) return;

      // Filter moments to only include those that matched the search
      const matchedMoments = mediaItem.moments
        .filter((moment) => hitMomentIds.has(moment.id))
        // Sort moments within each bucket by start time
        .sort((a, b) => a.start - b.start);

      hydratedBuckets.push({
        key: bucketKey,
        moments: matchedMoments,
      });
    });

    return sortBucketsByRelevance(hydratedBuckets);
  } else if (aggregationKey === "by_people") {
    // Bucket values are people ids ie: John_Doe, Jane_Doe, etc.
    const bucketKeys: string[] = aggregation?.values ?? [];
    const hydratedBuckets: HydratedBucket[] = [];

    // Create a map of media_item_id -> Set<moment_id> for efficient lookup
    const mediaItemHits = new Map<string, Set<string>>();
    hits.forEach((hit) => {
      if (!mediaItemHits.has(hit.media_item_id)) {
        mediaItemHits.set(hit.media_item_id, new Set());
      }
      mediaItemHits.get(hit.media_item_id)?.add(hit.moment_id);
    });

    bucketKeys.forEach((bucketKey) => {
      const matchedMoments: components["schemas"]["Moment"][] = [];

      // For each media item that had hits
      mediaItemHits.forEach((hitMomentIds, mediaItemId) => {
        const mediaItem = searchResponse.media_items?.[mediaItemId];
        if (!mediaItem) return;

        // Filter moments to only include those that matched the search AND have this person
        const moments = mediaItem.moments
          .filter(
            (moment) =>
              hitMomentIds.has(moment.id) &&
              moment.person_ids.includes(bucketKey),
          )
          // Sort moments within each bucket by start time
          .sort((a, b) => a.start - b.start);
        matchedMoments.push(...moments);
      });

      hydratedBuckets.push({
        key: bucketKey,
        moments: matchedMoments.sort((a, b) => a.start - b.start),
      });
    });

    return sortBucketsByRelevance(hydratedBuckets);
  }
};

export const momentTypeEnumToLabel = (momentType: number | string) => {
  const type = Number(momentType);
  switch (type) {
    case 1:
      return "VIDEO";
    case 2:
      return "SOUND";
    case 3:
      return "IMAGE";
    case 4:
      return "OCR";
    case 5:
      return "TRANSCRIPT";
    default:
      return "Unknown";
  }
};

export const aggregationKeyToLabel = (aggregationKey: string): string => {
  // Handle tag-specific aggregations
  if (aggregationKey.startsWith("by_tag_")) {
    // Extract the tag name after "by_tag_"
    const tagName = aggregationKey.slice(7);
    return tagName;
  }

  // Handle other common aggregation keys
  if (aggregationKey === "key" || aggregationKey === "values") {
    return aggregationKey;
  }

  // For any other keys, just clean up the format
  const words = aggregationKey
    .replace(/^by_/, "") // Remove leading "by_"
    .split("_"); // Split on underscores

  const label = words
    .map(
      (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(" ");

  return label;
};
