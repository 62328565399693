import { MediaItem } from "@/context/MediaItemContext/MediaItem";
import { components } from "@/openapi-bindings/v2";
import { useDisplayStore } from "@/store/display/displayStore";
import { FileNameDisplaySource } from "@/types/display";
import { getFileName } from "./pathUtils";

type MediaItem = components["schemas"]["MediaItem"];
type UmidData = components["schemas"]["UmidData"];

export const selectPathFileName = (mediaItem: MediaItem) => getFileName(mediaItem.original_path) ?? null;
export const selectUniqueClip = (mediaItem: MediaItem) => (mediaItem.metadata?.umiddata as UmidData)?.unique_clip ?? null;

export function useMediaItemDisplayFileName(mediaItem: MediaItem | undefined, fallback: string): string;
export function useMediaItemDisplayFileName(mediaItem?: MediaItem, fallback?: null): string | null;
export function useMediaItemDisplayFileName(mediaItem?: MediaItem, fallback: string | null = null): string | null {
    const filenameDisplaySource = useDisplayStore(state => state.fileNameDisplaySource);

    switch (filenameDisplaySource) {
        case FileNameDisplaySource.UniqueClip:
            return mediaItem ? selectUniqueClip(mediaItem) : fallback;
        case FileNameDisplaySource.Path:
        default:
            return mediaItem ? selectPathFileName(mediaItem) : fallback;
    }
}
