import { usePersonImagesDropzone } from "@/hooks/usePeopleImageDrop";
import { UnassignedImage } from "@/types/people";
import { cn } from "@kino/ui";
import { PropsWithChildren } from "react";

export const PersonPhotoDropzone: React.FC<
  PropsWithChildren<{
    onDrop: (images: UnassignedImage[]) => void;
    className?: string;
  }>
> = ({ onDrop, children, className }) => {
  const { getRootProps, getInputProps, isDragActive } = usePersonImagesDropzone(
    {
      onDrop,
    },
  );

  return (
    <div
      className={cn(
        "relative inset-0 overflow-hidden",
        isDragActive ? "pointer-events-auto" : "pointer-events-none",
        className,
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {children}
      {isDragActive && (
        <div className="bg-accent/50 border-primary pointer-events-none absolute inset-0 z-10 border-2" />
      )}
    </div>
  );
};
