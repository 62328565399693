import { DisplayMode } from "@/types/display";
import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";
import { persist } from "zustand/middleware";

export type DebuggerTab = "mocking" | "generation" | "server" | "other";

interface DebuggerStore {
  isMockSearchResults: boolean;
  setIsMockSearchResults: (isMockSearchResults: boolean) => void;
  isMockMediaEndpoint: boolean;
  setIsMockMediaEndpoint: (isMockMediaEndpoint: boolean) => void;
  isMockSeasonStats: boolean;
  setIsMockSeasonStats: (isMockSeasonStats: boolean) => void;
  isMockTagsEndpoint: boolean;
  setIsMockTagsEndpoint: (isMockTagsEndpoint: boolean) => void;
  isMockPeopleEndpoint: boolean;
  setIsMockPeopleEndpoint: (isMockPeopleEndpoint: boolean) => void;
  showComments: boolean;
  setShowComments: (showComments: boolean) => void;
  showExportPlaylistsAsAaf: boolean;
  setShowExportPlaylistsAsAaf: (showExportPlaylistsAsAaf: boolean) => void;
  summarizationPrompt: string | undefined;
  setSummarizationPrompt: (summarizationPrompt: string | undefined) => void;
  activeTab: DebuggerTab;
  setActiveTab: (activeTab: DebuggerTab) => void;
  defaultDisplayMode: DisplayMode;
  setDefaultDisplayMode: (defaultDisplayMode: DisplayMode) => void;
  isReactScanEnabled: boolean;
  setIsReactScanEnabled: (isReactScanEnabled: boolean) => void;
}

export const useDebuggerStore = createClearable([
  StoreCollectionIdentifier.ORGANIZATION,
])<DebuggerStore>()(
  persist(
    (set) => ({
      isMockSearchResults: false,
      setIsMockSearchResults: (isMockSearchResults) =>
        set({ isMockSearchResults }),
      isMockMediaEndpoint: false,
      setIsMockMediaEndpoint: (isMockMediaEndpoint) =>
        set({ isMockMediaEndpoint }),
      isMockSeasonStats: false,
      setIsMockSeasonStats: (isMockSeasonStats) => set({ isMockSeasonStats }),
      isMockTagsEndpoint: false,
      setIsMockTagsEndpoint: (isMockTagsEndpoint) =>
        set({ isMockTagsEndpoint }),
      isMockPeopleEndpoint: false,
      setIsMockPeopleEndpoint: (isMockPeopleEndpoint) =>
        set({ isMockPeopleEndpoint }),
      showComments: false,
      setShowComments: (showComments) => set({ showComments }),
      showExportPlaylistsAsAaf: false,
      setShowExportPlaylistsAsAaf: (showExportPlaylistsAsAaf) =>
        set({ showExportPlaylistsAsAaf }),
      summarizationPrompt: undefined,
      setSummarizationPrompt: (summarizationPrompt) =>
        set({ summarizationPrompt }),
      activeTab: "mocking",
      setActiveTab: (activeTab) => set({ activeTab }),
      defaultDisplayMode: "s48",
      setDefaultDisplayMode: (defaultDisplayMode) =>
        set({ defaultDisplayMode }),
      isReactScanEnabled: false,
      setIsReactScanEnabled: (isReactScanEnabled) =>
        set({ isReactScanEnabled }),
    }),
    {
      name: "debugger-store",
    },
  ),
);
