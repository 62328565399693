import { ICellRendererParams } from "ag-grid-community";
import { Avatar, AvatarImage, AvatarFallback } from "@kino/ui";
import { components } from "@/openapi-bindings/v2";
import { LayoutGrid, VideoIcon } from "lucide-react";
import { useDisplayTags } from "@/hooks/useGetTagTypeDefinition";
import { Tag } from "@/types/filters";
import { useState } from "react";
import { PersonPopover } from "@/components/popover/PersonPopover";
import { FilterBadgeArray } from "@/components/filter/filterPanel/array/FilterBadgeArray";

interface PeopleCellProps extends ICellRendererParams {
  value: components["schemas"]["Person"];
  buildServerUrl: (path: string) => string;
}

interface TextCellProps extends ICellRendererParams {
  value: string;
  allowWrapping?: boolean;
}

interface NumberCellProps extends ICellRendererParams {
  value: number;
  formatOptions?: Intl.NumberFormatOptions;
}

// Helper function for initials
const getInitials = (name: string) => {
  return name
    .split(" ")
    .map((part) => part.charAt(0))
    .join("")
    .toUpperCase();
};

interface HasMulticamCellProps extends ICellRendererParams {
  value: boolean;
}

export const HasMulticamCellRenderer = ({ value }: HasMulticamCellProps) => {
  return (
    <div className="ag-value">
      {value && <LayoutGrid className="h-4 w-4 text-amber-500" />}
    </div>
  );
};

export const PeopleCellRenderer = ({
  value,
  buildServerUrl,
}: PeopleCellProps) => {
  if (!value || !Array.isArray(value)) return null;

  return (
    <div className="flex h-full w-full items-center gap-2 p-1">
      {value.map((person, index) => (
        <FilterBadgeArray
          key={`${person.id}-${index}`}
          filterKey="person_ids"
          filterOption={{
            id: person.id,
            label: person.name,
            value: person.name,
            icon: (
              <Avatar className="h-full w-full">
                <AvatarImage
                  src={buildServerUrl(person.thumbnail_path || "")}
                />
                <AvatarFallback className="text-[8px]">
                  {getInitials(person.name)}
                </AvatarFallback>
              </Avatar>
            ),
          }}
          displayOnly
          focused={false}
        />
      ))}
    </div>
  );
};

PeopleCellRenderer.cellStyle = {
  display: "flex",
  alignItems: "start",
  overflow: "hidden",
  height: "100%",
  flexWrap: "wrap",
  padding: "8px 0",
};

export const TextCellRenderer = ({
  value,
  allowWrapping = false,
}: TextCellProps) => {
  return (
    <div
      className={`p-1 ${
        allowWrapping
          ? "whitespace-normal"
          : "overflow-hidden text-ellipsis whitespace-nowrap"
      }`}
    >
      {value}
    </div>
  );
};

export const NumberCellRenderer = ({
  value,
  formatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
}: NumberCellProps) => {
  const formattedValue = new Intl.NumberFormat(undefined, formatOptions).format(
    value,
  );

  return <div className="p-1 text-right">{formattedValue}</div>;
};

interface ThumbnailCellProps extends ICellRendererParams {
  value: string | null;
}

export const ThumbnailCellRenderer = ({ value }: ThumbnailCellProps) => {
  const [hasError, setHasError] = useState(false);

  const Fallback = () => (
    <div className="flex aspect-video h-10 min-w-12 items-center justify-center rounded bg-neutral-400/10 text-neutral-300/50">
      <VideoIcon size={12} />
    </div>
  );

  if (!value || hasError) {
    return <Fallback />;
  }
  return (
    <img
      src={value}
      alt="thumbnail"
      className="h-10 rounded-sm object-contain"
      onError={() => setHasError(true)}
    />
  );
};

ThumbnailCellRenderer.cellStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: "2px",
};
interface TagCellProps extends ICellRendererParams {
  value: Tag[];
}

export const TagCellRenderer = ({ value }: TagCellProps) => {
  const { getTagTypeDefinition } = useDisplayTags();
  if (!value || !Array.isArray(value) || value.length === 0) return null;

  return (
    <div className="flex h-full w-full items-center gap-2 p-1">
      {value.map((tag, index) => {
        const tagDef = getTagTypeDefinition(tag.tag_type);
        const baseColor = tagDef.baseColor;

        return (
          <div
            key={`${tag.id}-${index}`}
            className={`flex items-center gap-1 rounded-md border px-2 py-1
              ${
                baseColor === "neutral"
                  ? "border-neutral-700 bg-neutral-800/20 text-neutral-400"
                  : `border-${baseColor}-700/30 bg-${baseColor}-900/10 text-${baseColor}-400`
              }`}
          >
            <span className="whitespace-nowrap text-xs">{tag.value}</span>
          </div>
        );
      })}
    </div>
  );
};

TagCellRenderer.cellStyle = {
  display: "flex",
  alignItems: "start",
  overflow: "hidden",
  height: "100%",
  flexWrap: "wrap",
  padding: "8px 0",
};

export const IndexCellRenderer = ({ value }: { value: number }) => {
  return (
    <div className="flex h-full items-center justify-center">
      <span className="text-neutral-600">{value}</span>
    </div>
  );
};
