import { MomentId } from "@/store/playlists/playlistsStore";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { constructMomentFromMomentId, formatMomentId } from "@/utils/moment";
import { Button, cn } from "@kino/ui";
import { ArrowLeftIcon, ArrowRightIcon, Download } from "lucide-react";
import PlaylistCover from "../playlists/PlaylistCover";
import { useMediaState, usePrimaryViewportContext } from "@kino/player";
import { usePlaylists } from "@/hooks/usePlaylists";
import { components } from "@/openapi-bindings/v2";
import { useMediaItemQuery } from "@/hooks/useAllMediaQuery";

interface ViewportPanelPlaylistHeaderProps {
  playlistMoment: components["schemas"]["PlaylistMoment"];
  playlistId: string;
}

export const ViewportPanelPlaylistHeader = ({
  playlistId,
  playlistMoment,
}: ViewportPanelPlaylistHeaderProps) => {
  const { data: playlistResponse } = usePlaylists();
  const playlist = playlistResponse?.playlists.find(
    ({ id }) => id === playlistId,
  );

  if (!playlist) {
    return null;
  }

  const playlistMomentIndex = playlist.moments?.findIndex(
    (moment) => moment.moment_id === playlistMoment.moment_id,
  );

  if (
    !playlist ||
    !playlist.moments ||
    playlistMomentIndex === undefined ||
    playlistMomentIndex === -1
  ) {
    return null;
  }

  const moments = playlist.moments;
  const prevMoment =
    playlistMomentIndex > 0 ? moments[playlistMomentIndex - 1] : null;
  const nextMoment =
    playlistMomentIndex < moments.length - 1
      ? moments[playlistMomentIndex + 1]
      : null;

  return (
    <div className="flex w-full flex-col gap-2 border-b p-2 text-xs text-white">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-1">
          <div className="h-5 w-5">
            <PlaylistCover playlist={playlist} />
          </div>
          <span className="text-xs">{playlist.title}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-xs tabular-nums text-neutral-400">
            {`${playlistMomentIndex + 1} / ${moments.length}`}
          </span>
          <PlaylistNavigationButton moment={prevMoment}>
            <ArrowLeftIcon size={10} />
          </PlaylistNavigationButton>
          <PlaylistNavigationButton moment={nextMoment}>
            <ArrowRightIcon size={10} />
          </PlaylistNavigationButton>
        </div>
      </div>
      <PlaylistMomentSegments
        playlist={playlist}
        playlistMoment={playlistMoment}
      />
    </div>
  );
};

interface PlaylistNavigationButtonProps {
  moment: components["schemas"]["PlaylistMoment"] | null | undefined;
  children: React.ReactNode;
}

const PlaylistNavigationButton = ({
  moment,
  children,
}: PlaylistNavigationButtonProps) => {
  const { data: mediaItem } = useMediaItemQuery(moment?.media_item_id);
  const setCurrentSelection = useSelectedMediaStore(
    (state) => state.setCurrentSelection,
  );

  if (!mediaItem || !moment) {
    return (
      <Button className="h-4 w-4 p-3 [&_svg]:size-3" disabled>
        {children}
      </Button>
    );
  }

  const constructedMoment = constructMomentFromMomentId(
    moment.moment_id as MomentId,
    mediaItem,
  );

  return (
    <Button
      onClick={() =>
        setCurrentSelection([
          {
            id: moment.moment_id,
            activeInspectorMoment: {
              moment: constructedMoment,
              searchMode: "omni",
            },
            mediaItem,
          },
        ])
      }
      className="h-4 w-4 p-3 [&_svg]:size-3"
    >
      {children}
    </Button>
  );
};

type PlaylistSegment = {
  startTime: number;
  duration: number;
};

interface PlaylistMomentSegmentsProps {
  playlist: components["schemas"]["Playlist"];
  playlistMoment: components["schemas"]["PlaylistMoment"];
}

const PlaylistMomentSegments = ({
  playlist,
  playlistMoment,
}: PlaylistMomentSegmentsProps) => {
  const { playerRef } = usePrimaryViewportContext();

  if (!playlist.moments) {
    return null;
  }

  const currentTime = useMediaState("currentTime", playerRef);
  const playlistParsedIds = playlist.moments.map((moment) => ({
    mediaItemId: moment.media_item_id,
    start: moment.start_time,
    end: moment.end_time,
  }));

  const { totalDuration, playlistSegments } = playlistParsedIds.reduce<{
    totalDuration: number;
    playlistSegments: PlaylistSegment[];
  }>(
    (acc, id) => ({
      totalDuration: acc.totalDuration + id.end - id.start,
      playlistSegments: [
        ...acc.playlistSegments,
        {
          momentId: formatMomentId(id),
          startTime: acc.totalDuration,
          duration: id.end - id.start,
        },
      ],
    }),
    {
      totalDuration: 0,
      playlistSegments: [],
    },
  );

  const currentPlayingMomentIndex = playlist.moments.findIndex(
    (moment) => moment.moment_id === playlistMoment.moment_id,
  );

  return (
    <div className="relative flex h-1 w-full gap-2">
      {playlistSegments.map(({ startTime, duration }, i) => (
        <span
          className={cn(
            "relative h-full overflow-clip rounded-full bg-neutral-600",
            i < currentPlayingMomentIndex && "bg-neutral-400",
          )}
          style={{ width: (duration / totalDuration) * 100 + "%" }}
        >
          {i === currentPlayingMomentIndex && (
            <span
              className={cn("absolute left-0 h-full bg-neutral-400")}
              style={{ width: (currentTime / duration) * 100 + "%" }}
            />
          )}
        </span>
      ))}
    </div>
  );
};
