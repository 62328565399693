import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@kino/ui";
import { usePeople, useUploadReferenceFaces } from "@/hooks/usePeople";
import { useCallback, useState } from "react";
import { useUnassignedImagesStore } from "@/store/unassignedImagesStore";
import { UnassignedImage } from "@/types/people";
import { TrashIcon, XIcon } from "lucide-react";
import { PersonPhotoDropzone } from "@/components/people/PersonPhotoDropzone";
import { toast } from "sonner";

const UnassignedView = () => {
  const peopleQuery = usePeople();
  const [selectedPersonId, setSelectedPersonId] = useState<string | undefined>(
    undefined,
  );

  const unassignedImages = useUnassignedImagesStore((state) => state.images);
  const addImages = useUnassignedImagesStore((state) => state.addImages);
  const removeImage = useUnassignedImagesStore((state) => state.removeImage);
  const toggleImage = useUnassignedImagesStore((state) => state.toggleImage);
  const clearSelected = useUnassignedImagesStore(
    (state) => state.clearSelected,
  );
  const selectedImages = useUnassignedImagesStore((state) => state.selected);

  const uploadFaces = useUploadReferenceFaces();

  const assignImages = useCallback(
    async (personId: string, images: UnassignedImage[]) => {
      await uploadFaces(
        { personId, images },
        {
          onSuccess: () =>
            toast.success(
              `Reference image${images.length > 1 ? "" : "s"} assigned`,
            ),
          onError: () => toast.error(`References images failed to upload`),
        },
      );

      images.forEach(({ id }) => removeImage(id));
    },
    [uploadFaces],
  );

  let personSelect;

  if (peopleQuery.status === "pending") {
    personSelect = (
      <Select>
        <SelectTrigger>
          <SelectValue placeholder="Loading" />
        </SelectTrigger>
      </Select>
    );
  } else if (peopleQuery.status === "error") {
    personSelect = null;
  } else {
    personSelect = (
      <Select value={selectedPersonId} onValueChange={setSelectedPersonId}>
        <SelectTrigger className="h-9 border-neutral-300/20">
          <SelectValue placeholder="Assign to person" />
        </SelectTrigger>
        <SelectContent>
          {peopleQuery.data.map((person) => (
            <SelectItem key={person.id} value={person.id}>
              {person.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }

  return (
    <PersonPhotoDropzone
      onDrop={(images) => addImages(images)}
      className="h-full"
    >
      <div className="pointer-events-auto relative flex h-full flex-grow flex-col gap-6 px-2 py-4">
        <h2 className="text-2xl font-bold">Unassigned Images</h2>
        <div className="flex flex-wrap gap-4">
          {unassignedImages.map((image) => {
            const isSelected =
              selectedImages.findIndex(({ id }) => id === image.id) > -1;

            return (
              <div
                key={image.id}
                className="relative h-24 w-24 cursor-pointer overflow-hidden rounded-md"
                onClick={() => toggleImage(image)}
              >
                <img
                  src={image.preview}
                  className="aspect-square object-cover"
                />
                <Checkbox
                  checked={isSelected}
                  className="absolute bottom-1 right-1"
                />
              </div>
            );
          })}
        </div>
        {!!selectedImages.length && (
          <Card className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-neutral-800 px-2 py-1">
            <CardContent className="flex items-center gap-2 p-1">
              <div className="flex h-6 items-center">
                <Button
                  variant="ghost"
                  className="whitespace-nowrap rounded-r-none border border-r-0 border-dashed border-neutral-300/20 px-2 py-1 disabled:opacity-100"
                  disabled
                >
                  {`${selectedImages.length} image${selectedImages.length === 1 ? "" : "s"} selected`}
                </Button>
                <Button
                  variant="ghost"
                  className="rounded-l-none border border-dashed border-neutral-300/20 p-2 hover:bg-neutral-900/50"
                  onClick={clearSelected}
                >
                  <XIcon className="h-4 w-4" />
                </Button>
              </div>
              <div className="w-48">{personSelect}</div>
              <Button
                variant="outline"
                className="h-9 border-neutral-300/20 bg-neutral-800 p-2"
                onClick={() =>
                  selectedImages.forEach(({ id }) => removeImage(id))
                }
              >
                <TrashIcon />
              </Button>
              <Button
                variant="kino"
                onClick={
                  selectedPersonId
                    ? () => assignImages(selectedPersonId, selectedImages)
                    : () => {}
                }
                disabled={!selectedPersonId}
              >
                Confirm
              </Button>
            </CardContent>
          </Card>
        )}
      </div>
    </PersonPhotoDropzone>
  );
};

export default UnassignedView;
