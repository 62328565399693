import {
  useMediaPlayer,
  useMediaStore,
  useMediaState,
  type MediaSeekRequestEvent,
  MediaPlayEvent,
} from "@vidstack/react";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export interface PlayerZoom {
  start: number;
  end: number;
}

export interface IPlayerZoomControllerContext {
  playerZoom: PlayerZoom;
  setPlayerZoom: (playerZoom: PlayerZoom) => void;
}

const PlayerZoomControllerContext = createContext<
  IPlayerZoomControllerContext | undefined
>(undefined);

interface PlayerZoomControlProps extends PropsWithChildren {
  initialZoom?: PlayerZoom;
  trackCurrentTime?: boolean;
}

export const PlayerZoomControllerProvider: React.FC<PlayerZoomControlProps> = ({
  children,
  initialZoom = { start: 0, end: Infinity },
  trackCurrentTime = false,
}) => {
  const player = useMediaPlayer();
  const { intrinsicDuration, clipStartTime } = useMediaStore();

  const usingDefaultZoom = useRef<boolean>(true);
  const [playerZoom, setPlayerZoom] = useState<PlayerZoom>(initialZoom);

  const [isTrackingCurrentTime, setIsTrackingCurrentTime] =
    useState<boolean>(trackCurrentTime);

  const currentTime = useMediaState("realCurrentTime");

  const zoomDuration = playerZoom.end - playerZoom.start;

  const makeClampedZoom = useCallback(
    (start: number, end?: number) => {
      const clampedStart = Math.max(0, start);
      const clampedEnd = Math.min(
        intrinsicDuration,
        end || start + zoomDuration,
      );

      const adjustedEnd = clampedEnd + (clampedStart - start);

      return {
        start: clampedStart,
        end: adjustedEnd,
      };
    },
    [intrinsicDuration, zoomDuration],
  );

  /**
   * As long as the zoom has not been changed manually,
   * set the zoom to the provided initial zoom
   */
  useEffect(() => {
    if (!usingDefaultZoom.current) {
      return;
    }

    setPlayerZoom({
      start: initialZoom.start,
      end: initialZoom.end,
    });
  }, [initialZoom.start, initialZoom.end]);

  /**
   * As long as an initial zoom was not provided, or the zoom has not changed,
   * set the zoom to the entire duration of the video
   */
  useEffect(() => {
    if (!intrinsicDuration) return;
    if (!usingDefaultZoom.current) return;

    setPlayerZoom((prev) => {
      if (Number.isFinite(prev.end)) return prev;

      return { ...prev, end: intrinsicDuration };
    });
  }, [intrinsicDuration]);

  /**
   * On player "seek" event:
   *
   * If a zoom event is passed in a seek request on the player,
   * set the zoom to the surrounding area of the time that is being seeked to
   */
  useEffect(() => {
    if (!player) {
      return;
    }

    const handleSeekRequest = (e: MediaSeekRequestEvent) => {
      if (!e.triggers.hasType(ZOOM_EVENT_TYPE)) {
        return;
      }

      if (playerZoom.start < e.detail && e.detail < playerZoom.end) {
        return;
      }

      setPlayerZoom(makeClampedZoom(e.detail - 1));
    };

    player.addEventListener(
      "media-seek-request",
      handleSeekRequest as EventListenerOrEventListenerObject,
    );

    return () => {
      player.removeEventListener(
        "media-seek-request",
        handleSeekRequest as EventListenerOrEventListenerObject,
      );
    };
  }, [player, makeClampedZoom, playerZoom.start, playerZoom.end]);

  /**
   * On player "play" event:
   *
   * 1. Reset track current time state to original; if supposed to track, start tracking again
   * 2. If not already within the zoom window, Set the zoom to start at the playhead
   */
  const handlePlay = useCallback(
    (e: MediaPlayEvent) => {
      const currentTime = e.target.currentTime + (clipStartTime || 0);

      setIsTrackingCurrentTime(trackCurrentTime);

      if (playerZoom.start < currentTime && currentTime < playerZoom.end) {
        return;
      }

      setPlayerZoom(makeClampedZoom(currentTime));
    },
    [playerZoom, makeClampedZoom, trackCurrentTime, clipStartTime],
  );

  useEffect(() => {
    if (!player) {
      return;
    }

    player.addEventListener(
      "play",
      handlePlay as EventListenerOrEventListenerObject,
    );

    return () =>
      player.removeEventListener(
        "play",
        handlePlay as EventListenerOrEventListenerObject,
      );
  }, [player, handlePlay]);

  /**
   * Behavior to track the playhead while playing
   *
   * As the current time goes past the window,
   * shift the window such that the previous end is the new start
   */
  useEffect(() => {
    if (!isTrackingCurrentTime) {
      return;
    }

    if (currentTime >= playerZoom.end) {
      setPlayerZoom((prev) => ({
        start: prev.end,
        end: prev.end + (prev.end - prev.start),
      }));
    }
  }, [isTrackingCurrentTime, currentTime, playerZoom.end]);

  const value = {
    playerZoom,
    setPlayerZoom: (zoom: PlayerZoom) => {
      usingDefaultZoom.current = false;

      const clampedZoom = makeClampedZoom(zoom.start, zoom.end);

      setPlayerZoom(clampedZoom);

      const isCurrentTimeWithinZoom =
        clampedZoom.start <= currentTime && currentTime < clampedZoom.end;

      setIsTrackingCurrentTime(trackCurrentTime && isCurrentTimeWithinZoom);
    },
  };

  return (
    <PlayerZoomControllerContext.Provider value={value}>
      {children}
    </PlayerZoomControllerContext.Provider>
  );
};

export const useCurrentZoom = (): PlayerZoom => {
  const context = useContext(PlayerZoomControllerContext);

  if (!context) {
    throw new Error(
      "usePlayerZoom must be used within a PlayerZoomControllerContext",
    );
  }

  return context.playerZoom;
};

export const usePlayerZoomController = (): IPlayerZoomControllerContext => {
  const context = useContext(PlayerZoomControllerContext);

  if (!context) {
    throw new Error(
      "usePlayerZoomController must be used within a PlayerZoomControllerContext",
    );
  }

  return context;
};

export const ZOOM_EVENT_TYPE = "zoom-change";
