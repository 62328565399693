import React, { useCallback } from "react";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  cn,
  ClickableIcon,
  Separator,
} from "@kino/ui";
import { useLayoutStore } from "@/store/layoutStore";
import { PanelRightClose, PanelRightOpen } from "lucide-react";
import { OrganizationSwitcher } from "@/components/auth/OrganizationSwitcher";
import { useOrganization, UserButton, useUser } from "@clerk/clerk-react";
import AVBImportPopover from "@/components/popover/AVBImportPopover";
import SeasonStatsPopover from "@/components/popover/SeasonStatsPopover";
import { SquareCode } from "lucide-react";
import { Tab } from "@/components/shared/Tab";
import ActiveServerPopover from "@/components/popover/ActiveServerPopover";
import { useHotkeys } from "react-hotkeys-hook";

interface NavItemProps {
  icon: React.ReactNode;
  label?: string;
  value: string;
  onClick?: () => void;
  isActive?: boolean;
}

const NavItem = React.memo(
  ({ icon, label, onClick, isActive = false }: NavItemProps) => {
    return (
      <NavigationMenuItem
        onClick={onClick}
        className={cn(
          "group flex h-5 cursor-pointer items-center justify-start gap-1 text-xs transition-all",
          isActive ? "text-white" : "text-neutral-600 hover:text-white",
        )}
      >
        <div
          className={cn(
            "flex h-4 w-4 items-center",
            isActive
              ? "text-indigo-500"
              : "text-neutral-500 group-hover:text-indigo-500",
          )}
        >
          {icon}
        </div>
        {label && <div>{label}</div>}
      </NavigationMenuItem>
    );
  },
);

// Separate the debug button to prevent re-renders
const DebugButton = React.memo(() => {
  const isDebuggerPanelOpen = useLayoutStore(
    (state) => state.isDebuggerPanelOpen,
  );
  const set = useLayoutStore((state) => state.set);
  const { organization } = useOrganization();
  const { user } = useUser();

  const toggleDebuggerPanel = useCallback(() => {
    set("isDebuggerPanelOpen", !isDebuggerPanelOpen);
  }, [isDebuggerPanelOpen, set]);

  if (
    !(
      import.meta.env.DEV ||
      import.meta.env.VITE_VERCEL_ENV === "preview" ||
      organization?.slug === "kino-ai" ||
      user?.emailAddresses.some(
        (email) =>
          email.emailAddress.toLowerCase().endsWith("@trykino.com") ||
          email.emailAddress.toLowerCase().endsWith("@kino.ai"),
      )
    )
  ) {
    return null;
  }

  return (
    <ClickableIcon
      Icon={SquareCode}
      name="debug"
      tooltip="Debug"
      onClick={toggleDebuggerPanel}
      className={cn(
        "rounded-md p-1 text-neutral-500 hover:bg-neutral-800",
        isDebuggerPanelOpen ? "bg-neutral-800" : "",
      )}
    />
  );
});

// Separate the inspector button to prevent re-renders
const InspectorButton = React.memo(() => {
  const isInspectorOpen = useLayoutStore((state) => state.isInspectorOpen);
  const set = useLayoutStore((state) => state.set);

  const toggleInspector = useCallback(() => {
    set("isInspectorOpen", !isInspectorOpen);
  }, [isInspectorOpen, set]);

  useHotkeys("]", toggleInspector, [toggleInspector]);

  return (
    <NavigationMenuItem asChild>
      <Tab
        icon={isInspectorOpen ? <PanelRightClose /> : <PanelRightOpen />}
        value="inspector"
        isActive={isInspectorOpen}
        onClick={toggleInspector}
        tooltip={{
          content: <p>{isInspectorOpen ? "Close" : "Open"} inspector</p>,
          shortcutKeys: "mod+]",
        }}
      />
    </NavigationMenuItem>
  );
});

// Separate the left side of the navbar to prevent re-renders
const NavbarLeft = React.memo(() => (
  <NavigationMenuList className="flex items-center justify-start gap-1">
    <UserButton
      appearance={{
        elements: {
          avatarBox: "w-5 h-5",
        },
      }}
    />
    <Separator orientation="vertical" className="h-3 bg-neutral-700" />
    <OrganizationSwitcher isMinified={true} />
    <ActiveServerPopover />
  </NavigationMenuList>
));

// Separate the right side of the navbar to prevent re-renders
const NavbarRight = React.memo(() => (
  <div className="flex items-center justify-end gap-2">
    <div className="flex items-center gap-1">
      <AVBImportPopover />
      <SeasonStatsPopover />
      <DebugButton />
    </div>
    <Separator orientation="vertical" className="h-3 bg-neutral-700" />
    <InspectorButton />
  </div>
));

const Navbar = React.memo(() => {
  return (
    <nav className="flex w-full border-b-2 border-black px-2 py-1">
      <NavigationMenu className="flex w-full max-w-none items-center justify-between">
        <NavbarLeft />
        <NavbarRight />
      </NavigationMenu>
    </nav>
  );
});

export { NavItem };
export default Navbar;
