import { Outlet } from "react-router";
import { PeopleSidebar } from "@/components/people/PeopleSidebar";
import { Separator } from "@kino/ui";

export default function People() {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex min-h-0 flex-1">
        <PeopleSidebar />
        <Separator orientation="vertical" />
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
