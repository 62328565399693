import React, { useState, useMemo, useCallback } from "react";
import { InspectorCollapsiblePanelSection } from "@/components/inspector/collapsibleSection/InspectorCollapsiblePanelSection";
import { CommandList, Command, CommandInput } from "@kino/ui";
import { useFilterOptionsStore } from "@/store/filter/filterOptionsStore";
import { ArrayFilterKeys } from "@/types/filters";
import ArrayFilterSection from "./ArrayFilterSection";
import { useFilterState } from "@/hooks/useFilterState";

interface ArrayFilterCollapsibleProps {
  filterKey: ArrayFilterKeys;
}

// Memoize the command input to prevent re-renders
export const MemoizedCommandInput = React.memo(
  ({
    value,
    onValueChange,
    placeholder = "Find a person",
  }: {
    value: string;
    onValueChange: (value: string) => void;
    placeholder?: string;
  }) => (
    <CommandInput
      placeholder={placeholder}
      searchLoading={false}
      className="h-7 rounded-md border border-neutral-800 p-2"
      value={value}
      onValueChange={onValueChange}
    />
  ),
);

// Memoize the filter section to prevent re-renders
const MemoizedArrayFilterSection = React.memo(
  ({
    filterKey,
    filter,
    search,
  }: {
    filterKey: ArrayFilterKeys;
    filter: any;
    search: string;
  }) => (
    <CommandList className="flex h-full max-h-none flex-col">
      <ArrayFilterSection
        filter={filter}
        search={search}
        filterKey={filterKey}
      />
    </CommandList>
  ),
);

// Memoize the command wrapper to prevent re-renders
const CommandWrapper = React.memo(
  ({ children }: { children: React.ReactNode }) => (
    <div className="flex w-full flex-col px-2">
      <Command className="flex flex-col">{children}</Command>
    </div>
  ),
);

const useArrayFilterState = (filterKey: ArrayFilterKeys) => {
  const { filterOptions } = useFilterOptionsStore();
  const { searchState } = useFilterState();
  const filterState = searchState.filterState;

  const filterOption = useMemo(
    () => filterOptions[filterKey],
    [filterOptions, filterKey],
  );
  const numBadge = useMemo(
    () => filterState[filterKey]?.length || 0,
    [filterState, filterKey],
  );

  return { filterOption, numBadge };
};

const ArrayFilterCollapsible = React.memo<ArrayFilterCollapsibleProps>(
  ({ filterKey }) => {
    const [kw, setKw] = useState("");
    const { filterOption, numBadge } = useArrayFilterState(filterKey);

    const handleValueChange = useCallback((value: string) => {
      setKw(value);
    }, []);

    return (
      <InspectorCollapsiblePanelSection
        label={filterOption.label}
        name={filterKey}
        icon={filterOption.icon}
        numBadge={numBadge}
        defaultOpen
      >
        <CommandWrapper>
          <MemoizedCommandInput value={kw} onValueChange={handleValueChange} />
          <MemoizedArrayFilterSection
            filterKey={filterKey}
            filter={filterOption}
            search={kw}
          />
        </CommandWrapper>
      </InspectorCollapsiblePanelSection>
    );
  },
);

ArrayFilterCollapsible.displayName = "ArrayFilterCollapsible";

export default ArrayFilterCollapsible;
