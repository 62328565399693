import { useClient, queryClient } from "@/hooks/useClient";
import { useSyncOrganization } from "@/store/organizationStore";
import { components } from "@/openapi-bindings/v2";
import { useCallback } from "react";
import { UnassignedImage } from "@/types/people";

type Person = components["schemas"]["Person"];

type MutateOptions = {
  onSuccess?: () => void;
  onSettled?: () => void;
  onError?: () => void;
};

export const usePeople = () => {
  const { apiClient } = useClient();
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/people",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
        },
      },
    },
    {
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
    },
    queryClient,
  );
};

export const useFilteredPeople = (filter: string) => {
  const { apiClient } = useClient();
  const store = useSyncOrganization();

  const selectFilteredPeople = useCallback(
    (people: Person[]) => {
      const lowercaseFilter = filter.toLowerCase();

      return (
        filter
          ? people.filter((person) =>
              person.name.toLowerCase().includes(lowercaseFilter),
            )
          : people
      ).sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" }),
      );
    },
    [filter],
  );

  return apiClient.useQuery(
    "get",
    "/people",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
        },
      },
    },
    {
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
      select: selectFilteredPeople,
    },
    queryClient,
  );
};

export const usePerson = (personId?: string) => {
  const { apiClient } = useClient();
  const store = useSyncOrganization();

  const selectPerson = useCallback(
    (people: Person[]) => people.find((person) => person.id === personId),
    [personId],
  );

  return apiClient.useQuery(
    "get",
    "/people",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
        },
      },
    },
    {
      enabled: !!personId,
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
      select: selectPerson,
    },
    queryClient,
  );
};

export const useCreatePerson = () => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const organizationId = orgStore.getEffectiveOrganizationId();

  const deletePeople = apiClient.useMutation(
    "post",
    "/person",
    {},
    queryClient,
  );

  return useCallback(
    (person: Person, options?: MutateOptions) =>
      deletePeople.mutate(
        {
          body: {
            organization_id: organizationId,
            ...person,
          },
        },
        options,
      ),
    [organizationId],
  );
};

export const useDeletePerson = () => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const organizationId = orgStore.getEffectiveOrganizationId();

  const deletePeople = apiClient.useMutation(
    "delete",
    "/people",
    {},
    queryClient,
  );

  return useCallback(
    ({ personId }: { personId: string }, options?: MutateOptions) =>
      deletePeople.mutate(
        {
          body: {
            organization_id: organizationId,
            person_ids: [personId],
          },
        },
        options,
      ),
    [organizationId],
  );
};

export const useUploadReferenceFace = () => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const organizationId = orgStore.getEffectiveOrganizationId();

  const uploadFaceMutation = apiClient.useMutation(
    "post",
    "/person/{person_id}/reference-face",
    {
      onError: (error) => {
        console.error("Failed to upload reference face:", error);

        throw error;
      },
    },
    queryClient,
  );

  return useCallback(
    async (
      {
        personId,
        file,
        setAsAvatar,
      }: { personId: string; file: File; setAsAvatar?: boolean },
      options: MutateOptions,
    ) => {
      const formData = new FormData();
      formData.append("files", file);

      return uploadFaceMutation.mutate(
        {
          params: {
            path: {
              person_id: personId,
            },
            query: {
              organization_id: orgStore.getEffectiveOrganizationId(),
              set_as_avatar: setAsAvatar,
            },
          },
          body: formData as unknown as { files: string[] },
          headers: {
            "Content-Type": undefined,
          },
        },
        options,
      );
    },
    [organizationId],
  );
};

export function useUploadReferenceFaces() {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const organizationId = orgStore.getEffectiveOrganizationId();

  const uploadFaceMutation = apiClient.useMutation(
    "post",
    "/person/{person_id}/reference-face",
    {
      onError: (error) => {
        console.error("Failed to upload reference face:", error);

        throw error;
      },
    },
    queryClient,
  );

  return useCallback(
    async (
      { personId, images }: { personId: string; images: UnassignedImage[] },
      options?: MutateOptions,
    ) => {
      images.forEach((image) => {
        const formData = new FormData();
        formData.append("files", image.file);

        return uploadFaceMutation.mutate(
          {
            params: {
              path: {
                person_id: personId,
              },
              query: {
                organization_id: orgStore.getEffectiveOrganizationId(),
                set_as_avatar: false,
              },
            },
            body: formData as unknown as { files: string[] },
            headers: {
              "Content-Type": undefined,
            },
          },
          options,
        );
      });
    },
    [organizationId],
  );
}

export const useDeleteReferenceFaces = () => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const organizationId = orgStore.getEffectiveOrganizationId();

  const deleteFaces = apiClient.useMutation(
    "delete",
    "/faces",
    {},
    queryClient,
  );

  return useCallback(
    ({ faces }: { faces: string[] }, options?: MutateOptions) =>
      deleteFaces.mutate(
        {
          body: {
            organization_id: organizationId,
            face_ids: faces,
          },
        },
        options,
      ),
    [organizationId],
  );
};

export const useSetAvatar = () => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const organizationId = orgStore.getEffectiveOrganizationId();

  const setAvatarMutation = apiClient.useMutation(
    "put",
    "/person/{person_id}/set-avatar",
    {},
    queryClient,
  );

  return useCallback(
    (
      { personId, imagePath }: { personId: string; imagePath: string },
      options?: MutateOptions,
    ) =>
      setAvatarMutation.mutate(
        {
          params: {
            path: { person_id: personId },
            query: {
              reference_face_path: imagePath,
              organization_id: organizationId,
            },
          },
        },
        options,
      ),
    [organizationId],
  );
};
