import { useClient } from "@/hooks/useClient";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyAllMediaFilesResponse from "@/api/dummyData/v2/dummyAllMediaFilesResponse.json";
import { components } from "@/openapi-bindings/v2";
import { queryClient } from "@/hooks/useClient";
import { useRouteParams } from "./useURLParams";
import { useSyncOrganization } from "@/store/organizationStore";
import { useCallback } from "react";

const parseShot = (shot: string) => {
  try {
    const [sceneStr, shotLetter] = shot.split("/");
    return {
      scene: parseInt(sceneStr ?? "0"),
      shot: shotLetter,
    };
  } catch {
    return null;
  }
};

const getMetadata = (item: components["schemas"]["MediaItem"]) => {
  try {
    const rawMetadata = item.metadata?.raw_metadata;
    if (!rawMetadata) return null;

    return typeof rawMetadata === "string"
      ? JSON.parse(rawMetadata)
      : rawMetadata;
  } catch {
    return null;
  }
};

const getSlateData = (item: components["schemas"]["MediaItem"]) => {
  const metadata = getMetadata(item);
  if (!metadata?.slate_data?.shot) return null;
  return parseShot(metadata.slate_data.shot);
};

const hasMulticamGroup = (item: components["schemas"]["MediaItem"]) => {
  const metadata = getMetadata(item);
  return !!metadata?.multicam_group;
};

const compareMedia = (
  a: components["schemas"]["MediaItem"],
  b: components["schemas"]["MediaItem"],
) => {
  // First check for multicam groups - these should always be at the top
  const hasMulticamA = hasMulticamGroup(a);
  const hasMulticamB = hasMulticamGroup(b);

  if (hasMulticamA && !hasMulticamB) return -1;
  if (!hasMulticamA && hasMulticamB) return 1;

  // If both have multicam or both don't, then check slate data
  const slateA = getSlateData(a);
  const slateB = getSlateData(b);

  // If both items have slate data, compare them
  if (slateA && slateB) {
    if (slateA.scene !== slateB.scene) {
      return slateA.scene - slateB.scene;
    }
    if (slateA.shot !== slateB.shot) {
      return (slateA.shot || "").localeCompare(slateB.shot || "");
    }
  }

  // If only one has slate data, prioritize it
  if (slateA) return -1;
  if (slateB) return 1;

  // Fallback to filename sort
  return (a.ffprobe_data.format.filename ?? "").localeCompare(
    b.ffprobe_data.format.filename ?? "",
  );
};

interface AllMediaParams {
  offset?: number;
  limit?: number;
}

const DEFAULT_ALL_MEDIA_PARAMS = {
  limit: 150,
};

export function useAllMedia({ offset = 0, limit = 500 }: AllMediaParams = {}) {
  const isMockMediaEndpoint = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const { apiClient } = useClient();
  const routeParams = useRouteParams();
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/media/all",
    {
      params: {
        query: {
          ...DEFAULT_ALL_MEDIA_PARAMS,
          organization_id: store.getEffectiveOrganizationId(),
          person_ids: routeParams.params.person_ids,
          tag_ids: routeParams.params.tag_ids,
        },
      },
    },
    {
      select: (data: components["schemas"]["AllMediaResponse"]) => ({
        ...data,
        media_items: [...data.media_items].sort(compareMedia),
      }),
      enabled: !isMockMediaEndpoint,
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
      placeholderData: isMockMediaEndpoint
        ? (dummyAllMediaFilesResponse as unknown as components["schemas"]["AllMediaResponse"])
        : undefined,
    },
    queryClient,
  );
}

export function useAGGridMedia({
  startRow = 0,
  endRow = 100,
  sortModel,
  filterModel,
}: {
  startRow?: number;
  endRow?: number;
  sortModel?: any;
  filterModel?: any;
}) {
  const isMockMediaEndpoint = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const { apiClient } = useClient();
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/media/ag-grid-media",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
          start_row: startRow,
          end_row: endRow,
          sort_model: sortModel ? JSON.stringify(sortModel) : undefined,
          filter_model: filterModel ? JSON.stringify(filterModel) : undefined,
        },
      },
    },
    {
      // No need for sorting here since AG Grid handles it
      enabled: !isMockMediaEndpoint,
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
    },
    queryClient,
  );
}

interface MediaResponseMap {
  [id: string]: components["schemas"]["MediaItem"];
}

const useMediaItemInitialDataCallback = (mediaItemId: string | undefined) => {
  const { apiClient } = useClient();
  const { getEffectiveOrganizationId } = useSyncOrganization();
  const organization_id = getEffectiveOrganizationId();

  const routeParams = useRouteParams();
  const { person_ids, tag_ids } = routeParams.params;

  const { queryKey: allMediaQueryKey } = apiClient.queryOptions(
    "get",
    "/media/all",
    {
      params: {
        query: {
          ...DEFAULT_ALL_MEDIA_PARAMS,
          organization_id,
          person_ids,
          tag_ids,
        },
      },
    },
  );

  const getInitialData = useCallback(() => {
    if (!mediaItemId) {
      return;
    }

    const allMediaCacheData:
      | components["schemas"]["AllMediaResponse"]
      | undefined = queryClient.getQueryData(allMediaQueryKey);

    if (!allMediaCacheData) {
      return undefined;
    }

    const itemInCache = allMediaCacheData.media_items.find(
      ({ id }) => id === mediaItemId,
    );

    if (!itemInCache) {
      return undefined;
    }

    return { [mediaItemId]: itemInCache };
  }, [mediaItemId, organization_id, person_ids, tag_ids, allMediaQueryKey]);

  const getInitialDataDateUpdated = useCallback(
    () => queryClient.getQueryState(allMediaQueryKey)?.dataUpdatedAt,
    [allMediaQueryKey],
  );

  return {
    getInitialData,
    getInitialDataDateUpdated,
  };
};

export function useMediaItemQuery(mediaItemId: string | undefined) {
  const { apiClient } = useClient();
  const { getEffectiveOrganizationId } = useSyncOrganization();
  const routeParams = useRouteParams();
  const organization_id = getEffectiveOrganizationId();
  const { person_ids, tag_ids } = routeParams.params;
  const { getInitialData, getInitialDataDateUpdated } =
    useMediaItemInitialDataCallback(mediaItemId);

  const select = useCallback(
    (data: MediaResponseMap) => (mediaItemId ? data[mediaItemId] : undefined),
    [mediaItemId],
  );

  return apiClient.useQuery(
    "get",
    "/media",
    {
      params: {
        query: {
          media_ids: [mediaItemId || ""],
          organization_id,
          person_ids,
          tag_ids,
        },
      },
    },
    {
      enabled: !!mediaItemId,
      staleTime: 5 * 60 * 1000,
      initialData: getInitialData,
      initialDataUpdatedAt: getInitialDataDateUpdated,
      select,
    },
  );
}

export function useMediaItems(mediaItemIds: string[]) {
  const isMockMediaEndpoint = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const { apiClient } = useClient();
  const routeParams = useRouteParams();
  const store = useSyncOrganization();

  const queryParams = {
    media_ids: mediaItemIds,
    organization_id: store.getEffectiveOrganizationId(),
    person_ids: routeParams.params.person_ids,
    tag_ids: routeParams.params.tag_ids,
  };

  return apiClient.useQuery(
    "get",
    "/media",
    {
      params: {
        query: queryParams,
      },
    },
    {
      enabled: !!mediaItemIds.length,
      staleTime: 5 * 60 * 1000,
      initialData: () => {
        const individualMediaItems: (
          | { [id: string]: components["schemas"]["MediaItem"] }
          | undefined
        )[] = mediaItemIds.map((id) =>
          queryClient.getQueryData(
            apiClient.queryOptions("get", "/media", {
              params: { query: { ...queryParams, media_ids: [id] } },
            }).queryKey,
          ),
        );

        if (!individualMediaItems.every(Boolean)) {
          return undefined;
        }

        return individualMediaItems.reduce(
          (acc, item) => ({ ...acc, ...item }),
          {},
        );
      },
      placeholderData: isMockMediaEndpoint
        ? (dummyAllMediaFilesResponse.media_items as unknown as {
            [key: string]: components["schemas"]["MediaItem"];
          })
        : undefined,
    },
  );
}
