import * as Slider from "@radix-ui/react-slider";
import {
  useMediaRemote,
  useMediaState,
  useMediaStore,
  useSliderPreview,
  useMediaProvider,
} from "@vidstack/react";
import {
  useEffect,
  useState,
  useMemo,
  useRef,
  CSSProperties,
  useDeferredValue,
  Fragment,
  useCallback,
} from "react";
import { Thumbnails } from "./thumbnails";

import { cn } from "../../utils/cn";
import { useClipMoment } from "../../context/ClipMomentsContext";

import WindowHandleLeftIcon from "../icons/WindowHandleLeft";
import WindowHandleRightIcon from "../icons/WindowHandleRight";
import GrabberIcon from "../icons/Grabber";
import {
  useCurrentZoom,
  usePlayerZoomController,
} from "../../context/ZoomControllerContext";
import {
  PlayerMoment,
  usePlayerMoments,
} from "../../context/PlayerMomentsContext";
import { useZoomGestures } from "../../hooks/useZoomGestures";
import { useSize } from "../../hooks/useSize";
import { useFramesPerSecond } from "../../context/FramerateContext";
import { WaitForPlayerReady } from "./skeleton";
import { MuteIcon, VolumeHighIcon } from "@vidstack/react/icons";
import { ContextMenu, ContextMenuContent, ContextMenuTrigger } from "@kino/ui";
import {
  useFormattedTime,
  useFormatTime,
} from "../../context/FormatTimeContext";
import { usePreviewTime } from "../../context/PreviewTimeContext";

export function Volume() {
  const volume = useMediaState("volume");
  const canSetVolume = useMediaState("canSetVolume");
  const remote = useMediaRemote();

  if (!canSetVolume) {
    return null;
  }

  return (
    <Slider.Root
      className="group relative inline-flex h-5 w-full max-w-[80px] cursor-pointer touch-none select-none items-center outline-none"
      value={[volume * 100]}
      onValueChange={([value = 0]) => {
        remote.changeVolume(value / 100);
      }}
    >
      <Slider.Track className="relative h-[5px] w-full rounded-sm bg-slate-100">
        <Slider.Range className="absolute h-full rounded-sm bg-slate-700/60 will-change-[width]" />
      </Slider.Track>
      <Slider.Thumb
        aria-label="Volume"
        className="block h-2 w-2 rounded-full border border-zinc-500 bg-zinc-600 opacity-0 shadow outline-none ring-slate-400/60 transition-opacity will-change-[left] focus:opacity-100 group-focus:opacity-100"
      />
    </Slider.Root>
  );
}

export function Time({ thumbnails = "" }) {
  const time = useMediaState("currentTime");
  const duration = useMediaState("duration");
  const canSeek = useMediaState("canSeek");
  const isSeeking = useMediaState("seeking");

  const remote = useMediaRemote();

  const step = (1 / duration) * 100;

  const [currentValue, setCurrentValue] = useState(0);

  const { previewRootRef, previewRef, previewValue } = useSliderPreview({
    clamp: true,
    offset: 4,
    orientation: "horizontal",
  });
  const previewTime = (previewValue / 100) * duration;
  const previewTimeString = useFormattedTime(previewTime);

  useEffect(() => {
    if (isSeeking) {
      return;
    }

    setCurrentValue((time / duration) * 100);
  }, [isSeeking, time, duration]);

  return (
    <Slider.Root
      className="group relative inline-flex h-9 w-full cursor-pointer touch-none select-none items-center outline-none"
      value={[currentValue]}
      disabled={!canSeek}
      step={Number.isFinite(step) ? step : 1}
      ref={previewRootRef}
      onValueChange={([value = 0]) => {
        remote.seek((value / 100) * duration);
      }}
      onValueCommit={([value = 0]) => {
        remote.seek((value / 100) * duration);
      }}
    >
      <Slider.Track className="relative h-[5px] w-full rounded-sm bg-neutral-800">
        <Slider.Range className="absolute h-full rounded-sm bg-neutral-800/60 will-change-[width]" />
      </Slider.Track>
      <Slider.Thumb
        aria-label="Current Time"
        className="bg-accent focus-visible:ring-ring block h-4 w-4 rounded-full border border-neutral-700 shadow transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50"
      />
      <div
        className="pointer-events-none absolute flex flex-col items-center opacity-0 transition-opacity duration-200 will-change-[left] data-[visible]:opacity-100"
        ref={previewRef}
      >
        {thumbnails && <Thumbnails src={thumbnails} time={previewTime} />}
        <span className="text-[13px]">{previewTimeString}</span>
      </div>
    </Slider.Root>
  );
}

const TIME_MARKER_INTERVALS = [
  { minor: 0.25, major: 1 },
  { minor: 0.5, major: 2 },
  { minor: 1, major: 10 },
  { minor: 5, major: 30 },
  { minor: 10, major: 60 },
  { minor: 10, major: 5 * 60 },
  { minor: 60, major: 5 * 60 },
  { minor: 60, major: 15 * 60 },
];
const MAJOR_INTERVAL_MIN_WIDTH = 40;
const MINOR_INTERVAL_MIN_DISTANCE = 3;
export const DEFAULT_SECONDS_ADDED = 1;

export function TimeWithMoments({
  className,
  children,
  clipContextMenuItems,
}: {
  className?: string;
  children?: React.ReactNode;
  clipContextMenuItems?: (clip: PlayerMoment) => React.ReactNode;
}) {
  const {
    clipStartTime = 0,
    clipEndTime = Infinity,
    realCurrentTime,
  } = useMediaStore();

  const isPlaying = useMediaState("playing");

  const playerZoom = useCurrentZoom();
  const { start: zoomStartTime, end: zoomEndTime } = playerZoom;
  const zoomDuration = zoomEndTime - zoomStartTime;

  const remote = useMediaRemote();
  const provider = useMediaProvider();

  const framesPerSecond = useFramesPerSecond();
  const frameDuration = 1 / framesPerSecond;
  const roundTimeToNearestFrame = useCallback(
    (time: number) => Math.round(time / frameDuration) * frameDuration,
    [frameDuration],
  );

  const formatTime = useFormatTime();
  const $sliderContainerRef = useRef<HTMLDivElement>(null);
  const $sliderTrackRef = useRef<HTMLSpanElement>(null);
  const $firstTimeLabel = useRef<HTMLSpanElement>(null);

  const { previewValue, previewRootRef } = usePreviewTime();
  const previewTime = roundTimeToNearestFrame(
    zoomStartTime + (previewValue / 100) * zoomDuration,
  );
  const [dragging, setDragging] = useState(false);
  const wasPlayingRef = useRef<boolean | null>(null);

  const sliderSize = useSize($sliderContainerRef);
  const timeLabelSize = useSize($firstTimeLabel);

  const { majorTicks, minorTicks } = useMemo(() => {
    if (!Number.isFinite(zoomEndTime) || !sliderSize?.width) {
      return { majorTicks: null, minorTicks: null };
    }

    const timeMarkerIntervals = [
      { minor: frameDuration, major: 1 },
      ...TIME_MARKER_INTERVALS,
    ];

    const timeMarkerInterval = timeMarkerIntervals.find(
      (t) =>
        (t.major / (zoomEndTime - zoomStartTime)) * sliderSize.width >
          (timeLabelSize?.width
            ? timeLabelSize.width + 5 // 5 pixel padding between timecodes
            : MAJOR_INTERVAL_MIN_WIDTH) &&
        (t.minor / (zoomEndTime - zoomStartTime)) * sliderSize.width >
          MINOR_INTERVAL_MIN_DISTANCE,
    ) || { minor: frameDuration || 0.1, major: 1 };

    const minorTicks = [];

    for (
      let t = Math.max(
        zoomStartTime - (zoomStartTime % timeMarkerInterval.minor),
        0,
      );
      t <= zoomEndTime;
      t += timeMarkerInterval.minor
    ) {
      const normalizedT = Math.round((t + Number.EPSILON) * 100) / 100;

      if (normalizedT === zoomStartTime || normalizedT === zoomEndTime) {
        continue;
      }

      minorTicks.push(
        <span
          key={`timeMarker-${t}`}
          className={cn("absolute bottom-0 h-2 w-px bg-neutral-700")}
          style={{ left: `${((t - zoomStartTime) / zoomDuration) * 100}%` }}
        />,
      );
    }

    const majorTicks = [];
    for (
      let t = Math.max(
        zoomStartTime - (zoomStartTime % timeMarkerInterval.major),
        0,
      );
      t <= zoomEndTime;
      t += timeMarkerInterval.major
    ) {
      const normalizedT = Math.round((t + Number.EPSILON) * 100) / 100;

      if (normalizedT === zoomEndTime) {
        continue;
      }

      majorTicks.push(
        <Fragment key={`timeMarker-${t}`}>
          <span
            className="absolute top-0 text-[10px] text-neutral-500"
            style={{ left: `${((t - zoomStartTime) / zoomDuration) * 100}%` }}
          >
            {formatTime(normalizedT)}
          </span>
          <span
            key={`timeMarker-${t}`}
            className={cn("absolute bottom-0 h-4 w-px bg-neutral-700")}
            style={{ left: `${((t - zoomStartTime) / zoomDuration) * 100}%` }}
          />
        </Fragment>,
      );
    }

    return { minorTicks, majorTicks };
  }, [
    zoomStartTime,
    zoomEndTime,
    zoomDuration,
    frameDuration,
    sliderSize?.width,
    timeLabelSize?.width,
    formatTime,
  ]);

  const { clipMoment } = useClipMoment();
  useZoomGestures($sliderContainerRef);

  const frameLevelTime = roundTimeToNearestFrame(realCurrentTime);
  const draggingSliderValue = dragging ? previewTime : frameLevelTime;

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div className="relative bg-neutral-900" ref={$sliderContainerRef}>
          <div className="relative">
            <div className="h-4 w-full">
              <span
                className="invisible absolute top-0 text-[10px] text-neutral-500"
                style={{ left: 0 }}
                ref={$firstTimeLabel}
              >
                {formatTime(0)}
              </span>
              {majorTicks}
            </div>
            <div className="h-4 w-full">{minorTicks}</div>
          </div>
          <Slider.Root
            ref={previewRootRef}
            className={cn(
              "group/slider group absolute bottom-0 flex h-full w-full cursor-pointer flex-col items-center justify-end",
              dragging && "cursor-grabbing",
              className,
            )}
            min={playerZoom.start}
            max={playerZoom.end}
            value={[draggingSliderValue]}
            step={frameDuration}
            onValueChange={([newTime = 0]) => {
              const clipClampedTime = roundTimeToNearestFrame(
                Math.min(
                  Math.max(newTime, clipStartTime),
                  clipEndTime || Infinity,
                ) - clipStartTime,
              );

              remote.seeking(clipClampedTime);
              remote.seek(clipClampedTime);
            }}
            onPointerDown={() => {
              const wasPlaying = isPlaying;
              wasPlayingRef.current = wasPlaying;

              setDragging(true);

              if (wasPlaying) {
                remote.pause();
              }
            }}
            onPointerUp={() => {
              const shouldResume = wasPlayingRef.current;

              const clipClampedTime = roundTimeToNearestFrame(
                Math.min(
                  Math.max(previewTime, clipStartTime),
                  clipEndTime || Infinity,
                ) - clipStartTime,
              );

              remote.seek(clipClampedTime);

              if (shouldResume) {
                provider?.play();
              }

              wasPlayingRef.current = null;
              setDragging(false);
            }}
            style={
              {
                "--pointer-position": `${previewValue}%`,
              } as CSSProperties
            }
          >
            <Slider.Track
              ref={$sliderTrackRef}
              className="relative z-0 h-full w-full flex-row group-data-[focus]:ring-[3px]"
            >
              <div className="absolute bottom-0 left-0 right-0 h-1/2">
                <Clip />
              </div>
              <WaitForPlayerReady>
                <Slider.Thumb asChild>
                  <span
                    style={{
                      left: `${((frameLevelTime - zoomStartTime) / zoomDuration) * 100}%`,
                    }}
                    className={cn(
                      "absolute box-content h-8 w-px -translate-x-1/2 cursor-grab rounded-full bg-white will-change-[left] focus-visible:outline-none",
                      (realCurrentTime < zoomStartTime ||
                        realCurrentTime > zoomEndTime) &&
                        "hidden",
                      dragging && "hidden",
                    )}
                  />
                </Slider.Thumb>
              </WaitForPlayerReady>
            </Slider.Track>
            <span
              className={cn(
                "pointer-events-none absolute bottom-0 left-[var(--pointer-position)] box-content h-8 w-px -translate-x-1/2 rounded-full bg-red-600 opacity-0 will-change-[left] group-hover/slider:opacity-100",
              )}
            />
          </Slider.Root>
          {children}
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent>
        {clipContextMenuItems &&
          clipContextMenuItems({
            id: `moment_${clipStartTime}_${clipEndTime}`,
            ...clipMoment,
          })}
      </ContextMenuContent>
    </ContextMenu>
  );
}

interface MulticamTimelineRowProps {
  offset: number;
  duration: number;
  isActive?: boolean;
  className?: string;
}

export function MulticamTimelineRow({
  offset,
  duration,
  isActive,
  className = "",
}: MulticamTimelineRowProps) {
  const $sliderRef = useRef<HTMLDivElement>(null);
  const sliderSize = useSize($sliderRef);

  const { intrinsicDuration } = useMediaStore();
  const { playerZoom } = usePlayerZoomController();
  const { start: zoomStartTime, end: zoomEndTime } = playerZoom;
  const zoomDuration = zoomEndTime - zoomStartTime;

  const actualStart = offset;
  const actualEnd = offset + duration;

  const isVisible = !(actualEnd < zoomStartTime || actualStart > zoomEndTime);

  return (
    <div className={cn("relative h-5 w-full bg-neutral-900", className)}>
      <WaitForPlayerReady>
        {isVisible && (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={cn(
              "group/marker absolute h-full cursor-pointer bg-neutral-800/40 transition-colors hover:bg-neutral-600",
              isActive && "bg-neutral-800",
            )}
            style={{
              left: `calc(${((actualStart - zoomStartTime) / zoomDuration) * 100}% - ${
                sliderSize
                  ? linearScale(
                      [
                        1 /
                          (40 / (sliderSize.width * (actualEnd - actualStart))),
                        intrinsicDuration,
                      ],
                      [0, 10],
                    )(zoomEndTime - zoomStartTime)
                  : 0
              }px)`,
              right: `calc(${((zoomEndTime - actualEnd) / zoomDuration) * 100}% - ${
                sliderSize
                  ? linearScale(
                      [
                        1 /
                          (40 / (sliderSize.width * (actualEnd - actualStart))),
                        intrinsicDuration,
                      ],
                      [0, 10],
                    )(zoomEndTime - zoomStartTime)
                  : 0
              }px)`,
            }}
          />
        )}
      </WaitForPlayerReady>
    </div>
  );
}

interface Camera {
  offset: number;
  duration: number;
  media_id: string;
}

export interface MulticamGroup {
  scene_number: string;
  prerendered_hls_manifest: string;
  cameras: {
    [filename: string]: Camera;
  };
}

interface MulticamTimelinesProps {
  activeMediaId?: string;
  multicamGroup?: MulticamGroup;
  className?: string;
}

export function MulticamTimelines({
  activeMediaId,
  multicamGroup,
  className = "",
}: MulticamTimelinesProps) {
  const $sliderRef = useRef<HTMLDivElement>(null);
  const { playerZoom } = usePlayerZoomController();
  const { start: zoomStartTime, end: zoomEndTime } = playerZoom;
  const zoomDuration = zoomEndTime - zoomStartTime;
  const { realCurrentTime } = useMediaStore();
  const deferredCurrentTime = useDeferredValue(realCurrentTime);
  useZoomGestures($sliderRef);

  if (!multicamGroup) {
    return null;
  }

  return (
    <div className={cn("flex w-full flex-col", className)} ref={$sliderRef}>
      {Object.entries(multicamGroup.cameras).map(([key, camera]) => (
        <MulticamTimelineRow
          key={key}
          {...camera}
          className="border-b"
          isActive={activeMediaId === camera.media_id}
        />
      ))}
      <span
        className="absolute top-0 h-full w-px bg-zinc-400/60"
        style={{
          left:
            ((deferredCurrentTime - zoomStartTime) / zoomDuration) * 100 + "%",
          zIndex: 10,
        }}
      />
    </div>
  );
}

interface MulticamTimelineSidebarProps {
  multicamGroup?: MulticamGroup;
  className?: string;
}

export function MulticamTimelineSidebar({
  multicamGroup,
  className = "",
}: MulticamTimelineSidebarProps) {
  const remote = useMediaRemote();

  const { audioTracks, audioTrack } = useMediaStore();

  if (!multicamGroup) {
    return null;
  }

  return (
    <div
      className={cn(
        "z-50 flex h-full flex-col border-r bg-neutral-900",
        className,
      )}
    >
      <div className="h-8" />
      {Object.entries(multicamGroup.cameras).map(([key], i) => (
        <div
          key={key}
          className={cn(
            "flex h-5 w-full items-center justify-center gap-1 border-t px-2 text-xs text-neutral-400",
          )}
        >
          <div>{i + 1}</div>
          <div className="w-12 overflow-hidden">
            <div className="truncate text-ellipsis whitespace-nowrap">
              {key}
            </div>
          </div>
          <div
            onClick={() => {
              const trackIndex = audioTracks.findIndex(
                (track) => track.id === key,
              );
              remote.changeAudioTrack(trackIndex);
            }}
            className="cursor-pointer"
          >
            {audioTrack?.id === key ? (
              <VolumeHighIcon className="h-4 w-4 text-neutral-300 hover:text-neutral-200" />
            ) : (
              <MuteIcon className="h-4 w-4 text-neutral-600 hover:text-neutral-500" />
            )}
          </div>
        </div>
      ))}
      <div className="border-t" />
    </div>
  );
}

export interface Comment {
  id: string;
  rootCommentId: string;
  text: string;
  created_at: Date;
  updated_at: Date;
  clerkUserId: string;
  startTime: number;
  endTime?: number;
  replies?: Comment[];
}

export interface HydratedComment extends Comment {
  clerkFirstname: string;
  clerkLastname: string;
  clerkImageUrl: string;
}

interface CommentTimelineProps {
  comments: HydratedComment[];
  className?: string;
}

export function CommentTimeline({
  comments,
  className = "",
}: CommentTimelineProps) {
  const $sliderRef = useRef<HTMLDivElement>(null);
  const sliderSize = useSize($sliderRef);

  const { intrinsicDuration, realCurrentTime } = useMediaStore();
  const deferredCurrentTime = useDeferredValue(realCurrentTime);
  const playerZoom = useCurrentZoom();
  const { start: zoomStartTime, end: zoomEndTime } = playerZoom;
  const zoomDuration = zoomEndTime - zoomStartTime;

  useZoomGestures($sliderRef);

  const { visibleComments } = useMemo(() => {
    const preWindowComments = [];
    const visibleComments = [];
    const postWindowComments = [];

    for (const comment of comments) {
      if ((comment.endTime || comment.startTime) < zoomStartTime) {
        preWindowComments.push(comment);
      } else if (comment.startTime > zoomEndTime) {
        postWindowComments.push(comment);
      } else {
        visibleComments.push(comment);
      }
    }

    return { preWindowComments, visibleComments, postWindowComments };
  }, [comments, zoomStartTime, zoomEndTime]);

  return (
    <div
      className={cn("relative h-5 w-full bg-neutral-900", className)}
      ref={$sliderRef}
    >
      <WaitForPlayerReady>
        {visibleComments.map((comment) => {
          const commentEnd = comment.endTime || comment.startTime + 0.1;

          const targetPlayerZoom = sliderSize
            ? 1 / (40 / (sliderSize.width * (commentEnd - comment.startTime)))
            : 1;

          const commentBarAdjust = linearScale(
            [targetPlayerZoom, intrinsicDuration],
            [0, 10],
          );

          const commentSizeAdjustment = commentBarAdjust(
            zoomEndTime - zoomStartTime,
          );

          return (
            <span
              key={comment.id}
              className="group/marker absolute flex items-center overflow-visible"
              style={{
                left: `calc(${((comment.startTime - zoomStartTime) / zoomDuration) * 100}% - ${commentSizeAdjustment}px)`,
                right: comment.endTime
                  ? `calc(${((zoomEndTime - commentEnd) / zoomDuration) * 100}% - ${commentSizeAdjustment}px)`
                  : "auto",
              }}
            >
              <img
                src={comment.clerkImageUrl}
                alt={comment.clerkFirstname}
                className="z-10 h-4 w-4 rounded-full"
              />
              {comment.endTime && (
                <div className="h-0.5 w-full bg-red-500/40 hover:bg-red-400/60" />
              )}
            </span>
          );
        })}

        <span
          className="absolute top-0 h-full w-px bg-zinc-400/60"
          style={{
            left:
              ((deferredCurrentTime - zoomStartTime) / zoomDuration) * 100 +
              "%",
            zIndex: 10,
          }}
        />
      </WaitForPlayerReady>
    </div>
  );
}

export function Moments({
  className,
  momentContextMenuItems,
}: {
  className?: string;
  momentContextMenuItems?: (moment: PlayerMoment) => React.ReactNode;
}) {
  const $sliderRef = useRef<HTMLDivElement>(null);

  const { intrinsicDuration } = useMediaStore();
  const { playerZoom, setPlayerZoom } = usePlayerZoomController();
  const moments = usePlayerMoments();
  const { activateClipMoment } = useClipMoment();
  const { start: zoomStartTime, end: zoomEndTime } = playerZoom;
  const zoomDuration = zoomEndTime - zoomStartTime;

  const { midWindowMoments } = useMemo(() => {
    const preWindowMoments = [];
    const midWindowMoments = [];
    const postWindowMoments = [];

    for (const moment of moments) {
      if (moment.endTime < zoomStartTime) {
        preWindowMoments.push(moment);
      } else if (moment.startTime > zoomEndTime) {
        postWindowMoments.push(moment);
      } else {
        midWindowMoments.push(moment);
      }
    }

    return { preWindowMoments, midWindowMoments, postWindowMoments };
  }, [moments, zoomStartTime, zoomEndTime]);

  const sliderSize = useSize($sliderRef);

  return (
    <div
      className={cn("relative h-[6px] w-full bg-neutral-900", className)}
      ref={$sliderRef}
    >
      <WaitForPlayerReady>
        {midWindowMoments.map((moment) => {
          const navigateToMoment = () => {
            const momentZoom = {
              start: Math.max(moment.startTime - DEFAULT_SECONDS_ADDED, 0),
              end: Math.min(
                moment.endTime + DEFAULT_SECONDS_ADDED,
                intrinsicDuration,
              ),
            };

            setPlayerZoom(momentZoom);
            activateClipMoment(moment);
          };

          const targetPlayerZoom = sliderSize
            ? 1 /
              (40 / (sliderSize.width * (moment.endTime - moment.startTime)))
            : 1;

          const momentBarAdjust = linearScale(
            [targetPlayerZoom, intrinsicDuration],
            [0, 10],
          );
          const momentSizeAdjustment = momentBarAdjust(
            playerZoom.end - playerZoom.start,
          );

          return (
            <ContextMenu>
              <ContextMenuTrigger>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateToMoment();
                  }}
                  key={`moment:${moment.startTime}-${moment.endTime}`}
                  className={cn(
                    "group/marker absolute h-full cursor-pointer bg-indigo-700 transition-colors hover:bg-indigo-600",
                  )}
                  style={{
                    left: `calc(${((moment.startTime - zoomStartTime) / zoomDuration) * 100}% - ${momentSizeAdjustment}px)`,
                    right: `calc(${((zoomEndTime - moment.endTime) / zoomDuration) * 100}% - ${momentSizeAdjustment}px)`,
                  }}
                ></span>
              </ContextMenuTrigger>
              <ContextMenuContent>
                {momentContextMenuItems && momentContextMenuItems(moment)}
              </ContextMenuContent>
            </ContextMenu>
          );
        })}
      </WaitForPlayerReady>
    </div>
  );
}

const MIN_SECONDS = 1;
export function Window({ withMiniMap = true }) {
  const $sliderRef = useRef<HTMLDivElement>(null);
  const $thumbRef = useRef<HTMLElement>(null);

  const { playerZoom, setPlayerZoom } = usePlayerZoomController();
  const { start: zoomSliderStart, end: zoomSliderEnd } = playerZoom;
  const { intrinsicDuration } = useMediaStore();
  const framesPerSecond = useFramesPerSecond();

  useZoomGestures($sliderRef);

  const thumbSize = useSize($thumbRef);

  const leftThumbAdjustment = thumbSize?.width
    ? getThumbInBoundsOffset(
        thumbSize.width,
        (zoomSliderStart / intrinsicDuration) * 100,
        1,
      )
    : 0;
  const rightThumbAdjustment = thumbSize?.width
    ? getThumbInBoundsOffset(
        thumbSize.width,
        (zoomSliderEnd / intrinsicDuration) * 100,
        1,
      )
    : 0;

  const minSteps = MIN_SECONDS * framesPerSecond;
  const leftOffsetForMinWidth = linearScale(
    [0, zoomSliderEnd - minSteps],
    [0, thumbSize ? thumbSize.width / 2 : 0],
  );
  const rightOffsetForMinWidth = linearScale(
    [zoomSliderStart + minSteps, intrinsicDuration],
    [thumbSize ? thumbSize.width / 2 : 0, 0],
  );
  const leftAdditionalAdjustment = leftOffsetForMinWidth(zoomSliderStart);
  const rightAdditionalAdjustment = rightOffsetForMinWidth(zoomSliderEnd);

  return (
    <div
      className="relative flex h-[10px] items-center bg-neutral-900"
      ref={$sliderRef}
    >
      <span className="absolute inset-0 bg-black/20" />
      {withMiniMap && (
        <WaitForPlayerReady>
          <MiniMap className="animate-fadeIn absolute left-0 right-0 top-1/2 h-full -translate-y-1/2" />
        </WaitForPlayerReady>
      )}
      <Slider.Root
        className="relative flex h-full w-full items-center"
        value={[zoomSliderStart, zoomSliderEnd]}
        min={0}
        max={intrinsicDuration}
        step={1 / framesPerSecond}
        minStepsBetweenThumbs={minSteps}
        onValueChange={([newStart = 0, newEnd = intrinsicDuration]) => {
          const startDiff = newStart - zoomSliderStart;
          const endDiff = newEnd - zoomSliderEnd;

          const usingStart = Math.abs(startDiff) > Math.abs(endDiff);
          const diff = usingStart ? startDiff : endDiff;

          const startSign = usingStart ? Math.sign(diff) : -1 * Math.sign(diff);

          setPlayerZoom({
            start: zoomSliderStart + startSign * Math.abs(diff),
            end: zoomSliderEnd + -1 * startSign * Math.abs(diff),
          });
        }}
      >
        <WaitForPlayerReady>
          <Slider.Track className="relative h-[10px] grow touch-none select-none overflow-hidden rounded-full">
            <span
              className="animate-fadeIn absolute h-[10px] cursor-pointer bg-zinc-50/10 transition-colors active:bg-zinc-50/5"
              onPointerDown={(e: React.PointerEvent<HTMLSpanElement>) => {
                e.stopPropagation();
                e.currentTarget.setPointerCapture(e.pointerId);
              }}
              onPointerMove={(e) => {
                if (!$sliderRef.current) {
                  return;
                }

                if (!e.currentTarget.hasPointerCapture(e.pointerId)) {
                  return;
                }

                e.stopPropagation();

                const containerWidth =
                  $sliderRef.current.getBoundingClientRect().width;

                const diff = (e.movementX / containerWidth) * intrinsicDuration;

                const newStart = playerZoom.start + diff;
                const newEnd = playerZoom.end + diff;

                if (newStart < 0 || newEnd > intrinsicDuration) {
                  return;
                }

                setPlayerZoom({
                  start: newStart,
                  end: newEnd,
                });
              }}
              onPointerUp={(e) => {
                e.currentTarget.releasePointerCapture(e.pointerId);
              }}
              style={{
                left: `calc(${(zoomSliderStart / intrinsicDuration) * 100}% + ${leftThumbAdjustment - leftAdditionalAdjustment}px)`,
                right: `calc(${100 - (zoomSliderEnd / intrinsicDuration) * 100}% - ${rightThumbAdjustment + rightAdditionalAdjustment}px)`,
              }}
            />
          </Slider.Track>
          <span
            className="animate-fadeIn absolute"
            style={{
              left: `calc(${(zoomSliderStart / intrinsicDuration) * 100}% + ${leftThumbAdjustment - leftAdditionalAdjustment}px)`,
            }}
          >
            <span
              className="block h-[10px] w-[10px] flex-none -translate-x-1/2 cursor-pointer rounded-full border border-zinc-500 bg-zinc-600 transition-transform focus-visible:ring-0 active:bg-zinc-700"
              ref={$thumbRef}
            />
          </span>
          <span
            className="animate-fadeIn absolute"
            style={{
              left: `calc(${(zoomSliderEnd / intrinsicDuration) * 100}% + ${rightThumbAdjustment + rightAdditionalAdjustment}px)`,
            }}
          >
            <span className="block h-[10px] w-[10px] flex-none -translate-x-1/2 cursor-pointer rounded-full border border-zinc-500 bg-zinc-600 transition-transform focus-visible:ring-0 active:bg-zinc-700" />
          </span>
        </WaitForPlayerReady>
      </Slider.Root>
    </div>
  );
}

export function MiniMap({ className = "" }) {
  const moments = usePlayerMoments();
  const { clipMoment, isClipMomentActive, hasClipMoment } = useClipMoment();
  const { realCurrentTime, intrinsicDuration } = useMediaStore();

  const deferredCurrentTime = useDeferredValue(realCurrentTime);

  return (
    <div className={cn(className)}>
      {hasClipMoment && (
        <span
          className={cn(
            "absolute bottom-1/2 h-1/2 min-w-1 border-l border-r",
            isClipMomentActive
              ? "border-blue-700 bg-blue-700/40"
              : "border-zinc-600 bg-zinc-600/40",
          )}
          style={{
            left: (clipMoment.startTime / intrinsicDuration) * 100 + "%",
            width:
              (((Number.isFinite(clipMoment.endTime)
                ? clipMoment.endTime
                : intrinsicDuration) -
                clipMoment.startTime) /
                intrinsicDuration) *
                100 +
              "%",
          }}
        />
      )}
      {moments.map(({ startTime, endTime }) => {
        return (
          <span
            key={`moment-${startTime.toFixed(2)}:${endTime.toFixed(2)}`}
            className="absolute bottom-0 h-1/2 min-w-1 bg-indigo-800"
            style={{
              left: (startTime / intrinsicDuration) * 100 + "%",
              width: ((endTime - startTime) / intrinsicDuration) * 100 + "%",
            }}
          />
        );
      })}
      <span
        className="absolute top-0 h-full w-px bg-zinc-400"
        style={{ left: (deferredCurrentTime / intrinsicDuration) * 100 + "%" }}
      />
    </div>
  );
}

export function Clip({ className = "" }) {
  const $sliderRef = useRef<HTMLDivElement>(null);
  const $thumbRef = useRef<HTMLElement>(null);

  const zoom = useCurrentZoom();
  const {
    clipMoment,
    setClipMomentStart,
    setClipMomentEnd,
    hasClipMoment,
    isClipMomentActive,
  } = useClipMoment();
  const { startTime: clipStartTime, endTime: clipEndTime } = clipMoment;

  const { intrinsicDuration } = useMediaStore();

  const [isDraggingClip, setIsDraggingClip] = useState<boolean>(false);

  const thumbSize = useSize($thumbRef);

  const leftThumbAdjustment = thumbSize?.width
    ? getThumbInBoundsOffset(
        thumbSize.width,
        ((clipStartTime - zoom.start) / (zoom.end - zoom.start)) * 100,
        1,
      )
    : 0;
  const rightThumbAdjustment = thumbSize?.width
    ? getThumbInBoundsOffset(
        thumbSize.width,
        ((clipEndTime - zoom.start) / (zoom.end - zoom.start)) * 100,
        1,
      )
    : 0;

  const framesPerSecond = useFramesPerSecond();

  return (
    <div
      className={cn(
        "relative hidden h-4 w-full",
        hasClipMoment && "block",
        className,
      )}
      ref={$sliderRef}
    >
      <Slider.Root
        value={[clipStartTime, clipEndTime]}
        min={zoom.start}
        max={zoom.end}
        step={1 / framesPerSecond}
        minStepsBetweenThumbs={framesPerSecond}
        className="absolute left-0 right-0 h-0"
        onValueChange={([newStart = 0, newEnd = intrinsicDuration]) => {
          setClipMomentStart(newStart);
          setClipMomentEnd(newEnd);
        }}
      >
        <span
          className={cn(
            "pointer-events-none absolute flex h-4 items-center justify-center bg-neutral-800/60",
            isDraggingClip && "bg-neutral-800/70",
            isClipMomentActive && "bg-blue-500/20",
          )}
          style={{
            left: `calc(${((clipStartTime - zoom.start) / (zoom.end - zoom.start)) * 100}% + ${leftThumbAdjustment}px)`,
            right: Number.isFinite(clipEndTime)
              ? `calc(${100 - ((clipEndTime - zoom.start) / (zoom.end - zoom.start)) * 100}% - ${rightThumbAdjustment}px)`
              : 0,
          }}
        >
          <GrabberIcon
            onPointerDown={(e: React.PointerEvent<SVGElement>) => {
              e.stopPropagation();
              e.currentTarget.setPointerCapture(e.pointerId);

              setIsDraggingClip(true);
            }}
            onPointerMove={(e) => {
              if (!$sliderRef.current) {
                return;
              }

              if (!e.currentTarget.hasPointerCapture(e.pointerId)) {
                return;
              }

              e.stopPropagation();

              const diff =
                (e.movementX /
                  $sliderRef.current.getBoundingClientRect().width) *
                (zoom.end - zoom.start);

              setClipMomentStart(clipStartTime + diff);
              setClipMomentEnd(clipEndTime + diff);
            }}
            onPointerUp={(e) => {
              e.currentTarget.releasePointerCapture(e.pointerId);
              setIsDraggingClip(false);
            }}
            className={cn(
              "pointer-events-auto h-full cursor-grab",
              isClipMomentActive ? "text-blue-500" : "text-zinc-400",
              isDraggingClip && "cursor-grabbing",
              (clipEndTime > zoom.end || clipStartTime < zoom.start) &&
                "hidden",
            )}
          />
        </span>
        <span
          className={cn(
            "pointer-events-auto absolute flex flex-none -translate-x-full cursor-ew-resize items-center justify-center transition-transform focus:outline-none focus-visible:outline-none",
            (clipStartTime < zoom.start || clipStartTime > zoom.end) &&
              "hidden",
          )}
          style={{
            left: `calc(${((clipStartTime - zoom.start) / (zoom.end - zoom.start)) * 100}% + ${leftThumbAdjustment}px)`,
          }}
          ref={$thumbRef}
        >
          <WindowHandleLeftIcon
            className={cn(
              "h-4 text-blue-500",
              isClipMomentActive ? "fill-blue-500/20" : "fill-neutral-800/60",
            )}
          />
        </span>
        <span
          className={cn(
            "pointer-events-auto absolute flex h-4 flex-none translate-x-full cursor-ew-resize items-center justify-center text-blue-500 transition-transform focus:outline-none focus-visible:outline-none",
            (clipEndTime > zoom.end || clipEndTime < zoom.start) && "hidden",
          )}
          style={{
            right: Number.isFinite(clipEndTime)
              ? `calc(${100 - ((clipEndTime - zoom.start) / (zoom.end - zoom.start)) * 100}% - ${rightThumbAdjustment}px)`
              : 0,
          }}
        >
          <WindowHandleRightIcon
            className={cn(
              "h-4 text-blue-500",
              isClipMomentActive ? "fill-blue-500/20" : "fill-neutral-800/60",
            )}
          />
        </span>
      </Slider.Root>
    </div>
  );
}

// https://github.com/radix-ui/primitives/blob/6469d41dcc6e84fe41d70a2703924338e7562dd1/packages/react/slider/src/Slider.tsx#L708
function getThumbInBoundsOffset(
  width: number,
  left: number,
  direction: number,
) {
  const halfWidth = width / 2;
  const halfPercent = 50;
  const offset = linearScale([0, halfPercent], [0, halfWidth]);
  return (halfWidth - offset(left) * direction) * direction;
}

// https://github.com/tmcw-up-for-adoption/simple-linear-scale/blob/master/index.js
// https://github.com/radix-ui/primitives/blob/6469d41dcc6e84fe41d70a2703924338e7562dd1/packages/react/slider/src/Slider.tsx#L748
function linearScale(
  input: readonly [number, number],
  output: readonly [number, number],
) {
  return (value: number) => {
    if (input[0] === input[1] || output[0] === output[1]) return output[0];
    const ratio = (output[1] - output[0]) / (input[1] - input[0]);
    return output[0] + ratio * (value - input[0]);
  };
}
