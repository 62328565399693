import { useMediaItems } from "./useAllMediaQuery";

export function useGetMediaItems(
  mediaIds: string[],
  removeDuplicates: boolean = false,
) {
  const uniqueMediaIds = removeDuplicates ? [...new Set(mediaIds)] : mediaIds;
  const { data: mediaItems, isLoading } = useMediaItems(uniqueMediaIds);

  return {
    data: removeDuplicates
      ? Object.values(mediaItems || {})
      : mediaIds.map((mediaId) => mediaItems?.[mediaId]),
    isLoading,
  };
}
