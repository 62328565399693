import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "@/components/ag-grid/ag-theme-kino.css";
import { useMemo } from "react";
import {
  ColDef,
  ISetFilterParams,
  KeyCreatorParams,
  NestedFieldPaths,
  ValueFormatterParams,
} from "ag-grid-community";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";
import { useActiveServerStore } from "@/store/activeServerStore";
import { components } from "@/openapi-bindings/v2";
import { getSlateData } from "@/utils/multicam";
import { secondsToTimestamp } from "@/utils/time";
import { getFileName } from "@/utils/pathUtils";
import {
  PeopleCellRenderer,
  HasMulticamCellRenderer,
  ThumbnailCellRenderer,
  TagCellRenderer,
  IndexCellRenderer,
} from "@/components/ag-grid/gridCells";
import { ExpandedCellEditor } from "@/components/ag-grid/CellEditor";
import { formatBitRate, formatFrameRate, formatSize } from "@/utils/ffprobe";
import { useCanAccessDevFeatures } from "../useCanAccessDevFeatures";
import DynamicContentCell from "@/components/ag-grid/cell-renderer/DynamicContentCell";
import { DisplayMode } from "@/types/display";
import { useGetTagsQuery } from "../useGetTagsQuery";
import { PlainTagsRenderer } from "@/components/ag-grid/cell-renderer/PlainTagsRenderer";
import {
  useDisplayStore,
  getEffectiveMode,
} from "@/store/display/displayStore";
import { match } from "ts-pattern";
import { useDisplayTags } from "../useGetTagTypeDefinition";

type MediaItem = components["schemas"]["MediaItem"];
type UmidData = components["schemas"]["UmidData"];

type GridColumnKey =
  | "index"
  | "thumbnail"
  | "filename"
  | "duration"
  | "people"
  | "shot"
  | "dateShot"
  | "scene"
  | "cameraIdentifier"
  | "hasMulticam"
  | "title"
  | "description"
  | "moments"
  | "transcript"
  | "celebrities_in_video"
  | "celebrities_not_in_video"
  | "num_video_streams"
  | "num_audio_streams"
  | "creation_time"
  | "size"
  | "bit_rate"
  | "framerate"
  | "format_name"
  | "resolution"
  | "codec"
  | "encoder"
  | "location"
  | "eventName"
  | "assetType"
  | "tags"
  | "id"
  | "day"
  | "crew"
  | "tape"
  | "camera_type"
  | "tape_id"
  | "umid"
  | "unique_clip"
  | "smpte_start_time"
  | "smpte_end_time"
  // BS2
  | "scene_part"
  | "take_number"
  | "scene_number"
  | "shooting_day"
  | "production_block"
  | "episode"
  | "file_path";

type ColumnRecord = {
  [K in GridColumnKey]: ColDef<MediaItem>;
} & {
  [K: string]: never; // This prevents additional properties
};

interface OrganizationConfig {
  layout: {
    start: GridColumnKey[];
    middle: GridColumnKey[];
    end: GridColumnKey[];
  };
}

interface SummarizeOutput {
  output: KinoGeneratedMetadata;
  run_id: string;
  timestamp: number;
  context: any;
}

interface KinoGeneratedMetadata {
  title: string;
  moments: string;
  location: string;
  asset_type: string;
  event_name: string;
  transcript: string;
  description: string;
  celebrities_in_video: string;
  celebrities_not_in_video: string;
  id: string;
}

export const getKinoMetadata = (
  item: MediaItem | undefined,
): KinoGeneratedMetadata | undefined => {
  if (!item || !item.metadata?.raw_metadata) return undefined;

  const rawMetadata = item.metadata.raw_metadata as {
    summarize_outputs?: Array<SummarizeOutput>;
  };

  // First try the new kino_generated format
  if (
    item.metadata?.kino_generated &&
    Object.keys(item.metadata.kino_generated).length > 0
  ) {
    // Get all entries and convert timestamps to numbers for sorting
    const entries = Object.entries(item.metadata.kino_generated).map(
      ([timestamp, data]) => ({
        timestamp: parseFloat(timestamp),
        data: data as KinoGeneratedMetadata,
      }),
    );

    // Sort by timestamp (descending)
    const sorted = entries.sort((a, b) => b.timestamp - a.timestamp);
    return sorted[0]?.data;
  }

  // Fall back to the old raw_metadata format
  if (!rawMetadata.summarize_outputs?.length) return undefined;

  // Sort summarize_outputs by timestamp (descending)
  const sortedOutputs = [...rawMetadata.summarize_outputs].sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  return sortedOutputs[0]?.output;
};

const ffProbeColumns: GridColumnKey[] = [
  "num_video_streams",
  "num_audio_streams",
  "creation_time",
  "size",
  "bit_rate",
  "framerate",
  "format_name",
  "resolution",
  "codec",
  "encoder",
];

const organizationConfigs: Record<DisplayMode, OrganizationConfig> = {
  default: {
    layout: {
      start: ["thumbnail", "title"],
      middle: [
        "description",
        "transcript",
        "duration",
        "moments",
        "people",
        ...ffProbeColumns,
        "id",
      ],
      end: [],
    },
  },
  news: {
    layout: {
      start: ["thumbnail", "title"],
      middle: [
        "description",
        "assetType",
        "filename",
        "eventName",
        "location",
        "transcript",
        "moments",
        "duration",
        "celebrities_in_video",
        "celebrities_not_in_video",
        ...ffProbeColumns,
        "id",
      ],
      end: [],
    },
  },
  unscripted: {
    layout: {
      start: ["thumbnail", "filename"],
      middle: [
        "description",
        "tags",
        "duration",
        "people",
        "filename",
        "location",
        "transcript",
        "moments",
        "duration",
        "title",
        ...ffProbeColumns,
      ],
      end: [],
    },
  },
  scripted: {
    layout: {
      start: ["thumbnail", "filename", "hasMulticam"],
      middle: [
        "dateShot",
        "shot",
        "scene",
        "cameraIdentifier",
        "duration",
        ...ffProbeColumns,
      ],
      end: [],
    },
  },
  s48: {
    layout: {
      start: ["thumbnail", "unique_clip"],
      middle: [
        "day",
        "crew",
        "tape",
        "camera_type",
        "duration",
        "tape_id",
        "smpte_start_time",
        "smpte_end_time",
        "people",
        "tags",
        "filename",
        "umid",
        "id",
      ],
      end: [],
    },
  },
  bs2: {
    layout: {
      start: ["thumbnail", "file_path"],
      middle: [
        "scene_number",
        "scene_part",
        "take_number",
        "people",
        "production_block",
        "shooting_day",
        "episode",
        "tags",
        "duration",
        "id",
      ],
      end: [],
    },
  },
};

const baseColumnConfig: Partial<ColDef<MediaItem>> = {
  sortable: true,
  resizable: true,
  suppressSizeToFit: true,
  suppressMovable: true,
  headerClass: "fixed-size-header",
  suppressAutoSize: true,
  mainMenuItems: ["sortAscending", "sortDescending", "sortUnSort"],
};

const getBaseColumnConfig = (
  config: Partial<ColDef<MediaItem>>,
  generated?: boolean,
) => {
  if (!generated) {
    return {
      ...baseColumnConfig,
      ...config,
    };
  }

  return {
    ...baseColumnConfig,
    ...config,
    cellRenderer: DynamicContentCell,
    cellRendererParams: {
      cellRenderer: config.cellRenderer,
      cellRendererParams: config.cellRendererParams,
    },
  };
};

const editorColumnConfig: Partial<ColDef<MediaItem>> = {
  editable: true,
  cellEditorPopup: true,
};

const withCellEditor = (config: Partial<ColDef<MediaItem>>) => {
  return {
    ...config,
    editable: true,
    cellEditor: ExpandedCellEditor,
    cellEditorParams: {
      cellRenderer: config.cellRenderer,
      cellRendererParams: config.cellRendererParams,
    },
    cellEditorPopup: true,
  };
};

interface ColumnConfigOptions {
  suppressEditor?: boolean;
  generated?: boolean;
}

const createColumn = (
  config: Partial<ColDef<MediaItem>>,
  options: ColumnConfigOptions = {},
) => {
  // Ensure each column has a stable colId based on its field or a meaningful identifier
  const colId =
    config.field ||
    (typeof config.valueGetter === "function"
      ? config.headerName?.toLowerCase().replace(/\s+/g, "_")
      : undefined);

  return {
    ...config,
    colId,
    ...(options.suppressEditor ? config : withCellEditor(config)),
    ...(!options.suppressEditor && editorColumnConfig),
    ...getBaseColumnConfig(config, options.generated),
  };
};

export const useAGMediaGrid = ({
  media,
  displayMode = "default",
}: {
  media?: components["schemas"]["AllMediaResponse"];
  displayMode?: DisplayMode;
}) => {
  const { data: people } = useGetPeopleQuery();
  const { buildServerUrl } = useActiveServerStore();
  const canAccessDevFeatures = useCanAccessDevFeatures();
  const { data: tagsData } = useGetTagsQuery();
  const tags = tagsData ?? [];
  const { orderTags } = useDisplayTags();
  const orderedTags = tags ? orderTags(tags) : undefined;
  const mode = useDisplayStore(getEffectiveMode);

  const columns: ColumnRecord = useMemo(
    () => ({
      index: createColumn(
        {
          headerName: "Index",
          width: 10,
          pinned: "left",
          headerValueGetter: () => {
            return "#";
          },
          valueGetter: (params) =>
            params.node?.rowIndex != null ? params.node.rowIndex + 1 : "",
          sortable: false,
          suppressSizeToFit: true,
          suppressMovable: true,
          suppressMenu: true,
          cellRenderer: IndexCellRenderer,
          filter: false,
        },
        {
          suppressEditor: true,
        },
      ),
      thumbnail: createColumn(
        {
          headerName: "Thumbnail",
          minWidth: 100,
          width: 100,
          sortable: false,
          filter: false,
          cellEditor: false,
          headerValueGetter: () => {
            return "";
          },
          suppressHeaderMenuButton: true,
          valueGetter: (params) => {
            return buildServerUrl(params.data?.key_thumbnail_path ?? "");
          },
          cellRenderer: ThumbnailCellRenderer,
          cellStyle: ThumbnailCellRenderer.cellStyle,
        },
        {
          suppressEditor: true,
        },
      ),
      filename: createColumn({
        headerName: "Filename",
        valueGetter: (params) => {
          return match(mode)
            .with("s48", () => {
              const filename = getFileName(params.data?.original_path ?? "");
              return filename?.startsWith("transcoded_")
                ? filename.substring(11)
                : filename;
            })
            .otherwise(() => {
              return getFileName(params.data?.original_path ?? "");
            });
        },
        wrapText: false,
        minWidth: 80,
        width: 300,
        cellClass: "text-neutral-400 h-full",
      }),
      duration: createColumn({
        headerName: "Duration",
        valueGetter: (params) => {
          const durationSeconds = Number(
            params.data?.ffprobe_data?.format?.duration ?? 0,
          );
          return {
            formatted: secondsToTimestamp(durationSeconds, {
              padZero: false,
            }),
            raw: durationSeconds,
          };
        },
        valueFormatter: (params) => params.value.formatted,
        comparator: (valueA, valueB) => valueA.raw - valueB.raw,
        minWidth: 80,
        width: 100,
      }),
      dateShot: createColumn({
        headerName: "Date Shot",
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.processed_metadata as any)?.date_shot ?? ""
          );
        },
        minWidth: 80,
        width: 100,
      }),
      // FFProbe Columns
      num_video_streams: createColumn({
        field: "ffprobe_data.num_video_streams",
        headerName: "Video Streams",
        minWidth: 80,
        width: 100,
      }),

      num_audio_streams: createColumn({
        field: "ffprobe_data.num_audio_streams",
        headerName: "Audio Streams",
        minWidth: 80,
        width: 100,
      }),

      creation_time: createColumn({
        field: "ffprobe_data.format.tags.creation_time",
        headerName: "Creation Time",
        minWidth: 80,
        width: 150,
      }),

      size: createColumn({
        headerName: "Size",
        minWidth: 80,
        width: 100,
        valueGetter: (params) =>
          formatSize(Number(params.data?.ffprobe_data.format.size || 0)),
      }),

      bit_rate: createColumn({
        headerName: "Bit Rate",
        minWidth: 80,
        width: 100,
        valueGetter: (params) =>
          formatBitRate(Number(params.data?.ffprobe_data.format.bit_rate || 0)),
      }),

      framerate: createColumn({
        headerName: "Frame Rate",
        minWidth: 80,
        width: 100,
        valueGetter: (params) => {
          const num = params.data?.ffprobe_data?.framerate_numerator;
          const den = params.data?.ffprobe_data?.framerate_denominator;
          return num && den ? formatFrameRate([num, den]) : "";
        },
      }),

      format_name: createColumn({
        field: "ffprobe_data.format.format_name",
        headerName: "Format",
        minWidth: 80,
        width: 100,
      }),

      resolution: createColumn({
        headerName: "Resolution",
        minWidth: 80,
        width: 120,
        valueGetter: (params) => {
          const videoStream = params.data?.ffprobe_data?.streams.find(
            (s) => s.codec_type === "video",
          );
          return videoStream?.width && videoStream?.height
            ? `${videoStream.width}x${videoStream.height}`
            : "";
        },
      }),
      codec: createColumn({
        headerName: "Codec",
        minWidth: 80,
        width: 100,
        valueGetter: (params) => {
          const videoStream = params.data?.ffprobe_data?.streams.find(
            (s) => s.codec_type === "video",
          );
          return videoStream?.codec_name || "";
        },
        filter: true,
      }),
      encoder: createColumn({
        field: "ffprobe_data.format.tags.encoder",
        headerName: "Encoder",
        minWidth: 80,
        width: 150,
      }),
      // Multicam Columns
      shot: createColumn({
        valueGetter: (params) => {
          return getSlateData(params.data)?.shot ?? "";
        },
        headerName: "Shot",
        minWidth: 50,
        width: 80,
      }),
      scene: createColumn({
        valueGetter: (params) => {
          return getSlateData(params.data)?.scene ?? "";
        },
        headerName: "Scene",
        minWidth: 50,
        width: 80,
      }),
      cameraIdentifier: createColumn({
        valueGetter: (params) => {
          return getSlateData(params.data)?.camera_identifier ?? "";
        },
        headerName: "Camera",
        minWidth: 50,
        width: 80,
      }),
      hasMulticam: createColumn({
        valueGetter: (params) => {
          return Boolean(
            (params.data?.metadata?.raw_metadata as any)?.multicam_group,
          );
        },
        headerName: "Multicam",
        minWidth: 50,
        width: 80,
        maxWidth: 100,
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        cellRenderer: HasMulticamCellRenderer,
      }),
      // Generated Metadata Columns
      title: createColumn(
        {
          valueGetter: (params) => {
            return getKinoMetadata(params.data)?.title ?? "";
          },
          headerName: "Title",
          minWidth: 80,
          wrapText: true,
        },
        {
          generated: true,
        },
      ),
      people: createColumn(
        {
          headerName: "People",
          width: 500,
          minWidth: 200,
          sortable: false,
          cellRenderer: PeopleCellRenderer,
          cellStyle: PeopleCellRenderer.cellStyle,
          valueGetter: (params) => {
            return (
              people?.filter((p) => params.data?.person_ids?.includes(p.id)) ||
              []
            );
          },
          cellRendererParams: {
            buildServerUrl,
          },
          filter: "agSetColumnFilter",
          keyCreator: (params: KeyCreatorParams) => {
            return params.value.name;
          },
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value ? params.value.name : "(Blanks)";
            },
          } as ISetFilterParams<any, components["schemas"]["Person"]>,
        },
        {
          generated: true,
        },
      ),
      transcript: createColumn(
        {
          valueGetter: (params) => {
            const transcript = getKinoMetadata(params.data)?.transcript ?? "";
            return transcript.replace(/<br\s*\/?>/gi, "\n\n");
          },
          headerName: "Transcript",
          minWidth: 80,
          width: 350,
          wrapText: true,
        },
        {
          generated: true,
        },
      ),
      description: createColumn(
        {
          valueGetter: (params) => {
            return getKinoMetadata(params.data)?.description ?? "";
          },
          headerName: "Description",
          minWidth: 80,
          width: 350,
          wrapText: true,
        },
        {
          generated: true,
        },
      ),
      moments: createColumn(
        {
          valueGetter: (params) => {
            const moments = getKinoMetadata(params.data)?.moments ?? "";
            return moments.replace(/;/g, "\n\n");
          },
          headerName: "Moments",
          minWidth: 80,
          width: 200,
          wrapText: true,
        },
        {
          generated: true,
        },
      ),
      celebrities_in_video: createColumn(
        {
          valueGetter: (params) => {
            const celebrities =
              getKinoMetadata(params.data)?.celebrities_in_video ?? "";
            return celebrities
              ? celebrities.split(",").map((c) => ({
                  id: c.trim(),
                  value: c.trim(),
                  tag_type: "TMZ_CELEBRITY",
                  user_id: null,
                }))
              : [];
          },
          headerName: "Celebrities In Video",
          minWidth: 80,
          width: 150,
          cellRenderer: TagCellRenderer,
          filter: "agSetColumnFilter",
          keyCreator: (params: KeyCreatorParams) => {
            return params.value.value;
          },
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value ? params.value.value : "(Blanks)";
            },
          } as ISetFilterParams,
        },
        {
          generated: true,
        },
      ),

      celebrities_not_in_video: createColumn(
        {
          headerName: "Celebrities Not In Video",
          valueGetter: (params) => {
            const celebrities =
              getKinoMetadata(params.data)?.celebrities_not_in_video ?? "";
            return celebrities
              ? celebrities.split(",").map((c) => ({
                  id: c.trim(),
                  value: c.trim(),
                  tag_type: "TMZ_CELEBRITY_NOT_IN_VIDEO",
                  user_id: null,
                }))
              : [];
          },
          minWidth: 80,
          width: 150,
          cellRenderer: TagCellRenderer,
          filter: "agSetColumnFilter",
          keyCreator: (params: KeyCreatorParams) => {
            return params.value.value;
          },
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value ? params.value.value : "(Blanks)";
            },
          } as ISetFilterParams,
        },
        {
          generated: true,
        },
      ),

      assetType: createColumn(
        {
          headerName: "Asset Type",
          valueGetter: (params) => {
            const assetType = getKinoMetadata(params.data)?.asset_type ?? "";
            return assetType
              ? assetType.split(",").map((type) => ({
                  id: type.trim(),
                  value: type.trim(),
                  tag_type: "TMZ_ASSET_TYPE",
                  user_id: null,
                }))
              : [];
          },
          width: 200,
          wrapText: true,
          cellRenderer: TagCellRenderer,
          filter: "agSetColumnFilter",
          keyCreator: (params: KeyCreatorParams) => {
            return params.value.value;
          },
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) => {
              return params.value ? params.value.value : "(Blanks)";
            },
          } as ISetFilterParams,
        },
        {
          generated: true,
        },
      ),
      tags: createColumn({
        headerName: "Tags",
        field: "tag_ids",
        filter: "agSetColumnFilter",
        filterParams: {
          values: tags.map((tag: components["schemas"]["Tag"]) => ({
            id: tag.id,
            value: tag.value,
            tag_type: tag.tag_type,
            user_id: tag.user_id,
          })),
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? params.value.value : "(Blanks)";
          },
          keyCreator: (params: KeyCreatorParams) => {
            return params.value.value;
          },
          refreshValuesOnOpen: true,
          excelMode: "windows",
          suppressSorting: false,
          suppressMiniFilter: false,
        } as ISetFilterParams<any, components["schemas"]["Tag"]>,
        valueGetter: (params) => {
          return tags.filter((t: components["schemas"]["Tag"]) =>
            params.data?.tag_ids?.includes(t.id),
          );
        },
        cellRenderer: TagCellRenderer,
      }),
      location: createColumn(
        {
          headerName: "Location",
          valueGetter: (params) => {
            return getKinoMetadata(params.data)?.location ?? "";
          },
          width: 200,
          wrapText: true,
        },
        {
          generated: true,
        },
      ),
      eventName: createColumn(
        {
          valueGetter: (params) => {
            return getKinoMetadata(params.data)?.event_name ?? "";
          },
          headerName: "Event Name",
          width: 200,
          wrapText: true,
        },
        {
          generated: true,
        },
      ),
      smpte_start_time: createColumn({
        headerName: "SMPTE Start Time",
        width: 100,
        valueGetter: (params) => {
          return params.data?.start_timecode ?? "";
        },
        cellClass: "text-neutral-600",
      }),
      smpte_end_time: createColumn({
        headerName: "SMPTE End Time",
        width: 100,
        valueGetter: (params) => {
          return params.data?.end_timecode ?? "";
        },
        cellClass: "text-neutral-600",
      }),
      tape_id: createColumn({
        headerName: "Tape ID",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["umiddata"] as UmidData)?.tape_id ?? ""
          );
        },
      }),
      unique_clip: createColumn({
        headerName: "Unique Clip",
        width: 280,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["umiddata"] as UmidData)?.unique_clip ?? ""
          );
        },
      }),
      umid: createColumn({
        headerName: "UMID",
        width: 100,
        valueGetter: (params) => {
          return (params.data?.metadata?.["umiddata"] as UmidData)?.uid ?? "";
        },
      }),
      // Dev Columns
      id: createColumn({
        field: "id",
        headerName: "ID",
        minWidth: 80,
        width: 100,
        hide: !canAccessDevFeatures,
        cellClass: "text-neutral-600",
      }),
      day: createColumn({
        headerName: "Day",
        width: 100,
        valueGetter: (params) => {
          const dayTag = orderedTags?.filter(
            (t) =>
              params.data?.tag_ids?.includes(t.id) &&
              (t.tag_type === "S48_DAY" || t.tag_type == "day"),
          )[0];
          return dayTag ? [dayTag] : [];
        },
        cellRenderer: PlainTagsRenderer,
        filter: "agSetColumnFilter",
        keyCreator: (params: KeyCreatorParams) => {
          return params.value.value;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? params.value.value : "(Blanks)";
          },
        } as ISetFilterParams,
      }),
      crew: createColumn({
        headerName: "Crew",
        width: 100,
        valueGetter: (params) => {
          const crewTag = orderedTags?.filter(
            (t) =>
              params.data?.tag_ids?.includes(t.id) &&
              (t.tag_type === "S48_CREW" || t.tag_type == "crew"),
          )[0];
          return crewTag ? [crewTag] : [];
        },
        cellRenderer: PlainTagsRenderer,
        filter: "agSetColumnFilter",
        keyCreator: (params: KeyCreatorParams) => {
          return params.value.value;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? params.value.value : "(Blanks)";
          },
        } as ISetFilterParams,
      }),
      tape: createColumn({
        headerName: "Tape",
        width: 100,
        valueGetter: (params) => {
          const tapeTag = orderedTags?.filter(
            (t) =>
              params.data?.tag_ids?.includes(t.id) &&
              (t.tag_type === "S48_TAPE" || t.tag_type == "tape"),
          )[0];
          return tapeTag ? [tapeTag] : [];
        },
        cellRenderer: PlainTagsRenderer,
        filter: "agSetColumnFilter",
        keyCreator: (params: KeyCreatorParams) => {
          return params.value.value;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? params.value.value : "(Blanks)";
          },
        } as ISetFilterParams,
      }),
      camera_type: createColumn({
        headerName: "Camera Type",
        width: 100,
        valueGetter: (params) => {
          const cameraTypeTag = orderedTags?.filter(
            (t) =>
              params.data?.tag_ids?.includes(t.id) &&
              (t.tag_type === "S48_CAMERA_TYPE" || t.tag_type == "camera_type"),
          )[0];
          return cameraTypeTag ? [cameraTypeTag] : [];
        },
        cellRenderer: PlainTagsRenderer,
        filter: "agSetColumnFilter",
        keyCreator: (params: KeyCreatorParams) => {
          return params.value.value;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? params.value.value : "(Blanks)";
          },
        } as ISetFilterParams,
      }),
      // BS2
      episode: createColumn({
        headerName: "Episode",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["extracted_media_metadata"] as any)?.[
              "episode"
            ] ?? ""
          );
        },
      }),
      scene_part: createColumn({
        headerName: "Scene Part",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["extracted_media_metadata"] as any)?.[
              "scene_part"
            ] ?? ""
          );
        },
      }),
      take_number: createColumn({
        headerName: "Take",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["extracted_media_metadata"] as any)?.[
              "take_number"
            ] ?? ""
          );
        },
      }),
      scene_number: createColumn({
        headerName: "Scene",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["extracted_media_metadata"] as any)?.[
              "scene_number"
            ] ?? ""
          );
        },
      }),
      shooting_day: createColumn({
        headerName: "Shooting Day",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["extracted_media_metadata"] as any)?.[
              "shooting_day"
            ] ?? ""
          );
        },
        sort: "desc",
        sortIndex: 0,
      }),
      production_block: createColumn({
        headerName: "Production Block",
        width: 100,
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.["extracted_media_metadata"] as any)?.[
              "production_block"
            ] ?? ""
          );
        },
      }),
      file_path: createColumn({
        headerName: "File Path",
        width: 200,
        valueGetter: (params) => {
          const fullPath = params.data?.metadata?.["file_path"] ?? "";
          if (typeof fullPath === "string") {
            const mxfIndex = fullPath.indexOf("/MXF/");
            if (mxfIndex !== -1) {
              return fullPath.substring(mxfIndex + 5); // +5 to skip "/MXF/"
            }
          }
          return fullPath;
        },
      }),
    }),
    [buildServerUrl, tags],
  ) as ColumnRecord;

  const columnDefs = useMemo(() => {
    // Use provided display mode or get default from organization
    const effectiveDisplayMode = displayMode || "unscripted"; // Default for now, will come from org store
    const config = organizationConfigs[effectiveDisplayMode];

    const createColumnGroup = (
      keys: GridColumnKey[],
      fixed?: "left" | "right",
    ): ColDef<MediaItem>[] => {
      return keys.map((key) => ({
        ...columns[key],
        pinned: fixed || null,
        field: columns[key].field as NestedFieldPaths<MediaItem, any, []>,
        // Apply default sort for shooting_day in BS2 mode
        ...(effectiveDisplayMode === "bs2" && key === "shooting_day"
          ? { sort: "desc", sortIndex: 0 }
          : {}),
      }));
    };

    return [
      columns.index,
      ...createColumnGroup(config.layout.start, "left"),
      ...createColumnGroup(config.layout.middle),
      ...createColumnGroup(config.layout.end, "right"),
    ];
  }, [columns, displayMode]);

  return {
    columnDefs,
    rowData: media?.media_items,
  };
};

export default useAGMediaGrid;
