import { usePerson, useUploadReferenceFaces } from "@/hooks/usePeople";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Separator,
  Skeleton,
} from "@kino/ui";
import { useNavigate, useParams } from "react-router";
import { components } from "@/openapi-bindings/v2";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useActiveServerStore } from "@/store/activeServerStore";
import { PlusIcon, TriangleAlert } from "lucide-react";
import { ReferenceFace } from "@/components/people/PersonReferenceFace";
import MediaGrid from "@/components/ag-grid/MediaGrid";
import { PersonPhotoDropzone } from "@/components/people/PersonPhotoDropzone";
import { PersonPhotoUploadZone } from "@/components/people/PersonPhotoUploadZone";
import { ModelUpdatedEvent } from "ag-grid-enterprise";
import { UnassignedImage } from "@/types/people";
import { toast } from "sonner";

export default function Person() {
  const { personId } = useParams();
  const navigate = useNavigate();
  const { data: person, status } = usePerson(personId);
  const { buildServerUrl } = useActiveServerStore();
  const [mediaItemsCount, setMediaItemsCount] = useState<number | null>(null);
  const gridRef = useRef<AgGridReact<components["schemas"]["MediaItem"]>>(null);

  const [showUploadZone, setShowUploadZone] = useState(false);
  const [facesToUpload, setFacesToUpload] = useState<UnassignedImage[]>([]);

  const uploadFaces = useUploadReferenceFaces();
  const uploadFacesForCurrentPerson = useCallback(
    (images: UnassignedImage[]) => {
      if (!person) {
        return;
      }

      uploadFaces(
        { personId: person.id, images },
        {
          onSuccess: () =>
            toast.success(
              `Reference image${images.length === 1 ? "" : "s"} uploaded for ${person.name}`,
            ),
          onError: () =>
            toast.error(`References images failed to upload to ${person.name}`),
        },
      );
    },
    [person],
  );

  useEffect(() => {
    if (!personId) {
      navigate("/people");
    }
  }, [personId]);

  const onGridModelUpdated = useCallback((event: ModelUpdatedEvent) => {
    const rowCount = event.api.getDisplayedRowCount();

    setMediaItemsCount(rowCount === 1 ? null : rowCount);
  }, []);

  let avatar;
  let name;
  let faces;
  let facesCount;

  if (status === "pending") {
    avatar = (
      <Skeleton>
        <Avatar size="xxl" />
      </Skeleton>
    );

    name = <Skeleton className="h-6 w-24" />;
  }

  if (status === "error") {
    avatar = (
      <Avatar size="xxl">
        <AvatarFallback className="h-32 min-h-[128px] w-32 min-w-[128px]">
          <TriangleAlert />
        </AvatarFallback>
      </Avatar>
    );

    name = (
      <span className="text-muted-foreground text-lg">
        There was an error loading this person
      </span>
    );
  }

  if (status === "success" && !person) {
    avatar = (
      <Avatar size="xxl">
        <AvatarFallback className="h-32 min-h-[128px] w-32 min-w-[128px]">
          <TriangleAlert />
        </AvatarFallback>
      </Avatar>
    );

    name = (
      <span className="text-muted-foreground text-lg">
        This person does not exist
      </span>
    );
  }

  if (status === "success" && person) {
    avatar = (
      <Avatar size="xxl">
        <AvatarImage
          src={buildServerUrl(person.thumbnail_path ?? "")}
          alt={`${person.name}'s avatar`}
          className="object-cover"
        />
        <AvatarFallback className="h-32 min-h-[128px] w-32 min-w-[128px]">
          {person.name[0]}
        </AvatarFallback>
      </Avatar>
    );

    name = (
      <>
        <h2 className="text-2xl font-bold">{person.name}</h2>
        {person.nickname && (
          <span className="text-muted-foreground text-lg">
            ({person.nickname})
          </span>
        )}
      </>
    );

    faces = (
      <>
        {person.reference_face_paths.map((path, i) => (
          <ReferenceFace
            key={person.id + "-" + i}
            path={path}
            personId={person.id}
            isAvatar={person.thumbnail_path === path}
            alt={`Reference image #${i} for ${person.name}`}
          />
        ))}
        <div
          className="border-border group relative flex aspect-square h-20 w-20 cursor-pointer items-center justify-center overflow-hidden rounded-lg border text-neutral-500 transition-colors hover:bg-neutral-800/40 hover:text-neutral-400"
          onClick={() => setShowUploadZone(true)}
        >
          <PlusIcon className="h-6 w-6" />
        </div>
      </>
    );

    facesCount = (
      <p className="text-muted-foreground mt-1 text-sm">
        {person.reference_face_paths.length} reference{" "}
        {person.reference_face_paths.length === 1 ? "face" : "faces"}
      </p>
    );
  }

  return (
    <PersonPhotoDropzone
      className="relative flex h-full flex-grow flex-col"
      onDrop={
        person && !showUploadZone ? uploadFacesForCurrentPerson : () => {}
      }
    >
      <div className="pointer-events-auto flex flex-none flex-col gap-6 px-2 py-4">
        <div className="flex-shrink-0">
          <div className="mb-4 flex items-start gap-6">
            <div className="h-32 w-32">{avatar}</div>
            <div>
              <div className="flex items-center gap-2">{name}</div>
              {facesCount}
            </div>
          </div>
          <div className="flex flex-wrap gap-2">{faces}</div>
          {person && (
            <Dialog
              open={showUploadZone}
              onOpenChange={(open) => {
                setShowUploadZone(open);
                setFacesToUpload([]);
              }}
            >
              <DialogContent width="narrow">
                <DialogHeader className="text-left">
                  <DialogTitle>{`Upload reference images`}</DialogTitle>
                  <DialogDescription>{`Drag and drop images below to be used as references for ${person.name}.`}</DialogDescription>
                </DialogHeader>
                <PersonPhotoUploadZone
                  onDrop={
                    person
                      ? (images) =>
                          setFacesToUpload((prev) => [...prev, ...images])
                      : () => {}
                  }
                />
                <DialogFooter className="items-center justify-between gap-4">
                  <div className="flex w-0 flex-1 justify-start gap-1 overflow-hidden whitespace-nowrap">
                    {facesToUpload.slice(0, 3).map((face) => (
                      <div className="flex items-center gap-1 truncate rounded-sm bg-neutral-800 px-2 py-1 text-xs">
                        <img
                          src={face.preview}
                          className="h-5 w-5 object-cover"
                        />
                        <span className="truncate">{face.file.name}</span>
                      </div>
                    ))}
                    {!!facesToUpload.slice(3).length && (
                      <span className="inline-flex flex-shrink-0 items-center rounded-sm bg-neutral-800 px-2 py-1 text-xs">
                        {`+ ${facesToUpload.slice(2).length} more`}
                      </span>
                    )}
                  </div>
                  <Button
                    disabled={!facesToUpload.length}
                    type="submit"
                    className="flex-shrink-0"
                    onClick={
                      facesToUpload.length
                        ? () => uploadFacesForCurrentPerson(facesToUpload)
                        : () => {}
                    }
                  >
                    Upload photos
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
      <Separator orientation="horizontal" />
      <div className="pointer-events-none flex min-h-0 w-full flex-1 flex-col overflow-hidden">
        <MediaGrid
          gridRef={gridRef}
          onModelUpdated={onGridModelUpdated}
          containerClassName="flex-grow pointer-events-auto"
          className="h-full w-full"
        />
        <Separator orientation="horizontal" />
        <div className="shrink-0 p-2 text-xs text-neutral-500">
          {mediaItemsCount !== null
            ? `${mediaItemsCount} matching media item${mediaItemsCount === 1 ? "" : "s"}`
            : "\u00A0"}
        </div>
      </div>
    </PersonPhotoDropzone>
  );
}
