import React, { Fragment } from "react";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  Separator,
} from "@kino/ui";
import {
  MagnifyingGlassIcon,
  PersonIcon,
  VideoIcon,
} from "@radix-ui/react-icons";
import { Tab } from "@/components/shared/Tab";
import { useRouteNavigation } from "@/hooks/useRouteParamsHistory";
import { BarChart4Icon, ListVideoIcon } from "lucide-react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSyncOrganization } from "@/store/organizationStore";
import { DisplayVariant } from "@/types/display";
import { SURVIVOR } from "@/utils/constants/organizations";
import { NavLink } from "react-router-dom";

type MenuItem = {
  icon: React.ReactNode;
  label: string;
  value: string;
  hidden?: boolean;
};

const MainPanelHeader: React.FC = () => {
  const { handleNavigation, currentPath } = useRouteNavigation();
  const { getEffectiveOrganizationId } = useSyncOrganization();

  useHotkeys("/", () => {
    handleNavigation("search");
  });
  useHotkeys("g", () => {
    handleNavigation("media");
  });

  const menuItems: MenuItem[] = [
    { icon: <VideoIcon />, label: "Media", value: "media" },
    { icon: <MagnifyingGlassIcon />, label: "Search", value: "search" },
    { icon: <PersonIcon />, label: "People", value: "people" },
    {
      icon: <BarChart4Icon />,
      label: "Overview",
      value: "overview",
      hidden: getEffectiveOrganizationId() !== SURVIVOR,
    },
    {
      icon: <ListVideoIcon />,
      label: "Playlists",
      value: "playlists",
    },
  ];

  const filteredMenuItems = menuItems.filter((item) => !item?.hidden);

  return (
    <div className="flex h-9 w-full items-center border-b px-2 py-1">
      <NavigationMenu className="flex w-full max-w-none items-center justify-between">
        <NavigationMenuList className="flex items-center justify-start gap-2 py-1">
          {filteredMenuItems.map(({ icon, label, value }, i) => (
            <Fragment key={value}>
              <NavigationMenuItem asChild>
                <NavLink to={value}>
                  {({ isActive }) => (
                    <Tab
                      icon={icon}
                      label={label}
                      value={value}
                      onClick={() => handleNavigation(value)}
                      isActive={isActive}
                    />
                  )}
                </NavLink>
              </NavigationMenuItem>
              {i !== filteredMenuItems.length - 1 && (
                <Separator
                  orientation="vertical"
                  className="h-3 bg-neutral-700"
                />
              )}
            </Fragment>
          ))}
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

export default MainPanelHeader;
