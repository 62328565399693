import React, { HTMLAttributes, PropsWithChildren, useState } from "react";
import { secondsToTimestamp } from "@/utils/time";
import { useActiveBucketStore } from "@/store/search/activeBucketStore";
import { cn } from "@/utils/tailwind";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";
import { useMediaItemTimecode } from "@/hooks/useMediaItemTimecode";

interface Segment {
  percentage: number;
  resultId: string;
}

interface Marker extends HTMLAttributes<HTMLDivElement> {
  percentage: number;
}

export interface RelativeTimeline {
  segments: Segment[];
  markers: Marker[];
}

interface OmniSearchCardRelativeTimelineProps {
  segments?: Segment[];
  markers?: Marker[];
  duration?: number;
  showTimestamps?: boolean;
  smpteOffset?: number;
  mediaItemId: string;
}

interface SegmentProps {
  percentage: number;
  resultId: string;
  disabled?: boolean;
}

const containerClassName = "flex min-h-5 items-center gap-1 px-2";
const timelineContainerClassName =
  "relative flex h-1.5 w-full overflow-hidden rounded-md bg-gray-800";

const Segment = ({ percentage, resultId, disabled }: SegmentProps) => {
  const { setActiveSegmentId } = useActiveBucketStore();

  return (
    <div
      className={cn(
        "h-1.5",
        disabled
          ? "bg-neutral-800"
          : "cursor-pointer bg-gray-200/60 hover:bg-gray-200",
      )}
      style={{ width: `${percentage}%` }}
      onClick={disabled ? undefined : () => setActiveSegmentId(resultId)}
      onMouseEnter={disabled ? undefined : () => setActiveSegmentId(resultId)}
      onMouseLeave={disabled ? undefined : () => setActiveSegmentId(null)}
    />
  );
};

interface MarkerProps extends React.HTMLAttributes<HTMLDivElement> {
  percentage: number;
  tooltipText?: string;
  isHighlighted?: boolean;
  isActive?: boolean;
  duration?: number;
  mediaItemId: string;
}

const Marker = ({
  percentage,
  isHighlighted,
  isActive,
  tooltipText,
  className,
  mediaItemId,
  ...props
}: MarkerProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const width = Math.min(
    Math.max(1.5, props.duration ? (props.duration / 3600) * 2 : 1.5),
    4,
  );
  const baseClasses =
    "absolute h-1.5 transition-all duration-200 ease-in-out rounded-sm";

  const stateClasses = cn({
    // Active and highlighted
    "z-50 border border-indigo-600 border-opacity-100 bg-indigo-600":
      isActive && isHighlighted,

    // Active but not highlighted
    "border border-indigo-600 border-opacity-100 bg-indigo-600 bg-opacity-30":
      isActive && !isHighlighted,

    // Highlighted but not active
    "z-50 bg-indigo-600": !isActive && isHighlighted,

    // Neither active nor highlighted
    "bg-indigo-300 bg-opacity-10": !isActive && !isHighlighted,
  });

  const timecode = useMediaItemTimecode(
    props.duration ? (props.duration * percentage) / 100 : 0,
    mediaItemId,
  );

  return (
    <TooltipProvider>
      <Tooltip
        delayDuration={0}
        open={isTooltipOpen || isHighlighted}
        onOpenChange={setIsTooltipOpen}
      >
        <TooltipTrigger asChild>
          <div
            className={cn(baseClasses, stateClasses, className)}
            style={{
              left: `calc(${percentage}% - ${width / 2}px)`,
              width: `${width}px`,
            }}
            {...props}
          />
        </TooltipTrigger>
        <TooltipContent>{tooltipText || timecode.toString()}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const Timestamp = ({ children = "--:--:--" }: PropsWithChildren) => (
  <div className="cursor-default select-none whitespace-nowrap text-[10px] font-light leading-none text-neutral-500">
    {children}
  </div>
);

interface TimelineProps {
  segments: Segment[];
  markers: Marker[];
  duration?: number;
  mediaItemId: string;
}

const Timeline = ({
  segments,
  markers,
  duration,
  mediaItemId,
}: TimelineProps) => {
  return (
    <div className="relative flex h-1.5 w-full overflow-hidden rounded-md bg-neutral-800">
      {!segments || segments.length === 0 ? (
        <div className="h-full bg-neutral-800" style={{ width: "100%" }} />
      ) : (
        segments.map((segment) => (
          <Segment
            key={segment.resultId}
            resultId={segment.resultId}
            percentage={segment.percentage}
          />
        ))
      )}
      {markers.map((marker, index) => (
        <Marker
          key={index}
          {...marker}
          duration={duration}
          mediaItemId={mediaItemId}
        />
      ))}
    </div>
  );
};

const NoTimelineState = () => {
  return (
    <div className={containerClassName}>
      <Timestamp />
      <div className={timelineContainerClassName}>
        <Segment percentage={100} disabled resultId="disabled" />
      </div>
      <Timestamp />
    </div>
  );
};

const OmniSearchCardRelativeTimeline: React.FC<
  OmniSearchCardRelativeTimelineProps
> = ({ segments, markers, duration, showTimestamps = true, mediaItemId }) => {
  if (!duration || !segments || !markers) return <NoTimelineState />;

  const startTimecode = useMediaItemTimecode(
    markers[0]?.percentage ? (duration * markers[0].percentage) / 100 : 0,
    mediaItemId,
  );
  const durationTimecode = useMediaItemTimecode(duration, mediaItemId);

  return (
    <div className="flex min-h-5 items-center gap-1 px-2">
      {showTimestamps && markers.length === 1 && (
        <Timestamp>{startTimecode.toString()}</Timestamp>
      )}
      <Timeline
        segments={segments}
        markers={markers}
        duration={duration}
        mediaItemId={mediaItemId}
      />
      {showTimestamps && <Timestamp>{durationTimecode.toString()}</Timestamp>}
    </div>
  );
};

export default OmniSearchCardRelativeTimeline;
