import createFetchClient, { Client } from "openapi-fetch";
import createClient, { OpenapiQueryClient } from "openapi-react-query";
import { useActiveServerStore } from "@/store/activeServerStore";
import { MutationCache, QueryClient } from "@tanstack/react-query";
import { paths } from "@/openapi-bindings/v2";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  }),
});

type ClientReturn = {
  fetchClient: Client<paths, `${string}/${string}`>;
  apiClient: OpenapiQueryClient<paths, `${string}/${string}`>;
  queryClient: QueryClient;
};

export const useClient = (): ClientReturn => {
  const { getEffectiveUrl } = useActiveServerStore();
  const baseUrl = getEffectiveUrl();

  const fetchClient = createFetchClient({ baseUrl });
  const apiClient = createClient<paths>(createFetchClient({ baseUrl }));

  return { fetchClient, apiClient, queryClient };
};
