import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  Button,
  Input,
  Label,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  cn,
  ScrollArea,
} from "@kino/ui";
import { Plus, X } from "lucide-react";
import {
  useForm,
  useFieldArray,
  SubmitHandler,
  type UseFormRegister,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v4 as uuidV4 } from "uuid";
import { z } from "zod";
import { useNavigate } from "react-router";
import { useCreatePerson } from "@/hooks/usePeople";
import { toast } from "sonner";

const personSchema = z.object({
  name: z.string().trim().min(1, { message: "Name is required" }),
  nickname: z.string().trim().optional(),
  id: z.string().trim().optional(),
});

const formSchema = z.object({
  persons: z.array(personSchema).min(1, { message: "Add at least one person" }),
});

type PersonFormValues = z.infer<typeof formSchema>;

export const AddPersonDialog = forwardRef<
  { setOpen: (open: boolean) => void },
  { triggerButton: boolean }
>(({ triggerButton = true }, ref) => {
  const createPerson = useCreatePerson();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    setOpen,
  }));
  const [personOpen, setPersonOpen] =
    useState<`persons.${number}`>("persons.0");

  const measurementTabRef = useRef<HTMLButtonElement>(null);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<PersonFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { persons: [{ name: "", nickname: "", id: "" }] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "persons",
  });

  const onSubmit: SubmitHandler<PersonFormValues> = (data) => {
    data.persons.forEach((person) => {
      createPerson(
        {
          ...person,
          id: person.id || uuidV4(),
          reference_face_paths: [],
        },
        {
          onSuccess: () =>
            toast.success(`Created ${person.name}`, {
              action: {
                label: "Go to page",
                onClick: () =>
                  navigate(`/people/${person.id}?person_ids=${person.id}`),
              },
            }),
          onError: () => toast.error(`Failed to create ${person.name}`),
        },
      );
    });

    setOpen(false);

    reset();
  };

  const handleAddPerson = () => {
    append({ name: "", nickname: "", id: "" });
  };

  useEffect(() => {
    setPersonOpen(`persons.${fields.length - 1}`);
  }, [fields.length]);

  const scrollToRightEdge = useCallback(() => {
    if (!measurementTabRef.current) {
      return;
    }

    measurementTabRef.current?.scrollIntoView();
  }, [measurementTabRef]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {triggerButton && (
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="text-muted-foreground hover:text-foreground absolute right-4 top-4"
          >
            <Plus className="h-5 w-5" />
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Add New People</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="min-w-0">
          <Tabs
            value={personOpen}
            onValueChange={(person) =>
              setPersonOpen(person as `persons.${number}`)
            }
          >
            <ScrollArea orientation="horizontal">
              <TabsList className="pb-0">
                {fields.map((field, index) => (
                  <TabsTrigger
                    key={field.id}
                    value={`persons.${index}`}
                    className={cn(
                      "relative h-9 rounded-none rounded-t-md border border-b-0",
                      "data-[state='inactive']:bg-neutral-800",
                      index > 0 && "border-l",
                    )}
                  >
                    {watch(`persons.${index}.name`) || "New Person"}
                    {errors?.persons?.[index] && (
                      <span className="mx-2 h-1 w-1 rounded-full bg-red-500 text-xs" />
                    )}
                    {fields.length > 1 && (
                      <Button
                        variant="ghost"
                        size="xsmCircle"
                        className="ml-2"
                        onClick={() => {
                          setPersonOpen(`persons.${index ? index - 1 : 1}`);
                          remove(index);
                        }}
                      >
                        <X size={10} />
                      </Button>
                    )}
                  </TabsTrigger>
                ))}
                <Button
                  className="h-9 rounded-none rounded-t-md border border-b-0 border-neutral-900 bg-neutral-800 px-3 py-1.5"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddPerson();
                    scrollToRightEdge();
                  }}
                >
                  <Plus size={10} />
                </Button>
                <TabsTrigger
                  value={`persons.measurement`}
                  className={cn(
                    "invisible relative h-9 rounded-none rounded-t-md border border-b-0",
                  )}
                  ref={measurementTabRef}
                >
                  {"New Person"}
                  {fields.length > 1 && (
                    <Button variant="ghost" size="xsmCircle" className="ml-2">
                      <X size={10} />
                    </Button>
                  )}
                </TabsTrigger>
              </TabsList>
            </ScrollArea>
            {fields.map((field, index) => (
              <TabsContent
                key={field.id}
                value={`persons.${index}`}
                className=" mt-0"
              >
                <div
                  key={field.id}
                  className="relative mb-4 rounded border p-4"
                >
                  <PersonInputs
                    index={index}
                    register={register}
                    error={
                      errors.persons?.[index] as PersonInputsProps["error"]
                    }
                  />
                </div>
              </TabsContent>
            ))}
          </Tabs>
          <DialogFooter className="mt-4">
            <Button type="submit">Create People</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
});

AddPersonDialog.displayName = "AddPersonDialog";

interface PersonInputsProps {
  index: number;
  register: UseFormRegister<PersonFormValues>;
  error?: {
    name?: { message?: string };
  };
}
const PersonInputs: React.FC<PersonInputsProps> = ({
  index,
  register,
  error,
}) => (
  <div className="flex flex-col gap-4">
    <div className="space-y-2">
      <Label htmlFor={`persons.${index}.name`}>Name</Label>
      <Input
        id={`persons.${index}.name`}
        {...register(`persons.${index}.name` as const)}
        placeholder="Enter name"
        autoFocus
      />
      <p className="mt-1 text-xs text-red-500">{error?.name?.message}</p>
    </div>
    <div className="space-y-2">
      <Label htmlFor={`persons.${index}.nickname`}>
        Nickname <span className="text-muted-foreground">(optional)</span>
      </Label>
      <Input
        id={`persons.${index}.nickname`}
        {...register(`persons.${index}.nickname` as const)}
        placeholder="Enter nickname"
      />
    </div>
    <div className="space-y-2">
      <Label htmlFor={`persons.${index}.id`}>
        ID <span className="text-muted-foreground">(optional)</span>
      </Label>
      <Input
        id={`persons.${index}.id`}
        {...register(`persons.${index}.id` as const)}
        placeholder="Enter ID"
      />
    </div>
  </div>
);
