import { components } from "@/openapi-bindings/v2";
import { ViewType } from "./search";

export type DisplayVariant = "media" | "search" | "playlist" | "overview";

export type DisplayMode =
  | "default"
  | "news"
  | "unscripted"
  | "scripted"
  | "s48"
  | "bs2";

export enum FileNameDisplaySource {
  UniqueClip = "UniqueClip",
  Path = "Path"
}

export interface DisplayModeConfig {
  allowedViewTypes: ViewType[];
  propertyVisibility: Record<string, boolean>;
  groupingEnabled: boolean;
  fileNameDisplaySource: FileNameDisplaySource;
}

export interface DisplayVariantConfig {
  allowViewTypeToggle: boolean;
  allowPropertyVisibility: boolean;
  allowGrouping: boolean;
  availableModes: DisplayMode[];
  defaultMode: DisplayMode;
}

export const DISPLAY_VARIANT_CONFIGS: Record<
  DisplayVariant,
  DisplayVariantConfig
> = {
  media: {
    allowViewTypeToggle: false,
    allowPropertyVisibility: false,
    allowGrouping: false,
    availableModes: ["default", "news", "unscripted", "scripted", "s48", "bs2"],
    defaultMode: "default",
  },
  search: {
    allowViewTypeToggle: true,
    allowPropertyVisibility: true,
    allowGrouping: true,
    availableModes: ["default"],
    defaultMode: "default",
  },
  playlist: {
    allowViewTypeToggle: true,
    allowPropertyVisibility: true,
    allowGrouping: false,
    availableModes: ["default"],
    defaultMode: "default",
  },
  overview: {
    allowViewTypeToggle: false,
    allowPropertyVisibility: false,
    allowGrouping: false,
    availableModes: ["default", "news", "unscripted", "scripted", "s48", "bs2"],
    defaultMode: "default",
  },
};

// TODO: MAKE THIS ENTIRELY DEFINED BY USER-MADE CONFIG STORED IN DATABASE
export const DISPLAY_MODE_CONFIGS: Record<DisplayMode, DisplayModeConfig> = {
  default: {
    allowedViewTypes: [ViewType.List],
    propertyVisibility: {
      thumbnail: true,
      title: true,
      duration: true,
      timeline: true,
      tags: true,
      people: true,
    },
    groupingEnabled: true,
    fileNameDisplaySource: FileNameDisplaySource.Path
  },
  news: {
    allowedViewTypes: [ViewType.List],
    propertyVisibility: {
      thumbnail: true,
      title: true,
      description: true,
      assetType: true,
      eventName: true,
      location: true,
    },
    groupingEnabled: false,
    fileNameDisplaySource: FileNameDisplaySource.Path
  },
  unscripted: {
    allowedViewTypes: [ViewType.List],
    propertyVisibility: {
      thumbnail: true,
      filename: true,
      duration: true,
      people: true,
    },
    groupingEnabled: false,
    fileNameDisplaySource: FileNameDisplaySource.Path
  },
  scripted: {
    allowedViewTypes: [ViewType.List],
    propertyVisibility: {
      thumbnail: true,
      filename: true,
      hasMulticam: true,
      dateShot: true,
      shot: true,
      scene: true,
      cameraIdentifier: true,
    },
    groupingEnabled: false,
    fileNameDisplaySource: FileNameDisplaySource.Path
  },
  s48: {
    allowedViewTypes: [ViewType.List],
    propertyVisibility: {
      thumbnail: true,
      filename: true,
      people: true,
      tags: true,
      duration: true,
      media_id: true,
    },
    groupingEnabled: false,
    fileNameDisplaySource: FileNameDisplaySource.UniqueClip
  },
  bs2: {
    allowedViewTypes: [ViewType.List],
    propertyVisibility: {
      thumbnail: true,
      filename: true,
      people: true,
      tags: true,
      duration: true,
      media_id: true,
    },
    groupingEnabled: false,
    fileNameDisplaySource: FileNameDisplaySource.Path
  },
};
