export const formatStringPlurality = (
  num: number,
  singular: string,
  plural: string,
) => {
  return num === 1 ? singular : plural;
};

export const getServerUrl = (
  serverAddress: string,
  serverPort: string,
  useHttps: boolean,
) => {
  const protocol = useHttps ? "https" : "http";
  return `${protocol}://${serverAddress}:${serverPort}/`;
};

export const serverUrlToFormValues = (url: string) => {
  try {
    // Try to parse with protocol if not provided
    let urlToParse = url.trim();
    if (
      !urlToParse.startsWith("http://") &&
      !urlToParse.startsWith("https://")
    ) {
      urlToParse = `http://${urlToParse}`;
    }

    const parsedUrl = new URL(urlToParse);
    return {
      ip: parsedUrl.hostname,
      port: parsedUrl.port || "1923", // Default port if not specified
      useHttps: parsedUrl.protocol === "https:",
    };
  } catch (error) {
    // If URL parsing fails, try to parse as hostname:port
    const [hostname, port] = url.trim().split(":");
    return {
      ip: hostname || url.trim(),
      port: port?.trim() || "1923",
      useHttps: true,
    };
  }
};

export const getInspectorItemId = (mediaItemId: string, momentId?: string) => {
  return momentId ? `${mediaItemId}-${momentId}` : mediaItemId;
};

export const getInspectorItemIdsFromInspectorItemId = (
  inspectorItemId: string,
) => {
  const parts = inspectorItemId.split("-");
  return {
    mediaItemId: parts[0],
    momentId: parts.length > 1 ? parts[1] : undefined,
  };
};

export const getPersonNameInitials = (personName: string) => {
  return personName
    .split(" ")
    .map((word) => word[0])
    .join("")
    .substring(0, 2)
    .toUpperCase();
};

export const capitalizeWords = (name: string) => {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
