import { ViewType } from "@/types/search";
import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";
import { devtools, persist } from "zustand/middleware";
import {
  DisplayMode,
  DisplayVariant,
  DISPLAY_MODE_CONFIGS,
  FileNameDisplaySource,
} from "@/types/display";

interface DisplayState {
  variant: DisplayVariant;
  mode: DisplayMode;
  modeOverride: DisplayMode | null;
  viewType: ViewType;
  groupingKey: string;
  propertyVisibility: Record<string, boolean>;
  fileNameDisplaySource: FileNameDisplaySource;
}

interface DisplayActions {
  setVariant: (variant: DisplayVariant) => void;
  setMode: (mode: DisplayMode) => void;
  setViewType: (viewType: ViewType) => void;
  setGroupingKey: (key: string) => void;
  togglePropertyVisibility: (property: string) => void;
  resetState: () => void;
  setModeOverride: (mode: DisplayMode | null) => void;
}

type DisplayStore = DisplayState & DisplayActions;

const getInitialState = (
  variant: DisplayVariant,
  mode: DisplayMode,
): DisplayState => {
  if (!mode || !DISPLAY_MODE_CONFIGS[mode]) {
    console.warn(`Invalid display mode: ${mode}, falling back to default`);
    mode = "default";
  }
  const modeConfig = DISPLAY_MODE_CONFIGS[mode];
  const defaultViewType = modeConfig.allowedViewTypes[0];
  if (!defaultViewType) {
    throw new Error(`No allowed view types for mode: ${mode}`);
  }

  return {
    variant,
    mode,
    viewType: defaultViewType,
    groupingKey: "none",
    propertyVisibility: modeConfig.propertyVisibility,
    fileNameDisplaySource: modeConfig.fileNameDisplaySource,
    modeOverride: null,
  };
};

export const useDisplayStore = createClearable([
  StoreCollectionIdentifier.DISPLAY,
])<DisplayStore>()(
  devtools(
    persist(
      (set) => ({
        ...getInitialState("media", "default"),

        setVariant: (variant) => {
          const newState = getInitialState(variant, "default");
          set(newState);
        },

        setMode: (mode) => {
          if (!mode || !DISPLAY_MODE_CONFIGS[mode]) {
            console.warn(
              `Invalid display mode: ${mode}, falling back to default`,
            );
            mode = "default";
          }
          const modeConfig = DISPLAY_MODE_CONFIGS[mode];
          const defaultViewType = modeConfig.allowedViewTypes[0];
          if (!defaultViewType) {
            throw new Error(`No allowed view types for mode: ${mode}`);
          }

          set((state) => ({
            mode,
            viewType: defaultViewType,
            propertyVisibility: modeConfig.propertyVisibility,
            fileNameDisplaySource: modeConfig.fileNameDisplaySource,
            groupingKey: modeConfig.groupingEnabled
              ? state.groupingKey
              : "none",
          }));
        },

        setViewType: (viewType: ViewType) => {
          set({ viewType });
        },

        setGroupingKey: (key) => {
          set({ groupingKey: key });
        },

        togglePropertyVisibility: (property) => {
          set((state) => ({
            propertyVisibility: {
              ...state.propertyVisibility,
              [property]: !state.propertyVisibility[property],
            },
          }));
        },

        resetState: () =>
          set((state) => getInitialState(state.variant, "default")),

        setModeOverride: (modeOverride) => {
          if (modeOverride) {
            if (!DISPLAY_MODE_CONFIGS[modeOverride]) {
              console.warn(
                `Invalid display mode override: ${modeOverride}, falling back to default`,
              );
              modeOverride = "default";
            }
            const modeConfig = DISPLAY_MODE_CONFIGS[modeOverride];
            set({
              modeOverride,
              viewType: modeConfig.allowedViewTypes[0],
              propertyVisibility: modeConfig.propertyVisibility,
              fileNameDisplaySource: modeConfig.fileNameDisplaySource
            });
          } else {
            set((state) => {
              const mode = state.mode || "default";
              const baseConfig = DISPLAY_MODE_CONFIGS[mode];
              return {
                modeOverride: null,
                viewType: baseConfig.allowedViewTypes[0],
                propertyVisibility: baseConfig.propertyVisibility,
                fileNameDisplaySource: baseConfig.fileNameDisplaySource
              };
            });
          }
        },
      }),
      {
        name: "display-storage",
        version: 3,
        migrate: (persisted, version) => {
          if (version < 2) {
            const persistedState = persisted as DisplayStateV1 | undefined;

            return getInitialState("media", (persistedState?.mode as DisplayMode | undefined) || "default");
          }
          
          const persistedState = persisted as DisplayStateV2
          
          return {
            ...persistedState,
            fileNameDisplaySource: DISPLAY_MODE_CONFIGS[persistedState.mode].fileNameDisplaySource
          }
        }
      },
    ),
    { name: "DisplayStore" },
  ),
);

type DisplayStateV1 = Omit<DisplayState, "fileNameDisplaySource">;
type DisplayStateV2 = Omit<DisplayState, "fileNameDisplaySource">;

export const displayStore = useDisplayStore.getState;

export const getEffectiveMode = (state: DisplayState) =>
  state.modeOverride ?? state.mode;
