import { useCurrentZoom } from "../../context/ZoomControllerContext";
import { usePreviewTime } from "../../context/PreviewTimeContext";
import { cn } from "../../utils/cn";
import { Thumbnail, ThumbnailProps } from "@vidstack/react";
import { forwardRef } from "react";

export const Thumbnails = forwardRef<HTMLElement, ThumbnailProps>(
  ({ src, time = 0, className, ...props }, ref) => (
    <Thumbnail.Root
      src={src}
      time={time}
      className={cn(
        "h-[var(--thumbnail-height)] max-h-[160px] min-h-[80px] w-[var(--thumbnail-width)] min-w-[120px] max-w-[180px] overflow-hidden border border-white bg-black data-[hidden]:hidden",
        className,
      )}
      {...props}
      ref={ref}
    >
      <Thumbnail.Img />
    </Thumbnail.Root>
  ),
);

export const PreviewThumbnails = ({
  className,
  ...props
}: Omit<ThumbnailProps, "time">) => {
  const { start, end } = useCurrentZoom();
  const { previewValue, previewRef, isPreviewVisible } = usePreviewTime();
  const previewTime = start + (previewValue / 100) * (end - start);

  if (!isPreviewVisible) {
    return null;
  }

  return (
    <Thumbnails
      {...props}
      time={previewTime}
      className={cn(className, "absolute")}
      ref={previewRef}
    />
  );
};
