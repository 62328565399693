import { type PanelProps } from "react-resizable-panels";
import {
  Player,
  KinoButtons,
  KinoSliders,
  KinoTimes,
  KinoThumbnails,
  PlayerMoment,
  isHTMLVideoElement,
  LocalMediaStorageWithoutTime,
  MediaLoadedMetadataEvent,
  usePrimaryViewportContext,
  Menu,
  useMediaRemote,
  useMediaStore,
  PlayerMomentType,
} from "@kino/player";
import { toast } from "sonner";

import { components } from "@/openapi-bindings/v2";
import React, { SVGProps, useState, useMemo, useCallback } from "react";
import {
  TooltipContent,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import PanelHeader from "@/layouts/panels/PanelHeader";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { cn } from "@/utils/tailwind";
import { CheckIcon } from "@radix-ui/react-icons";
import { Mic2 } from "lucide-react";
import { MetadataA24, QUAD_BOX_PATH } from "@/utils/multicam";
import { useGetMediaComments } from "@/hooks/useGetMediaComments";
import { useDebuggerStore } from "@/store/debuggerStore";
import { AddToPlaylistSubContextMenu } from "../playlists/AddToPlaylistSubContextMenu";
import { ViewportPanelPlaylistHeader } from "./ViewportPanelPlaylistHeader";
import { MomentId } from "@/store/playlists/playlistsStore";
import { usePlaylistAutoplay } from "@/hooks/usePlaylistAutoplay";
import { usePlaylists } from "@/hooks/usePlaylists";
import { useFormatTimecodeForMediaItem } from "@/hooks/useMediaItemTimecode";
import { useMediaItemDisplayFileName } from "@/utils/mediaItem";

const storage = new LocalMediaStorageWithoutTime();

// Memoize empty state component
const ViewportPanelEmptyState = React.memo(() => (
  <div className="flex h-full w-full items-center justify-center text-xs text-neutral-700">
    No Media
  </div>
));

// Memoize multiple media component
const ViewportPanelMultipleMedia = React.memo(
  ({ numSelectedMedia }: { numSelectedMedia: number }) => (
    <div className="flex h-64 w-full items-center justify-center text-xs text-neutral-700">
      {numSelectedMedia} selected
    </div>
  ),
);

// Memoize the quad box SVG
export const QuadBoxSVG = React.memo(
  ({ ...props }: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 13 13" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={QUAD_BOX_PATH}
        fill="currentColor"
      />
    </svg>
  ),
);

interface AudioTrack {
  id: string;
  label: string;
  selected: boolean;
}

// Memoize the player controls
const PlayerControls = React.memo(
  ({
    remote,
    audioTracks,
    multicam,
    isQuadBoxActive,
    onMulticam,
    onAudioTrackChange,
  }: {
    remote: ReturnType<typeof useMediaRemote>;
    audioTracks: AudioTrack[];
    multicam: boolean;
    isQuadBoxActive: boolean;
    onMulticam: () => void;
    onAudioTrackChange: (index: number) => void;
  }) => (
    <div className="flex w-full items-center gap-2 border-b bg-neutral-900 p-2">
      <TooltipProvider>
        <KinoButtons.Play />
        <div className="no-scrollbar flex shrink items-center gap-2 overflow-x-scroll">
          <KinoButtons.Generic
            disabled={!multicam}
            tooltipContent="Multicam"
            className={cn(
              "active:text-yellow-500",
              isQuadBoxActive && "text-yellow-400",
            )}
            onClick={multicam ? onMulticam : () => {}}
          >
            <QuadBoxSVG className="h-4 w-4" />
          </KinoButtons.Generic>
          {isQuadBoxActive && audioTracks && (
            <Menu.Root>
              <Menu.Button>
                <KinoButtons.Generic>
                  <Mic2 />
                </KinoButtons.Generic>
              </Menu.Button>
              <Menu.Items
                className="animate-out fade-out slide-out-to-bottom-2 data-[open]:animate-in data-[open]:fade-in data-[open]:slide-in-from-bottom-4 flex h-[var(--menu-height)] max-h-[400px] min-w-[260px] flex-col overflow-y-auto overscroll-y-contain rounded-md border border-white/10 bg-black/95 p-2.5 font-sans text-[15px] font-medium outline-none backdrop-blur-sm transition-[height] duration-300 will-change-[height] data-[resizing]:overflow-hidden"
                placement="top"
                offset={0}
              >
                {audioTracks.map(({ id, label, selected }, i) => (
                  <Menu.Item onClick={() => onAudioTrackChange(i)} key={id}>
                    <span className="h-2 w-2">{selected && <CheckIcon />}</span>
                    {label}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu.Root>
          )}
          <KinoButtons.ClipIn />
          <KinoButtons.ClipOut />
          <KinoButtons.LoopClip />
          <KinoButtons.JumpToClipIn />
          <KinoButtons.JumpToClipOut />
          <KinoButtons.ZoomToMoment />
        </div>
        <span className="grow" />
        <KinoTimes.Times />
        <KinoButtons.Fullscreen />
      </TooltipProvider>
    </div>
  ),
);

// Memoize the header content
const ViewportPanelHeader = React.memo(({ title }: { title: string }) => (
  <PanelHeader className="flex items-center justify-between gap-2">
    <div className="flex items-center gap-1">Viewport</div>
    <span
      className="cursor-pointer truncate text-ellipsis text-xs text-neutral-400"
      onClick={() => {
        navigator.clipboard.writeText(title);
        toast.success("Copied to clipboard");
      }}
    >
      {title}
    </span>
  </PanelHeader>
));

export const ViewportPanel: React.FC<PanelProps> = React.memo(() => {
  const { playerRef } = usePrimaryViewportContext();
  const remote = useMediaRemote(playerRef);

  const { audioTracks, error } = useMediaStore(playerRef);

  const { selectedMedia } = useSelectedMediaStore((state) => ({
    selectedMedia: state.currentSelection ?? [],
  }));
  const playlistId = useSelectedMediaStore(
    (state) => state.currentSelectedPlaylistId,
  );
  const { data: playlistResponse } = usePlaylists();
  const playlists = playlistResponse?.playlists;
  const playlist = useMemo(
    () =>
      playlistId ? playlists?.find(({ id }) => id === playlistId) : undefined,
    [playlistId, playlists],
  );

  const { buildServerUrl } = useActiveServerStore();
  const { showComments } = useDebuggerStore((state) => ({
    showComments: state.showComments,
  }));
  const numSelectedMedia = selectedMedia.length;
  const singleSelection = useMemo(
    () => (numSelectedMedia === 1 ? selectedMedia[0] : undefined),
    [numSelectedMedia, selectedMedia],
  );

  const [startTime, setStartTime] = useState(0);
  const [isQuadBoxActive, setIsQuadBoxActive] = useState(false);

  const { mediaItem, activeInspectorMoment } = singleSelection ?? {};
  const metadata = useMemo(
    () => (mediaItem?.metadata as unknown as MetadataA24) ?? undefined,
    [mediaItem?.metadata],
  );
  const moment = activeInspectorMoment?.moment;
  const formatTimeToTimecode = useFormatTimecodeForMediaItem(mediaItem?.id);
  const formatTimeToString = useCallback(
    (time: number) => formatTimeToTimecode(time).toString(),
    [formatTimeToTimecode],
  );

  const { onPlaylistItemEnd } = usePlaylistAutoplay({
    playlist,
    momentId: moment?.id as MomentId,
  });

  const { data: comments } = useGetMediaComments(
    mediaItem?.ffprobe_data.duration,
  );

  const onLoadedMetadata = (nativeEvent: MediaLoadedMetadataEvent) => {
    const target = nativeEvent.trigger?.target;

    if (isHTMLVideoElement(target)) {
      const element = target;
      element.currentTime = startTime || moment?.start || 0;
    }
  };

  const onMulticam = () => {
    const currentTime = playerRef.current?.currentTime ?? 0;
    const cameras = metadata?.raw_metadata?.multicam_group?.cameras;

    if (!cameras) return;

    if (isQuadBoxActive) {
      const targetCameraOffset =
        Object.entries(cameras).find(
          ([_, camera]) => camera.media_id === mediaItem?.id,
        )?.[1]?.offset ?? 0;

      const adjustedTime = Math.max(0, currentTime - targetCameraOffset);
      setStartTime(adjustedTime);
    } else {
      const currentCameraOffset =
        Object.entries(cameras).find(
          ([_, camera]) => camera.media_id === mediaItem?.id,
        )?.[1]?.offset ?? 0;

      const adjustedTime = currentTime + currentCameraOffset;
      setStartTime(adjustedTime);
    }

    setIsQuadBoxActive((prev) => !prev);
  };

  const multicamGroup = metadata?.raw_metadata?.multicam_group;
  const multicam = metadata?.raw_metadata?.multicam_group !== undefined;
  const multicamManifestPath =
    metadata?.raw_metadata?.multicam_group?.prerendered_hls_manifest;
  const srcFile = useMemo(
    () =>
      multicam && isQuadBoxActive
        ? buildServerUrl(multicamManifestPath ?? "")
        : buildServerUrl(mediaItem?.hls_manifest_path ?? ""),
    [
      multicam,
      isQuadBoxActive,
      buildServerUrl,
      multicamManifestPath,
      mediaItem?.hls_manifest_path,
    ],
  );

  const handleAudioTrackChange = useCallback(
    (index: number) => remote.changeAudioTrack(index),
    [remote],
  );

  const fileName = useMediaItemDisplayFileName(mediaItem, "");

  return (
    <div className="flex h-full flex-col">
      {playlist && moment && (
        <ViewportPanelPlaylistHeader
          playlistId={playlist.id}
          playlistMoment={{
            moment_id: moment.id,
            media_item_id: moment.media_item_id,
            start_time: moment.start,
            end_time: moment.end,
            position: 0,
          }}
        />
      )}
      <ViewportPanelHeader title={fileName} />
      {numSelectedMedia === 0 && <ViewportPanelEmptyState />}
      {numSelectedMedia > 1 && (
        <ViewportPanelMultipleMedia numSelectedMedia={numSelectedMedia} />
      )}
      {selectedMedia && singleSelection && srcFile && (
        <Player
          key={`search-result-panel-player-${singleSelection.id}`}
          storage={storage}
          ref={playerRef}
          src={srcFile}
          useDefaultControls={false}
          className="flex aspect-auto h-full min-h-64 w-full flex-col items-center justify-center bg-black"
          currentTime={startTime || moment?.start}
          onEnd={playlist && onPlaylistItemEnd}
          clipInTime={playlist ? moment?.start : undefined}
          clipOutTime={playlist ? moment?.end : undefined}
          loopClip={!!playlist}
          duration={
            Number(mediaItem?.ffprobe_data?.format.duration) ?? undefined
          }
          onLoadedMetadata={onLoadedMetadata}
          frameRate={
            mediaItem?.ffprobe_data && [
              mediaItem.ffprobe_data.framerate_numerator ?? 30000,
              mediaItem.ffprobe_data.framerate_denominator ?? 1001,
            ]
          }
          zoom={
            moment?.start != null && moment?.end != null
              ? {
                  start: Math.max(moment.start - 1, 0),
                  end: Math.min(
                    moment.end + 1,
                    Number(mediaItem?.ffprobe_data?.format.duration),
                  ),
                }
              : undefined
          }
          shouldZoomTrackCurrentTime
          formatTime={formatTimeToString}
          customControls={
            <div className="relative flex w-full flex-col border-b">
              <PlayerControls
                remote={remote}
                audioTracks={audioTracks}
                multicam={multicam}
                isQuadBoxActive={isQuadBoxActive}
                onMulticam={onMulticam}
                onAudioTrackChange={handleAudioTrackChange}
              />
              <KinoThumbnails.PreviewThumbnails
                src={null}
                className="absolute bottom-full scale-75"
              />
              <div className="flex w-full border">
                {!error && isQuadBoxActive && (
                  <KinoSliders.MulticamTimelineSidebar
                    multicamGroup={multicamGroup}
                  />
                )}
                <div className="flex w-full flex-col border-b">
                  <TooltipProvider>
                    <KinoSliders.TimeWithMoments
                      className=""
                      clipContextMenuItems={(moment) => (
                        <AddToPlaylistSubContextMenu
                          disabled={!(moment.startTime && moment.endTime)}
                          label="Add clip to playlist"
                          playlistMoment={{
                            moment_id: moment.id ?? "",
                            media_item_id: mediaItem?.id ?? "",
                            start_time: moment.startTime,
                            end_time: moment.endTime,
                            position: 0,
                          }}
                        />
                      )}
                    >
                      {isQuadBoxActive && (
                        <KinoSliders.MulticamTimelines
                          className="border-t"
                          multicamGroup={multicamGroup}
                        />
                      )}
                      {showComments && comments && (
                        <KinoSliders.CommentTimeline comments={comments} />
                      )}
                      <KinoSliders.Moments
                        className="border-b"
                        momentContextMenuItems={(moment) => (
                          <AddToPlaylistSubContextMenu
                            playlistMoment={{
                              moment_id: moment.id ?? "",
                              media_item_id: mediaItem?.id ?? "",
                              start_time: moment.startTime,
                              end_time: moment.endTime,
                              position: 0,
                            }}
                          />
                        )}
                      />
                    </KinoSliders.TimeWithMoments>
                  </TooltipProvider>
                  <KinoSliders.Window withMiniMap />
                  {moment?.start != null && moment?.end != null && (
                    <PlayerMoment
                      startTime={moment.start}
                      endTime={moment.end}
                      type={PlayerMomentType.Video}
                      id={moment.id}
                    />
                  )}
                </div>
              </div>
            </div>
          }
          autoPlay
        />
      )}
    </div>
  );
});

ViewportPanel.displayName = "ViewportPanel";
