import { Button } from "@kino/ui";
import { usePeople } from "@/hooks/usePeople";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "@/layouts/Loading";
import { useUnassignedImagesStore } from "@/store/unassignedImagesStore";
import { Plus, TriangleAlert, UserCircle } from "lucide-react";
import { AddPersonDialog } from "@/components/people/AddPersonDialog";

const PeopleIndex = () => {
  const navigate = useNavigate();
  const peopleQuery = usePeople();
  const hasUnassignedImages = useUnassignedImagesStore(
    (state) => state.images.length > 0,
  );
  const dialogRef = useRef<{ setOpen: (open: boolean) => void }>(null);

  useEffect(() => {
    if (hasUnassignedImages) {
      navigate("/people/unassigned");
      return;
    }

    if (peopleQuery.status !== "success") {
      return;
    }

    if (!peopleQuery.data[0]) {
      return;
    }

    navigate(`/people/${peopleQuery.data[0]?.id}`, { replace: true });
  }, [hasUnassignedImages, peopleQuery.status, peopleQuery.data?.[0]]);

  if (peopleQuery.status === "pending") {
    return <Loading />;
  }

  if (peopleQuery.status === "error") {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-4">
        <TriangleAlert absoluteStrokeWidth size={64} className="text-red-400" />
        <p>There was an error loading people</p>
      </div>
    );
  }

  if (peopleQuery.data.length > 0) {
    return <Loading />;
  }

  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <UserCircle absoluteStrokeWidth size={64} className="text-neutral-400" />
      <h1 className="text-xl text-neutral-50">No people added yet</h1>
      <p className="text-sm text-neutral-500">
        Create a person to start finding media that contains them
      </p>
      <Button variant="kino" onClick={() => dialogRef.current?.setOpen(true)}>
        <Plus className="h-4 w-4" />
        Create a person
      </Button>
      <AddPersonDialog triggerButton={false} ref={dialogRef} />
    </div>
  );
};

export default PeopleIndex;
