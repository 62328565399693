import { Popover, PopoverContent, PopoverTrigger } from "@kino/ui";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";
import { Avatar, AvatarImage, AvatarFallback } from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { capitalizeWords } from "@/utils/stringUtils";

interface PersonPopoverProps {
  children: React.ReactNode;
  personId: string;
}

export const PersonPopover = ({ children, personId }: PersonPopoverProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<number>();
  const { buildServerUrl } = useActiveServerStore();

  const { data: persons } = useGetPeopleQuery();
  const person = persons?.find(p => p.id === personId);

  const handleMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
    }, 50); // Small delay before closing
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  return (
    <Popover open={isOpen}>
      <PopoverTrigger 
        asChild
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent 
        className="w-48 p-2"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        side="right"
        sideOffset={8}
        avoidCollisions={true}
      >
        {person && (
          <div className="mb-2 p-2">
            <div className="flex items-center gap-2">
              <Avatar className="h-8 w-8">
                <AvatarImage src={buildServerUrl(person.thumbnail_path || "")} />
                <AvatarFallback>{person.name[0]}</AvatarFallback>
              </Avatar>
              <div>
                <h3 className="font-medium">{capitalizeWords(person.name)}</h3>
              </div>
            </div>
          </div>
        )}
        <button
          className="w-full flex flex-row justify-between rounded-md p-2 text-xs text-left hover:bg-neutral-800"
          onClick={() => navigate(`/people?person_ids=${personId}`)}
        >
          View Person Details <ArrowRightIcon className="w-4 h-4" />
        </button>
      </PopoverContent>
    </Popover>
  );
}; 