import { useSyncOrganization } from "@/store/organizationStore";
import { useUser } from "@clerk/clerk-react";

export const useCanAccessDevFeatures = () => {
  const { user } = useUser();
  const { getEffectiveOrganizationSlug } = useSyncOrganization();

  return (
    import.meta.env.DEV ||
    import.meta.env.VITE_VERCEL_ENV === "preview" ||
    getEffectiveOrganizationSlug() === "kino-ai" ||
    user?.emailAddresses.some(
      (email) =>
        email.emailAddress.toLowerCase().endsWith("@trykino.com") ||
        email.emailAddress.toLowerCase().endsWith("@kino.ai"),
    )
  );
};
