import {
  createContext,
  useContext,
} from "react";

type IPreviewTimeContext = {
    previewRootRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    previewRef:  React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    previewValue: number;
    isPreviewVisible: boolean;
}

const previewTimeContextDefault: IPreviewTimeContext = {
    previewRef: () => {},
    previewRootRef: () => {},
    isPreviewVisible: false,
    previewValue: 0,
};

export const PreviewTimeContext = createContext<IPreviewTimeContext>(
  previewTimeContextDefault,
);

export const usePreviewTime = (): IPreviewTimeContext => {
    const context = useContext(PreviewTimeContext);

    if (!context) {
      throw new Error("usePreviewTime must be used within a PreviewTimeProvider");
    }

    return context;
}
