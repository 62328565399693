import { useClipMoment } from "../../context/ClipMomentsContext";
import { Tooltip, TooltipTrigger, TooltipContent, cn } from "@kino/ui";
import {
  FullscreenButton,
  MuteButton,
  PIPButton,
  PlayButton,
  useMediaRemote,
  useMediaState,
} from "@vidstack/react";
import {
  PlayIcon,
  PauseIcon,
  MuteIcon,
  VolumeHighIcon,
  VolumeLowIcon,
  PictureInPictureIcon,
  PictureInPictureExitIcon,
  FullscreenIcon,
  FullscreenExitIcon,
  RepeatSquareIcon,
  RepeatSquareOnIcon,
  ReplayIcon,
} from "@vidstack/react/icons";
import { usePlayerZoomController } from "../../context/ZoomControllerContext";
import { usePlayerMoments } from "../../context/PlayerMomentsContext";
import JumpToMomentIcon from "../icons/JumpToMoment";
import ClipInIcon from "../icons/ClipIn";
import ClipOutIcon from "../icons/ClipOut";
import JumpToClipInIcon from "../icons/JumpToClipIn";
import JumpToClipOutIcon from "../icons/JumpToClipOut";
import { MOMENT_BUFFER_SECONDS } from "../../constants";
import { ButtonHTMLAttributes } from "react";

const TOOLTIP_OFFSET = 5;

const KINO_BUTTON_CLASSES =
  "ring-media-focus group relative inline-flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center border border-neutral-800 bg-transparent p-1 text-white outline-none ring-inset hover:bg-neutral-800/50 focus-visible:ring-4 active:text-indigo-600 disabled:active:text-white/40 disabled:text-white/40 disabled:hover:bg-transparent disabled:cursor-default aria-disabled:hidden";

export function Play() {
  const isPaused = useMediaState("paused");
  const isBuffering = useMediaState("waiting");

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <PlayButton className="group relative inline-flex h-7 w-7 shrink-0 cursor-pointer items-center justify-center rounded-full bg-neutral-800 outline-none ring-inset transition-colors focus-visible:ring-1 active:bg-neutral-700 aria-disabled:hidden">
          {isPaused ? (
            <PlayIcon className="text-accent-neutral-100 h-4 w-4" />
          ) : (
            <PauseIcon className="text-accent-neutral-100 h-4 w-4" />
          )}
          <svg
            className={cn(
              "pointer-events-none absolute inset-0 z-50 flex h-full w-full items-center justify-center text-neutral-200 opacity-0 transition-opacity duration-1000 ease-in-out",
              isBuffering && "animate-spin opacity-100",
            )}
            viewBox="0 0 120 120"
            fill="none"
          >
            <circle
              cx={60}
              cy={60}
              r={58}
              strokeWidth={4}
              className="opacity-25"
              stroke="currentColor"
            />
            <circle
              cx={60}
              cy={60}
              r={58}
              strokeWidth={4}
              className="opacity-75"
              strokeDasharray={100}
              strokeDashoffset={50}
              stroke="currentColor"
              pathLength={100}
            />
          </svg>
        </PlayButton>
      </TooltipTrigger>
      <TooltipContent side="top" align="start" sideOffset={TOOLTIP_OFFSET}>
        {isPaused ? "Play" : "Pause"}
      </TooltipContent>
    </Tooltip>
  );
}

export function Mute() {
  const volume = useMediaState("volume");
  const isMuted = useMediaState("muted");

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <MuteButton className="ring-media-focus group relative inline-flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-full text-neutral-500 outline-none ring-inset focus-visible:ring-4 aria-disabled:hidden">
          {isMuted || volume == 0 ? (
            <MuteIcon className="h-4 w-4" />
          ) : volume <= 0.5 ? (
            <VolumeLowIcon className="h-4 w-4" />
          ) : (
            <VolumeHighIcon className="h-4 w-4" />
          )}
        </MuteButton>
      </TooltipTrigger>
      <TooltipContent side="top" align="center" sideOffset={TOOLTIP_OFFSET}>
        {isMuted ? "Unmute" : "Mute"}
      </TooltipContent>
    </Tooltip>
  );
}

export function PIP() {
  const isActive = useMediaState("pictureInPicture");

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <PIPButton className="ring-media-focus group relative inline-flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-full text-slate-500 outline-none ring-inset hover:bg-slate-300/50 focus-visible:ring-4 aria-disabled:hidden">
          {isActive ? (
            <PictureInPictureExitIcon className="h-4 w-4 " />
          ) : (
            <PictureInPictureIcon className="h-4 w-4" />
          )}
        </PIPButton>
      </TooltipTrigger>
      <TooltipContent side="top" align="center" sideOffset={TOOLTIP_OFFSET}>
        {isActive ? "Exit PIP" : "Enter PIP"}
      </TooltipContent>
    </Tooltip>
  );
}

export function Fullscreen() {
  const isActive = useMediaState("fullscreen");

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <FullscreenButton className={KINO_BUTTON_CLASSES}>
          {isActive ? (
            <FullscreenExitIcon fill="" className="h-4 w-4" />
          ) : (
            <FullscreenIcon className="h-4 w-4" />
          )}
        </FullscreenButton>
      </TooltipTrigger>
      <TooltipContent side="top" align="end" sideOffset={TOOLTIP_OFFSET}>
        {isActive ? "Exit Fullscreen" : "Enter Fullscreen"}
      </TooltipContent>
    </Tooltip>
  );
}

export function ClipIn() {
  const { setClipMomentStart, clearClipMomentStart, clipMoment } =
    useClipMoment();

  const currentTime = useMediaState("realCurrentTime");
  const hasClipIn = clipMoment.startTime >= 0;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={cn(
            KINO_BUTTON_CLASSES,
            "active:text-blue-600",
            hasClipIn && "bg-neutral-800 text-white",
          )}
          onClick={() =>
            hasClipIn ? clearClipMomentStart() : setClipMomentStart(currentTime)
          }
        >
          <ClipInIcon className="h-full w-full" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        {hasClipIn ? "Unset Clip In" : "Set Clip In"}
      </TooltipContent>
    </Tooltip>
  );
}

export function JumpToClipIn() {
  const remote = useMediaRemote();
  const { clipMoment, isClipMomentActive } = useClipMoment();
  const { setPlayerZoom, playerZoom } = usePlayerZoomController();

  const zoomDuration = playerZoom.end - playerZoom.start;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={cn(KINO_BUTTON_CLASSES, "active:text-blue-600")}
          onClick={() => {
            setPlayerZoom({
              start: clipMoment.startTime - zoomDuration / 2,
              end: clipMoment.startTime + zoomDuration / 2,
            });
            remote.seek(
              clipMoment.startTime -
                (isClipMomentActive ? clipMoment.startTime : 0),
            );
          }}
        >
          <JumpToClipInIcon className="h-full w-full" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        {"Go to Clip In"}
      </TooltipContent>
    </Tooltip>
  );
}

export function ClipOut() {
  const { setClipMomentEnd, clearClipMomentEnd, clipMoment } = useClipMoment();

  const currentTime = useMediaState("realCurrentTime");
  const realDuration = useMediaState("intrinsicDuration");
  const hasClipOut = clipMoment.endTime < realDuration;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={cn(
            KINO_BUTTON_CLASSES,
            "active:text-blue-600",
            hasClipOut && "bg-neutral-800 text-white",
          )}
          onClick={() =>
            hasClipOut ? clearClipMomentEnd() : setClipMomentEnd(currentTime)
          }
        >
          <ClipOutIcon className="h-full w-full" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        {hasClipOut ? "Unset Clip Out" : "Set Clip Out"}
      </TooltipContent>
    </Tooltip>
  );
}

export function JumpToClipOut() {
  const remote = useMediaRemote();
  const { clipMoment, isClipMomentActive } = useClipMoment();
  const { setPlayerZoom, playerZoom } = usePlayerZoomController();

  const zoomDuration = playerZoom.end - playerZoom.start;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={cn(KINO_BUTTON_CLASSES, "active:text-blue-600")}
          onClick={() => {
            setPlayerZoom({
              start: clipMoment.endTime - zoomDuration / 2,
              end: clipMoment.endTime + zoomDuration / 2,
            });
            remote.seek(
              clipMoment.endTime -
                (isClipMomentActive ? clipMoment.startTime : 0),
            );
          }}
        >
          <JumpToClipOutIcon className="h-full w-full" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        {"Go to Clip Out"}
      </TooltipContent>
    </Tooltip>
  );
}

export function LoopClip() {
  const {
    activateClipMoment,
    deactivateClipMoment,
    isClipMomentActive,
    hasClipMoment,
  } = useClipMoment();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          disabled={!hasClipMoment}
          className={cn(
            KINO_BUTTON_CLASSES,
            "active:text-blue-600",
            isClipMomentActive && "bg-neutral-800 text-white",
          )}
          onClick={() =>
            isClipMomentActive ? deactivateClipMoment() : activateClipMoment()
          }
        >
          {isClipMomentActive ? (
            <RepeatSquareOnIcon className="h-full w-full" />
          ) : (
            <RepeatSquareIcon className="h-full w-full" />
          )}
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        Loop Clip
      </TooltipContent>
    </Tooltip>
  );
}

export function ZoomToClip() {
  const { clipMoment, hasClipMoment, isClipMomentActive } = useClipMoment();
  const { setPlayerZoom, playerZoom } = usePlayerZoomController();

  const hasFiniteClip =
    hasClipMoment &&
    clipMoment.startTime > -1 &&
    Number.isFinite(clipMoment.endTime);
  const isCorrectZoom =
    clipMoment.startTime - 1 === playerZoom.start &&
    clipMoment.endTime + 1 === playerZoom.end;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          disabled={!hasFiniteClip || isCorrectZoom}
          className={cn(
            KINO_BUTTON_CLASSES,
            "border-blue-800 active:text-blue-600",
            isClipMomentActive && "bg-blue-600/40 hover:bg-blue-600/50",
          )}
          onClick={() =>
            setPlayerZoom({
              start: clipMoment.startTime - MOMENT_BUFFER_SECONDS,
              end: clipMoment.endTime + MOMENT_BUFFER_SECONDS,
            })
          }
        >
          <ReplayIcon className="h-full w-full" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        Go to Clip
      </TooltipContent>
    </Tooltip>
  );
}

export function ZoomToMoment() {
  const { activateClipMoment } = useClipMoment();
  const playerMoments = usePlayerMoments();
  const intrinsicDuration = useMediaState("intrinsicDuration");

  const { setPlayerZoom } = usePlayerZoomController();

  const navigateToMoment = () => {
    const moment = playerMoments[0];

    if (!moment) return;

    const momentZoom = {
      start: Math.max(moment.startTime - MOMENT_BUFFER_SECONDS, 0),
      end: Math.min(moment.endTime + MOMENT_BUFFER_SECONDS, intrinsicDuration),
    };

    setPlayerZoom(momentZoom);
    activateClipMoment(moment);
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          disabled={!playerMoments.length}
          className={cn(KINO_BUTTON_CLASSES)}
          onClick={() => navigateToMoment()}
        >
          <JumpToMomentIcon className="h-full w-full" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="top" align="center">
        Go to Search Moment
      </TooltipContent>
    </Tooltip>
  );
}

export function Generic({
  className,
  disabled,
  onClick,
  tooltipContent = "",
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & { tooltipContent?: string }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          disabled={disabled}
          onClick={disabled ? () => {} : onClick}
          className={cn(KINO_BUTTON_CLASSES, className)}
          {...props}
        />
      </TooltipTrigger>
      {tooltipContent && (
        <TooltipContent side="top" align="center">
          {tooltipContent}
        </TooltipContent>
      )}
    </Tooltip>
  );
}
