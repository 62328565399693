import { UnassignedImage } from "@/types/people";
import { useState } from "react";
import { useDropzone, DropzoneOptions, DropzoneState } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

export const usePersonImagesDropzone = ({
  onDrop: propsOnDrop,
  ...dropzoneOptions
}: {
  onDrop: (images: UnassignedImage[]) => void;
} & Omit<DropzoneOptions, "onDrop">): DropzoneState & {
  isUploading: boolean;
} => {
  const [isHandling, setIsHandling] = useState(false);

  const onDrop = async (acceptedFiles: File[]) => {
    const newImages: UnassignedImage[] = acceptedFiles.map((file) => ({
      id: uuidv4(),
      file,
      preview: URL.createObjectURL(file),
    }));

    setIsHandling(true);

    await propsOnDrop(newImages);

    setIsHandling(false);
  };

  const dropzoneState = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".webp"],
    },
    noClick: true,
    noKeyboard: true,
    multiple: true,
    ...dropzoneOptions,
  });

  return {
    ...dropzoneState,
    isUploading: isHandling,
  };
};
