import React, { PropsWithChildren } from "react";
import { PreviewTimeContext } from ".";
import { useSliderPreview } from "@vidstack/react";

const PreviewTimeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const sliderPreview = useSliderPreview({
    clamp: true,
    offset: 2,
    orientation: "horizontal",
  });

  return (
    <PreviewTimeContext.Provider value={sliderPreview}>
      {children}
    </PreviewTimeContext.Provider>
  );
};

export default PreviewTimeProvider;
