import React, { useCallback, useMemo, useState } from "react";
import { cn } from "@kino/ui";
import ContextMenu, { MenuItem } from "../ContextMenu";
import { AvatarIcon, TrashIcon } from "@radix-ui/react-icons";
import { useActiveServerStore } from "@/store/activeServerStore";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@kino/ui";
import { useDeleteReferenceFaces, useSetAvatar } from "@/hooks/usePeople";
import { CircleAlert, UserIcon } from "lucide-react";
import { toast } from "sonner";

export const ReferenceFace = ({
  personId,
  path,
  alt,
  isAvatar,
}: {
  personId: string;
  path: string;
  alt?: string;
  isAvatar?: boolean;
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [imageState, setImageState] = useState<"loading" | "loaded" | "error">(
    "loading",
  );
  const { buildServerUrl } = useActiveServerStore();

  const deleteFaces = useDeleteReferenceFaces();
  const handleDeleteFace = useCallback(
    () =>
      deleteFaces(
        { faces: [path] },
        {
          onSuccess: () => toast("Successfully deleted reference face"),
          onError: () => toast("Unable to delete reference face"),
        },
      ),
    [path],
  );

  const setImageAsAvatar = useSetAvatar();

  const handleSetImageAsAvatar = useCallback(
    () => setImageAsAvatar({ personId, imagePath: path }),
    [personId, path],
  );

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        label: "Set as Avatar",
        icon: <AvatarIcon />,
        onClick: handleSetImageAsAvatar,
      },
      {
        label: "Delete Reference Face",
        icon: <TrashIcon />,
        onClick: () => setDeleteDialogOpen(true),
      },
    ],
    [],
  );

  return (
    <>
      <ContextMenu customMenuItems={menuItems}>
        <div className="border-border group relative h-20 w-20 overflow-hidden rounded-lg border">
          <img
            src={buildServerUrl(path)}
            alt={alt || "Reference face image"}
            className="aspect-square w-full object-cover"
            onLoad={() => setImageState("loaded")}
            onError={() => setImageState("error")}
          />
          {imageState === "loading" && (
            <div
              className={cn(
                "pointer-events-none absolute inset-0 bg-neutral-800 object-cover",
              )}
            />
          )}
          {imageState === "error" && (
            <div className="absolute inset-0 flex items-center justify-center bg-neutral-800">
              <CircleAlert className="h-6 w-6" />
            </div>
          )}
          {isAvatar && (
            <div className="bg-accent/60 absolute bottom-1 right-1 rounded-full p-1 text-xs">
              <UserIcon className="h-3 w-3" />
            </div>
          )}
        </div>
      </ContextMenu>
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete this face. This action cannot be
              undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="mt-0">Cancel</AlertDialogCancel>
            <AlertDialogAction variant="destructive" onClick={handleDeleteFace}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
