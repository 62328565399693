import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
} from "@kino/ui";
import {
  DotsHorizontalIcon,
  DownloadIcon,
  Pencil1Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { PlaylistDialog } from "./PlaylistDialog";
import DeletePlaylistPopover from "./DeletePlaylistPopover";
import { components } from "@/openapi-bindings/v2";
import { useDebuggerStore } from "@/store/debuggerStore";

interface PlaylistOptionsDropdownProps {
  playlist: components["schemas"]["Playlist"];
}

export const PlaylistOptionsDropdown = ({
  playlist,
}: PlaylistOptionsDropdownProps) => {
  const showExportPlaylistsAsAaf = useDebuggerStore(
    (state) => state.showExportPlaylistsAsAaf,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DotsHorizontalIcon className="cursor-pointer text-neutral-400 hover:text-white" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {showExportPlaylistsAsAaf && (
          <DropdownMenuItem
            onSelect={() => {
              const link = document.createElement("a");
              link.href = "/On-mic stringout.aaf";
              link.download = "On-mic stringout.aaf";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            className="flex items-center"
          >
            <DownloadIcon className="mr-2 h-4 w-4" />
            Download playlist as .aaf
          </DropdownMenuItem>
        )}
        <PlaylistDialog
          playlist={playlist}
          mode="edit"
          trigger={
            <DropdownMenuItem
              onSelect={(e) => e.preventDefault()}
              className="flex items-center"
            >
              <Pencil1Icon className="mr-2 h-4 w-4" />
              Edit playlist
            </DropdownMenuItem>
          }
        />
        <DeletePlaylistPopover
          playlist={playlist}
          trigger={
            <DropdownMenuItem
              onSelect={(e) => e.preventDefault()}
              className="flex items-center text-red-500"
            >
              <TrashIcon className="mr-2 h-4 w-4" />
              Delete playlist
            </DropdownMenuItem>
          }
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
