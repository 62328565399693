import { create } from "zustand";
import { UnassignedImage } from "@/types/people";

interface UnassignedImagesStore {
  images: UnassignedImage[];
  selected: UnassignedImage[];
  addImages: (newImages: UnassignedImage[]) => void;
  toggleImage: (image: UnassignedImage) => void;
  clearSelected: () => void;
  removeImage: (id: string) => void;
  clearImages: () => void;
}

export const useUnassignedImagesStore = create<UnassignedImagesStore>(
  (set) => ({
    images: [],
    selected: [],
    toggleImage: (image) => set((state) => ({
      selected: state.selected.findIndex(({ id }) => id === image.id) >= 0 
        ? state.selected.filter(({ id }) => id !== image.id)
        : [...state.selected, image]
    })),
    clearSelected: () => set(() => ({ selected: [] })),
    addImages: (newImages) =>
      set((state) => ({
        images: [...state.images, ...newImages],
      })),
    removeImage: (id) =>
      set((state) => ({
        images: state.images.filter((image) => image.id !== id),
        selected: state.selected.filter((image) => image.id !== id)
      })),
    clearImages: () => set({ images: [] }),
  }),
);
